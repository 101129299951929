define("@trovedata/sunstone-ui-commons/login/controller", ["exports", "@ember/controller", "@ember/array", "@ember/object", "@ember/service", "ember-concurrency-decorators", "@ember/object/computed"], function (_exports, _controller, _array, _object, _service, _emberConcurrencyDecorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LoginController = _exports.default = (_dec = (0, _computed.alias)('config.trove.troveUserManager.registration'), _dec2 = (0, _object.computed)('registrationConfig'), (_class = class LoginController extends _controller.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", ['register']);
      _defineProperty(this, "register", false);
      _defineProperty(this, "emailSent", void 0);
      _defineProperty(this, "resetPasswordUsername", '');
      _defineProperty(this, "showResetPassword", false);
      _defineProperty(this, "overrideApplicationTemplate", true);
      _defineProperty(this, "errors", (0, _array.A)([]));
      _defineProperty(this, "username", void 0);
      _defineProperty(this, "password", void 0);
      _defineProperty(this, "agreementInfoToShow", void 0);
      _initializerDefineProperty(this, "sunstoneAjax", _descriptor, this);
      _initializerDefineProperty(this, "troveTheme", _descriptor2, this);
      _initializerDefineProperty(this, "config", _descriptor3, this);
      _initializerDefineProperty(this, "registrationConfig", _descriptor4, this);
    }
    get canRegister() {
      return this.registrationConfig;
    }
    *authenticateTask() {
      this.set('errors', (0, _array.A)([]));
      let {
        username,
        password
      } = this.getProperties('username', 'password');
      return yield this.session.authenticate('authenticator:trove', username.toLowerCase(), password).catch(error => {
        error = error?.responseJSON;
        let errMsg = error?.error_description === 'User is disabled' ? `${error.error_description}. Please contact your administrator or reset your password.` : error?.error_description;
        this.set('errors', (0, _array.A)([errMsg]));
      });
    }
    authenticate() {
      // @ts-ignore
      return this.authenticateTask.perform();
    }
    toggleReset(username) {
      this.set('emailSent', null);
      this.set('resetPasswordUsername', username);
      this.set('showResetPassword', !this.showResetPassword);
    }
    async requestReset() {
      this.set('emailSent', null);
      try {
        await this.sunstoneAjax.post('resetPassword', {
          namespace: 'trove-user-manager/otr',
          data: JSON.stringify({
            username: this.resetPasswordUsername.toLowerCase(),
            basePath: `${window.location.origin}/#/reset-password`
          }),
          contentType: 'application/json'
        });
      } finally {
        this.set('emailSent', 'Email sent successfully! Please contact your administrator if you do not receive the email ');
      }
    }
    toggleRegister() {
      const userAgreement = this.config.get('trove.troveUserManager.registration.userAgreement');
      if (userAgreement) {
        if (this.agreementInfoToShow && this.agreementInfoToShow.agreeValue) {
          this.set('agreementInfoToShow', undefined);
          this.set('register', true);
        } else {
          this.set('agreementInfoToShow', {
            ...userAgreement,
            file: userAgreement.file ? `${location.origin}/${userAgreement.file}` : undefined
          });
        }
      } else {
        this.set('register', !this.register);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sunstoneAjax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveTheme", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "registrationConfig", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canRegister", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "canRegister"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "authenticateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "authenticate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleReset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "requestReset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleRegister", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRegister"), _class.prototype)), _class));
});