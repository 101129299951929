define("@trovedata/sunstone-ui-commons/components/geometry-viewer/component", ["exports", "@ember/component", "@glimmer/component", "@trovedata/sunstone-ui-commons/utils/map-google", "@ember/template-factory"], function (_exports, _component, _component2, _mapGoogle, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PaperCard class="geometry-viewer" ...attributes>
    {{#if @isLoadingProgress}}
      <progress value={{@loadingProgress}}></progress>
    {{/if}}
  
    {{#if @isLoading}}
      <div class="loader-spinner"></div>
    {{/if}}
  
    {{#if @showWarning}}
      <div class="warning-message-container">
        <TroveButton @iconButton={{true}} @onClick={{@onClearMessage}}>
          <TroveIcon @icon="close" @size={{18}} />
        </TroveButton>
  
        <TroveLabel class="message" @label={{@message}} />
      </div>
    {{/if}}
  
    <SunstoneMap
      class="map-container flex"
      @mapType={{null}}
      @options={{hash
        center=(hash lat=43.004065 lng=-78.804576)
        mapTypeId=this.mapTypeId
        rotateControl=false
        styles=this.nightModeStyles
        tilt=0
        zoom=5
      }}
      as |map|
    >
      <map.geoJson
        @enableSearch={{@enableSearch}}
        @enableContextWindow={{@enableContextWindow}}
        @geoJson={{@featureCollection}}
        @useClick={{@useClick}}
        @useMouseover={{@useMouseover}}
        @onClick={{@onClick}}
        @onMouseout={{@onMouseout}}
        @onMouseover={{@onMouseover}}
        @onFetchContext={{@onFetchContext}}
        @onReady={{@onReady}}
      />
    </SunstoneMap>
  </PaperCard>
  
  */
  {
    "id": "4aob7icK",
    "block": "[[[8,[39,0],[[24,0,\"geometry-viewer\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"progress\"],[15,2,[30,3]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[14,0,\"loader-spinner\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[14,0,\"warning-message-container\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@iconButton\",\"@onClick\"],[true,[30,6]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@icon\",\"@size\"],[\"close\",18]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,4],[[24,0,\"message\"]],[[\"@label\"],[[30,7]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,5],[[24,0,\"map-container flex\"]],[[\"@mapType\",\"@options\"],[null,[28,[37,6],null,[[\"center\",\"mapTypeId\",\"rotateControl\",\"styles\",\"tilt\",\"zoom\"],[[28,[37,6],null,[[\"lat\",\"lng\"],[43.004065,-78.804576]]],[30,0,[\"mapTypeId\"]],false,[30,0,[\"nightModeStyles\"]],0,5]]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,8,[\"geoJson\"]],null,[[\"@enableSearch\",\"@enableContextWindow\",\"@geoJson\",\"@useClick\",\"@useMouseover\",\"@onClick\",\"@onMouseout\",\"@onMouseover\",\"@onFetchContext\",\"@onReady\"],[[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,17],[30,18]]],null],[1,\"\\n  \"]],[8]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@isLoadingProgress\",\"@loadingProgress\",\"@isLoading\",\"@showWarning\",\"@onClearMessage\",\"@message\",\"map\",\"@enableSearch\",\"@enableContextWindow\",\"@featureCollection\",\"@useClick\",\"@useMouseover\",\"@onClick\",\"@onMouseout\",\"@onMouseover\",\"@onFetchContext\",\"@onReady\"],false,[\"paper-card\",\"if\",\"trove-button\",\"trove-icon\",\"trove-label\",\"sunstone-map\",\"hash\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/geometry-viewer/component.hbs",
    "isStrictMode": false
  });
  class GeometryViewer extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "nightModeStyles", _mapGoogle.nightModeStyles);
    }
    get mapTypeId() {
      return this.args.mapTypeId ? this.args.mapTypeId : 'hybrid';
    }
  }
  _exports.default = GeometryViewer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeometryViewer);
});