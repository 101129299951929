define("ember-svg-jar/inlined/DataConnections-Color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#8dc63f}.st1{fill:#fff}</style><g><path class=\"st0\" d=\"M90.6 25.9c-13 0-23.6 10.6-23.6 23.6v47.4c0 7.1-5.9 12.9-13 12.9S41 104 41 96.9V56.1H30.2v40.8c0 13 10.8 23.6 23.8 23.6s23.8-10.6 23.8-23.6V49.5c0-7.1 5.8-12.9 12.9-12.9 7.1 0 12.9 5.8 12.9 12.9v37.7h10.7V49.5c-.1-13-10.7-23.6-23.7-23.6zm0 0M40.6 0h-10c-3 0-5.4 2.5-5.4 5.4 0 .3 0 .7.1 1h20.5c.1-.4.1-.7.1-1 0-2.9-2.4-5.4-5.3-5.4zm0 0M118.2 136.9H98c-.2.7-.3 1.1-.3 1.7 0 3 2.4 5.4 5.4 5.4h10c3 0 5.4-2.5 5.4-5.4 0-.6-.1-1-.3-1.7zm0 0\"/><path class=\"st1\" d=\"M24.5 11.8H47v38.9H24.5zM97 92.5h22.5v38.9H97z\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
});