define("@trovedata/trove-scenario-planner/utils/operator-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OPERATOR_MAP = void 0;
  const OPERATOR_MAP = _exports.OPERATOR_MAP = {
    '<=': (a, b) => a <= b,
    '>=': (a, b) => a >= b
  };
});