define("@trovedata/sunstone-ui-commons/helpers/disable-bubbling", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.disableBubbling = disableBubbling;
  function disableBubbling([action]) {
    return function (event) {
      event.stopPropagation();
      event.preventDefault();
      return action(event);
    };
  }
  var _default = _exports.default = (0, _helper.helper)(disableBubbling);
});