define("@trovedata/trove-scenario-planner/serializers/plan", ["exports", "@ember/service", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    config: (0, _service.inject)(),
    /**
     * Stop-gap solution for bypassing CORS issue on dev environment.
     *
     * These association links gets called explicitly and bypasses the proxy
     * causing CORS network errors. Thus, within dev env, we replace the link host
     * to the proxy.
     */
    normalizeResponse(store, typeClass, payload, id, requestType) {
      if (payload.content) {
        const data = payload.content;
        data._links = payload._links;
        payload = data;
      }
      const {
        config
      } = this;
      const isDevEnv = config.get('environment') === 'development';
      const apiGatewayBaseUrl = config.get('trove.apiGatewayBaseUrl');
      const hasProxyUrl = !!apiGatewayBaseUrl;
      if (isDevEnv && hasProxyUrl) {
        const hasMultiPayload = !!payload?._embedded?.plan?.length;
        if (hasMultiPayload) {
          payload._embedded.plan.forEach(({
            _links
          }) => {
            if (!_links) return;
            this.replaceLinksUrlToLocalProxy(_links);
          });
        } else if (payload?._links) {
          this.replaceLinksUrlToLocalProxy(payload?._links);
        }
      }
      return this._super(store, typeClass, payload, id, requestType);
    },
    replaceLinksUrlToLocalProxy(_links) {
      const apiGatewayBaseUrl = this.config.get('trove.apiGatewayBaseUrl');
      Object.keys(_links).forEach(key => {
        const link = _links[key]?.href;
        if (!link) return;
        const url = new URL(link);
        const revisedUrl = apiGatewayBaseUrl.replace('/api', '');
        _links[key].href = `${revisedUrl}${url.pathname}`;
      });
    }
  });
});