define("ember-svg-jar/inlined/synchronize-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M5.642 4.642A8.907 8.907 0 0112 2c4.963 0 9 4.038 9 9h2c0-6.065-4.935-11-11-11a10.89 10.89 0 00-7.771 3.229L2 1v6h6L5.642 4.642zM18.357 19.358A8.9 8.9 0 0112 22c-4.963 0-9-4.038-9-9H1c0 6.065 4.935 11 11 11 2.967 0 5.721-1.167 7.771-3.229L22 23v-6h-6l2.357 2.358z\"/><path d=\"M12 5c-1.654 0-3 1.346-3 3v1a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1V8c0-1.654-1.346-3-3-3zm2 10h-4v-4h4v4zm-3-6V8c0-.551.449-1 1-1s1 .449 1 1v1h-2z\"/><circle cx=\"12\" cy=\"13\" r=\"1\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});