define("@trovedata/sunstone-ui-commons/components/ember-accordion-body/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isOpen}}
    {{yield}}
  {{/if}}
  
  */
  {
    "id": "YPjNR5o9",
    "block": "[[[41,[33,1],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"isOpen\",\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/ember-accordion-body/template.hbs",
    "isStrictMode": false
  });
});