define("@trovedata/sunstone-ui-commons/helpers/is-empty-trove", ["exports", "@ember/component/helper", "@ember/utils", "@ember/object"], function (_exports, _helper, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEmpty = isEmpty;
  /* eslint-disable ember/no-observers */

  function isEmpty(value) {
    return (0, _utils.isEmpty)(value);
  }
  var _default = _exports.default = _helper.default.extend({
    compute([value]) {
      (0, _object.set)(this, 'value', value);
      return this.content;
    },
    content: (0, _object.computed)('value.[]', function () {
      return (0, _utils.isEmpty)(this.value);
    }),
    contentDidChange: (0, _object.observer)('content', function () {
      this.recompute();
    })
  });
});