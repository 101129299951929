define("@trovedata/trove-scenario-planner/components/trove-select/component", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="trove-select layout-column" ...attributes>
    {{#if @label}}
      <TroveLabel class="trove-select-label" @label={{@label}} @size="12" />
    {{/if}}
  
    {{#if @isMultiSelect}}
      <PowerSelectMultiple
        @animationEnabled={{false}}
        @closeOnSelect={{@closeOnSelect}}
        @disabled={{@disabled}}
        @dropdownClass={{@dropdownClass}}
        @options={{@options}}
        @placeholder={{@placeholder}}
        @searchEnabled={{@searchEnabled}}
        @searchField={{@searchField}}
        @searchPlaceholder={{@searchPlaceholder}}
        @selected={{@selected}}
        @triggerClass={{@triggerClass}}
        @onChange={{@onChange}}
        as |option|
      >
        {{yield option}}
      </PowerSelectMultiple>
    {{else}}
      <PowerSelect
        @allowClear={{@allowClear}}
        @animationEnabled={{false}}
        @disabled={{@disabled}}
        @dropdownClass={{@dropdownClass}}
        @options={{@options}}
        @placeholder={{@placeholder}}
        @placeholderComponent={{@placeholderComponent}}
        @search={{@search}}
        @searchEnabled={{@searchEnabled}}
        @searchField={{@searchField}}
        @selected={{@selected}}
        @triggerClass={{@triggerClass}}
        @onChange={{@onChange}}
        as |option|
      >
        {{yield option}}
      </PowerSelect>
    {{/if}}
  </div>
  
  */
  {
    "id": "9gvWN1EJ",
    "block": "[[[11,0],[24,0,\"trove-select layout-column\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],[[24,0,\"trove-select-label\"]],[[\"@label\",\"@size\"],[[30,2],\"12\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,2],null,[[\"@animationEnabled\",\"@closeOnSelect\",\"@disabled\",\"@dropdownClass\",\"@options\",\"@placeholder\",\"@searchEnabled\",\"@searchField\",\"@searchPlaceholder\",\"@selected\",\"@triggerClass\",\"@onChange\"],[false,[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14]]],[[\"default\"],[[[[1,\"\\n      \"],[18,20,[[30,15]]],[1,\"\\n    \"]],[15]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@allowClear\",\"@animationEnabled\",\"@disabled\",\"@dropdownClass\",\"@options\",\"@placeholder\",\"@placeholderComponent\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@triggerClass\",\"@onChange\"],[[30,16],false,[30,5],[30,6],[30,7],[30,8],[30,17],[30,18],[30,9],[30,10],[30,12],[30,13],[30,14]]],[[\"default\"],[[[[1,\"\\n      \"],[18,20,[[30,19]]],[1,\"\\n    \"]],[19]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@isMultiSelect\",\"@closeOnSelect\",\"@disabled\",\"@dropdownClass\",\"@options\",\"@placeholder\",\"@searchEnabled\",\"@searchField\",\"@searchPlaceholder\",\"@selected\",\"@triggerClass\",\"@onChange\",\"option\",\"@allowClear\",\"@placeholderComponent\",\"@search\",\"option\",\"&default\"],false,[\"if\",\"trove-label\",\"power-select-multiple\",\"yield\",\"power-select\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/trove-select/component.hbs",
    "isStrictMode": false
  });
  class TroveSelect extends _component2.default {}
  _exports.default = TroveSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TroveSelect);
});