define("@trovedata/sunstone-ui-commons/helpers/word-display", ["exports", "@ember/component/helper", "ember-inflector"], function (_exports, _helper, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.wordDisplay = wordDisplay;
  function wordDisplay([word, count]) {
    return Number(count) === 1 ? word : (0, _emberInflector.pluralize)(word);
  }
  var _default = _exports.default = (0, _helper.helper)(wordDisplay);
});