define("ember-svg-jar/inlined/nature-tree-2-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Filled_Icons\"><g fill=\"#757575\"><path d=\"M17.963 17.445L15 13h.579a.999.999 0 00.819-1.573L14 8a.783.783 0 00.64-1.229L12 3 9.36 6.772A.78.78 0 0010 8l-2.398 3.427A1 1 0 008.421 13H9l-2.963 4.445A1.002 1.002 0 006.869 19h10.263c.369 0 .708-.203.882-.527a1.004 1.004 0 00-.051-1.028zM11 20h2v4h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});