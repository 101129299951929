define("ember-svg-jar/inlined/navigation-show-more-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><circle cx=\"12\" cy=\"5.001\" r=\"2\"/><circle cx=\"12\" cy=\"12.001\" r=\"2\"/><circle cx=\"12\" cy=\"19.001\" r=\"2\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});