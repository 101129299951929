define("@trovedata/sunstone-ui-commons/components/paper-async-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPending}}
    {{paper-progress-circular}}
  {{else}}
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{text}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "oXWfbsYf",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[34,2]],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,5]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"isPending\",\"paper-progress-circular\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-async-button/template.hbs",
    "isStrictMode": false
  });
});