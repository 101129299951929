define("ember-svg-jar/inlined/move-left-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .004h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M21.924 13.617A1 1 0 0021 13H6v2h12.586l-3.293 3.293 1.414 1.414 5-5c.286-.286.371-.716.217-1.09zM2.076 10.383A1 1 0 003 11h15V9H5.414l3.293-3.293-1.414-1.414-5 5a1.002 1.002 0 00-.217 1.09z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});