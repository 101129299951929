define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/card-list-item-action/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/card-list-item-action/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _component, _template, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins

  let component = _component.default.extend(_troveColorMixin.default, {
    layout: _template.default,
    tagName: 'li',
    classNames: ['action'],
    click() {
      let action = this.action;
      let toggleActions = this.toggleActions;
      if (action && typeof action === 'function') {
        action();
      }
      toggleActions();
      return false;
    }
  });
  component.reopenClass({
    positionalParams: ['action']
  });
  var _default = _exports.default = component;
});