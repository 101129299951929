define("@trovedata/sunstone-ui-commons/components/ember-accordion-trigger/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield isOpen}}
  
  */
  {
    "id": "e3/vPAzA",
    "block": "[[[18,1,[[33,1]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"isOpen\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/ember-accordion-trigger/template.hbs",
    "isStrictMode": false
  });
});