define("@trovedata/trove-scenario-planner/components/map-legend/component", ["exports", "@ember/component", "@ember/template", "@glimmer/component", "@trovedata/trove-scenario-planner/utils/map-utils", "@ember/template-factory"], function (_exports, _component, _template, _component2, _mapUtils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="map-legend layout-row layout-align-center-center">
    <TroveLabel
      class="layout-margin-right"
      @bold={{true}}
      @label="Low"
      @size={{14}}
    />
  
    {{#each this.riskColorStyles as |style|}}
      <span class="legend-swatch" style={{style}}></span>
    {{/each}}
  
    <TroveLabel
      class="layout-margin-left"
      @bold={{true}}
      @label="High"
      @size={{14}}
    />
  </div>
  
  */
  {
    "id": "fS6oBmEi",
    "block": "[[[10,0],[14,0,\"map-legend layout-row layout-align-center-center\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"layout-margin-right\"]],[[\"@bold\",\"@label\",\"@size\"],[true,\"Low\",14]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"riskColorStyles\"]]],null]],null],null,[[[1,\"    \"],[10,1],[14,0,\"legend-swatch\"],[15,5,[30,1]],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n  \"],[8,[39,0],[[24,0,\"layout-margin-left\"]],[[\"@bold\",\"@label\",\"@size\"],[true,\"High\",14]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"style\"],false,[\"trove-label\",\"each\",\"-track-array\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/map-legend/component.hbs",
    "isStrictMode": false
  });
  class MapLegend extends _component2.default {
    get riskColorStyles() {
      return Object.values(_mapUtils.RISK_COLOR_PALETTE_MAP).map(v => {
        return (0, _template.htmlSafe)(`background:${v}`);
      });
    }
  }
  _exports.default = MapLegend;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapLegend);
});