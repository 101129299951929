define("@trovedata/sunstone-ui-commons/components/paper-card/component", ["exports", "ember-paper/components/paper-card", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "@ember-decorators/component", "@ember/object", "@ember/template", "@trovedata/sunstone-ui-commons/utils/component-utils"], function (_exports, _paperCard, _troveColorMixin, _component, _object, _template, _componentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperCardComponent = _exports.default = (_dec = (0, _object.computed)('alternative'), _dec2 = (0, _object.computed)('info'), _dec3 = (0, _component.attribute)('style'), _dec4 = (0, _object.computed)('opacity'), _dec5 = (0, _component.className)('md-round'), _dec6 = (0, _component.className)('md-border'), _dec7 = (0, _component.className)('md-flat'), _dec8 = (0, _component.className)('md-opaque'), _dec9 = (0, _component.className)('md-large'), _dec10 = (0, _component.className)('md-gradient'), _dec11 = (0, _component.className)('md-hoverable'), _dec12 = (0, _component.className)('md-border'), (_class = class PaperCardComponent extends _paperCard.default.extend(_troveColorMixin.default) {
    get alternativeShade() {
      return (0, _componentUtils.calcColorVar)('md-alternative', this.alternative);
    }
    get infoShade() {
      return (0, _componentUtils.calcColorVar)('md-info', this.info);
    }
    get componentStyles() {
      let styles = '';
      if (this.opacity) {
        styles = `${styles}--opacity: ${this.opacity};`;
      }
      return (0, _template.htmlSafe)(styles);
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "alternative", void 0);
      _defineProperty(this, "info", void 0);
      _initializerDefineProperty(this, "round", _descriptor, this);
      _initializerDefineProperty(this, "inverted", _descriptor2, this);
      _initializerDefineProperty(this, "flat", _descriptor3, this);
      _initializerDefineProperty(this, "opaque", _descriptor4, this);
      _initializerDefineProperty(this, "large", _descriptor5, this);
      _initializerDefineProperty(this, "gradient", _descriptor6, this);
      _initializerDefineProperty(this, "hoverable", _descriptor7, this);
      _initializerDefineProperty(this, "border", _descriptor8, this);
      _defineProperty(this, "opacity", 1);
      _defineProperty(this, "status", 'default');
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "bubbles", void 0);
      _defineProperty(this, "href", void 0);
      _defineProperty(this, "tagName", 'md-card');
      _defineProperty(this, "type", void 0);
      if (this.onClick) {
        (0, _object.set)(this, 'hoverable', true);
      }
      if (this.href) {
        (0, _object.setProperties)(this, {
          hoverable: true,
          tagName: 'a',
          type: null
        });
      }
    }
    didReceiveAttrs() {
      (0, _object.set)(this, 'hoverable', this.onClick ? true : false);
    }
    click(event) {
      if (this.onClick) {
        this.onClick(event);
      }
      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "alternativeShade", [_component.className, _dec], Object.getOwnPropertyDescriptor(_class.prototype, "alternativeShade"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "infoShade", [_component.className, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "infoShade"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "componentStyles", [_dec3, _dec4], Object.getOwnPropertyDescriptor(_class.prototype, "componentStyles"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "round", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inverted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flat", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "opaque", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "large", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "gradient", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hoverable", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "border", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});