define("@trovedata/sunstone-ui-commons/models/app", ["exports", "rsvp", "@ember/object", "@ember/object/computed", "@ember-data/model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _rsvp, _object, _computed, _model, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    orgId: (0, _model.attr)('number'),
    instances: (0, _model.hasMany)('application-instance'),
    applicationPropertyValues: (0, _model.hasMany)('application-property-value'),
    applicationPropertySets: (0, _model.hasMany)('application-property-set'),
    appType: (0, _model.attr)('string'),
    mainClassName: (0, _model.attr)('string'),
    activeIndicatorDetail: (0, _attributes.fragment)('active-indicator-detail', {
      defaultValue: {
        activeIndicator: true
      }
    }),
    sinceVersionDetail: (0, _attributes.fragment)('since-version-detail'),
    propertySets: (0, _computed.alias)('applicationPropertySets'),
    propertyValues: (0, _computed.alias)('applicationPropertyValues'),
    deployableInstances: (0, _object.computed)('instances.@each.isDeployable', function () {
      let promise = this.instances.then(instances => {
        const deployableFlags = instances.getEach('isDeployable');
        return (0, _rsvp.allSettled)(deployableFlags).then(flags => {
          return instances.filter((instance, index) => {
            return flags[index].value;
          });
        });
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    })
  });
});