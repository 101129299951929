define("@trovedata/sunstone-ui-commons/components/main-navigation/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/main-navigation/template", "@ember-decorators/component", "@ember/service", "@ember/object", "@ember/array", "@ember/runloop", "ember-concurrency-decorators", "@ember/object/computed", "@trovedata/sunstone-ui-commons/utils/check-resource-status"], function (_exports, _component, _template, _component2, _service, _object, _array, _runloop, _emberConcurrencyDecorators, _computed, _checkResourceStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HOST_HASH = {
    '@trovedata/trove-demand-response': 'demand-response',
    '@trovedata/trove-scenario-planner': 'scenario-planner'
  };
  const HOME_HASH = {
    'demand-response': {
      icon: 'circle-graph-bar-2',
      homeURL: '/demand-response',
      title: 'Demand Response'
    },
    'scenario-planner': {
      icon: 'location-map-pin',
      homeURL: '/',
      title: 'Planning'
    }
  };
  let MainNavigationComponent = _exports.default = (_dec = (0, _component2.classNames)('layout-column'), _dec2 = (0, _component2.tagName)('main-navigation'), _dec3 = (0, _component2.layout)(_template.default), _dec4 = (0, _computed.alias)('config.environment'), _dec5 = (0, _object.computed)('currentRouteName'), _dec6 = (0, _object.computed)('currentRouteName'), _dec7 = (0, _computed.alias)('loggingPortalStatusResponse.value'), _dec8 = (0, _computed.alias)('currentUser.theme'), _dec9 = (0, _object.computed)('activeApp.config.name'), _dec10 = (0, _object.computed)('apps.[]', 'applicationChooserValue'), _dec11 = (0, _object.computed)('currentRouteName'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class MainNavigationComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "config", _descriptor3, this);
      _defineProperty(this, "collapsed", true);
      _defineProperty(this, "searchProperties", (0, _array.A)(['name']));
      _defineProperty(this, "currentRouteName", void 0);
      _defineProperty(this, "apps", void 0);
      _defineProperty(this, "host", void 0);
      _defineProperty(this, "loggingConfig", void 0);
      _defineProperty(this, "userManagerConfig", {
        admin: undefined,
        name: 'User Manager Portal',
        redirect: true,
        route: '/user-manager-portal'
      });
      _initializerDefineProperty(this, "environment", _descriptor4, this);
      _defineProperty(this, "loggingPortalStatusResponse", void 0);
      _initializerDefineProperty(this, "loggingPortalStatus", _descriptor5, this);
      _initializerDefineProperty(this, "theme", _descriptor6, this);
    }
    init() {
      super.init();
      _runloop.run.scheduleOnce('afterRender', this, function () {
        let $sideMenu = document.querySelector('.side-menu');
        if ($sideMenu && this.environment !== 'test') {
          $sideMenu.addEventListener('transitionend', () => {
            $(window).resize();
          }, false);
        }
      });
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      if (this.loggingPortalStatus !== false && this.loggingPortalStatus !== true) {
        // @ts-ignore
        this.set('loggingPortalStatusResponse', this.checkAppStatus.perform(this.loggingConfig.externalRoute));
      }
    }
    get isHostLoggingPortal() {
      return this.config.get('APP.name') === '@trovedata/trove-logging-portal';
    }
    get isHostUserMgmtPortal() {
      return this.config.get('APP.name') === '@trovedata/trove-user-manager-portal';
    }
    get homeHash() {
      return HOME_HASH[this.host];
    }
    get isLogsActive() {
      return this.currentRouteName.includes('logging-portal');
    }
    get isUsersActive() {
      return this.currentRouteName.includes('user-manager-portal');
    }
    *checkAppStatus(path) {
      return yield (0, _checkResourceStatus.default)(path, this.environment, this.config.get('sunstone.apps'), this.loggingConfig.externalRoute);
    }
    get applicationChooserValue() {
      return (0, _object.getWithDefault)(this.activeApp.config ? this.activeApp.config : {}, 'name', null);
    }
    get sortedApps() {
      let applicationChooserValue = this.applicationChooserValue;
      return (0, _array.A)(this.apps).sort(a => {
        return a.name === applicationChooserValue ? -1 : 1;
      });
    }
    get currentRouteNameQueryable() {
      const name = this.currentRouteName;
      return (0, _array.A)([{
        name
      }]);
    }
    canShowLink(navItem) {
      if (!this.currentUser.roles || !navItem.permittedRoles || this.currentUser.roles.length === 0) {
        return true;
      }
      let hasAccess = false;
      navItem.permittedRoles.forEach(authority => {
        if (this.currentUser.hasAuthority(authority)) {
          hasAccess = true;
          return;
        }
      });
      return hasAccess;
    }
    async switchTheme(theme) {
      await this.currentUser.setSetting('default', 'Theme', theme);
    }
    home() {
      this.router.transitionTo('application');
    }
    navigateToRoute(navItem) {
      if (navItem.external) {
        const appName = this.config.get('APP.name');
        let queryParams = '';
        if (this.host) {
          queryParams = `?host=${this.host}`;
        } else if (HOST_HASH[appName]) {
          queryParams = `?host=${HOST_HASH[appName]}`;
        }
        return location.href = `${location.origin}/${navItem.externalRoute}${queryParams}`;
      } else {
        return this.router.transitionTo(navItem.route);
      }
    }
    appNavigate(app) {
      const appName = this.config.get('APP.name');
      let queryParams = '';
      if (this.host) {
        queryParams = `?host=${this.host}`;
      } else if (HOST_HASH[appName]) {
        queryParams = `?host=${HOST_HASH[appName]}`;
      }
      this.anchorClick(`${app.route}${queryParams}`);
    }
    anchorClick(href) {
      let $temp = document.createElement('a');
      let $body = document.querySelector('body');
      if ($body) {
        $body.appendChild($temp);
        $temp.href = href;
        $temp.click();
        $temp.remove();
      }
    }
    toggleMenu() {
      this.toggleProperty('collapsed');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "environment", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isLogsActive", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isLogsActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isUsersActive", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isUsersActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkAppStatus", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "checkAppStatus"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "loggingPortalStatus", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "theme", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "applicationChooserValue", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "applicationChooserValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sortedApps", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedApps"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentRouteNameQueryable", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "currentRouteNameQueryable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canShowLink", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "canShowLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "switchTheme", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "switchTheme"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "home", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "home"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "navigateToRoute", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "navigateToRoute"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "appNavigate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "appNavigate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "anchorClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "anchorClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleMenu", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleMenu"), _class2.prototype)), _class2)) || _class) || _class) || _class);
});