define("@trovedata/trove-scenario-planner/components/shared-asset-info-window/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PaperCard class="layout-padding layout-margin-none flex">
    <div class="layout-column layout-wrap prop-container">
      <div class="layout-column">
        <TroveLabel
          @bold={{true}}
          @label={{this.sharedAsset.primaryLineName}}
          @size={{12}}
        />
        <TroveLabel @label="Primary Line" @size={{10}} />
      </div>
      <div class="layout-column">
        <TroveLabel
          @bold={{true}}
          @label={{this.sharedAsset.primarySpanDisplayName}}
          @size={{12}}
        />
        <TroveLabel @label="Primary Span" @size={{10}} />
      </div>
      <div class="layout-column">
        <TroveLabel
          @bold={{true}}
          @label={{this.sharedAsset.secondaryLineName}}
          @size={{12}}
        />
        <TroveLabel @label="Secondary Line" @size={{10}} />
      </div>
      <div class="layout-column">
        <TroveLabel
          @bold={{true}}
          @label={{this.sharedAsset.secondarySpanDisplayName}}
          @size={{12}}
        />
        <TroveLabel @label="Secondary Span" @size={{10}} />
      </div>
      <div class="layout-column">
        <TroveLabel
          @bold={{true}}
          @label={{this.adjustedRiskScore}}
          @size={{12}}
        />
        <TroveLabel @label="Risk" @size={{10}} />
      </div>
    </div>
  </PaperCard>
  
  */
  {
    "id": "/I6Kfaum",
    "block": "[[[8,[39,0],[[24,0,\"layout-padding layout-margin-none flex\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"layout-column layout-wrap prop-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"layout-column\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"sharedAsset\",\"primaryLineName\"]],12]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Primary Line\",10]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"layout-column\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"sharedAsset\",\"primarySpanDisplayName\"]],12]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Primary Span\",10]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"layout-column\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"sharedAsset\",\"secondaryLineName\"]],12]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Secondary Line\",10]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"layout-column\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"sharedAsset\",\"secondarySpanDisplayName\"]],12]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Secondary Span\",10]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"layout-column\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"adjustedRiskScore\"]],12]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Risk\",10]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"paper-card\",\"trove-label\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/shared-asset-info-window/template.hbs",
    "isStrictMode": false
  });
});