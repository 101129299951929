define("@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", ["exports", "@ember/object", "@ember/service", "@ember/object/mixin"], function (_exports, _object, _service, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-new-mixins */
  var _default = _exports.default = _mixin.default.create({
    currentUser: (0, _service.inject)(),
    classNameBindings: ['theme', 'mdStyle', 'warn:md-warn', 'accent:md-accent', 'primary:md-primary', 'alert:md-alert', 'secondary:md-secondary', 'info:md-info'],
    theme: (0, _object.computed)('currentUser.settings.default.theme', function () {
      let theme = (0, _object.get)(this, 'currentUser.settings.default.theme') === undefined ? 'dark' : (0, _object.get)(this, 'currentUser.settings.default.theme');
      return `md-${theme}`;
    }),
    mdStatus: null,
    status: null,
    mdStyle: (0, _object.computed)('mdStatus', 'status', function () {
      let status = this.status || this.mdStatus;
      return status ? `md-${status}` : '';
    })
  });
});