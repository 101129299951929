define("@trovedata/sunstone-ui-commons/components/async-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      submit=(component 'async-button' promise=promise type="submit")
      paper-submit=(component 'paper-async-button' promise=promise type="submit")
    )
    promise
  }}
  
  */
  {
    "id": "UaAtHtYn",
    "block": "[[[18,1,[[28,[37,1],null,[[\"submit\",\"paper-submit\"],[[50,\"async-button\",0,null,[[\"promise\",\"type\"],[[33,3],\"submit\"]]],[50,\"paper-async-button\",0,null,[[\"promise\",\"type\"],[[33,3],\"submit\"]]]]]],[33,3]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"promise\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/async-form/template.hbs",
    "isStrictMode": false
  });
});