define("@trovedata/sunstone-ui-commons/initializers/route-class", ["exports", "@ember/string", "@ember/routing/route"], function (_exports, _string, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _route.default.reopen({
      activate() {
        this._super(...arguments);
        const $body = document.querySelector('body');
        $body.classList.toggle(this._toCssClass());
      },
      deactivate() {
        this._super(...arguments);
        const $body = document.querySelector('body');
        $body.classList.toggle(this._toCssClass());
      },
      _toCssClass() {
        return (0, _string.dasherize)(this.routeName.replace(/\./g, '-'));
      }
    });
  }
  var _default = _exports.default = {
    name: 'route-class',
    initialize
  };
});