define("@trovedata/sunstone-ui-commons/adapters/segment-set", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlForCreateRecord(modelName) {
      this._super(...arguments);
      let url = this._buildURL(modelName);
      return `${url}?async=true`;
    }
  });
});