define("@trovedata/sunstone-ui-commons/components/bs-glyph/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let component = _component.default.extend({
    tagName: 'span',
    classNames: ['glyphicon'],
    classNameBindings: ['glyphClass'],
    glyphClass: (0, _object.computed)('glyph', function () {
      return `glyphicon-${this.glyph}`;
    })
  });
  component.reopenClass({
    positionalParams: ['glyph']
  });
  var _default = _exports.default = component;
});