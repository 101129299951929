define("@trovedata/sunstone-ui-commons/utils/verify-enum-value", ["exports", "@ember/array", "@ember/utils"], function (_exports, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = verifyEnumValue;
  function verifyEnumValue(val, dataType) {
    let numTypes = (0, _array.A)(['LONG', 'DOUBLE', 'INTEGER']);
    if (numTypes.includes(dataType)) {
      if ((0, _utils.isNone)(val.numValue)) {
        return (0, _utils.isNone)(val.strValue) ? null : 'strValue';
      } else {
        return 'numValue';
      }
    } else {
      if ((0, _utils.isNone)(val.strValue)) {
        return (0, _utils.isNone)(val.numValue) ? null : 'numValue';
      } else {
        return 'strValue';
      }
    }
  }
});