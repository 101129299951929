define("ember-svg-jar/inlined/business-graph-pie-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M19 13h-8V5a1.002 1.002 0 00-1.124-.992C5.386 4.569 2 8.435 2 13c0 4.962 4.037 9 9 9 4.566 0 8.432-3.386 8.992-7.876A1 1 0 0019 13zm-8 7c-3.859 0-7-3.14-7-7 0-3.166 2.082-5.898 5-6.771V14a1 1 0 001 1h7.771c-.873 2.917-3.605 5-6.771 5z\"/><path d=\"M13 2a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1c0-4.962-4.037-9-9-9zm1 8V4.071A7.016 7.016 0 0119.929 10H14z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});