define("@trovedata/sunstone-ui-commons/components/trove-map-polylines/component", ["exports", "@ember/object", "@ember/component", "@ember/array", "@trovedata/sunstone-ui-commons/components/trove-map-polylines/template"], function (_exports, _object, _component, _array, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global google */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    polylines: (0, _array.A)(),
    _polylines: (0, _array.A)(),
    lineSymbol: null,
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map) {
        let map = this.map;
        let strokeColor = this.color;
        let _polylines = this._polylines;
        _polylines.forEach(_polyline => {
          _polyline.setMap(null);
        });
        (0, _object.set)(this, '_polylines', (0, _array.A)([]));
        let polylines = this.polylines;
        let lineSymbol = this.lineSymbol;
        polylines.forEach(polyline => {
          let _polyline = (0, _object.set)(this, '_polyline', new google.maps.Polyline({
            path: polyline,
            geodesic: true,
            strokeColor,
            strokeOpacity: lineSymbol ? 0 : 1,
            strokeWeight: 2,
            icons: lineSymbol ? [{
              icon: lineSymbol,
              offset: '0',
              repeat: '10px'
            }] : null,
            map
          }));
          _polylines.pushObject(_polyline);
        });
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      let _polylines = this._polylines;
      _polylines.forEach(_polyline => {
        _polyline.setMap(null);
      });
      (0, _object.set)(this, '_polylines', (0, _array.A)([]));
    }
  });
});