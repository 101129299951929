define("@trovedata/sunstone-ui-commons/utils/model-utils", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanupInstance = cleanupInstance;
  _exports.createInstanceWithParameters = createInstanceWithParameters;
  async function createInstanceWithParameters(store, model, parameterExclusions = []) {
    const parameterExclusionsMap = parameterExclusions.reduce((map, parameter) => {
      map[`${parameter.id}`] = parameter;
      return map;
    }, {});
    const parameters = await model.get('parameters');
    const modelInstance = store.createRecord('model-instance', {
      model
    });
    const modelInstanceParameters = [];
    parameters.forEach(modelParameter => {
      if (!parameterExclusionsMap[`${modelParameter.id}`]) {
        modelInstanceParameters.push(store.createRecord('model-instance-parameter', {
          modelInstance,
          modelParameter
        }));
      }
    });
    modelInstance.set('modelInstanceParameters', modelInstanceParameters);
    return modelInstance;
  }
  function cleanupInstance(instance) {
    instance.modelInstanceParameters.reduce((arr, modelInstanceParameter) => {
      if (modelInstanceParameter.get('isNew')) {
        arr.push(modelInstanceParameter);
      }
      return arr;
    }, (0, _array.A)([])).invoke('destroyRecord');
    if (instance.get('isNew')) {
      instance.destroyRecord();
    }
  }
});