define("@trovedata/sunstone-ui-commons/components/paper-range-slider/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="md-slider-wrapper">
    <div class="md-slider-content">
      <div class="md-track-container">
        <div class="md-track"></div>
        <div class="md-track md-track-fill" style={{activeTrackStyle}}></div>
        <div class="md-track-ticks"></div>
      </div>
  
      <div class="md-thumb-container" style={{thumbContainerStyleMin}}>
        <div class="md-thumb" style="touch-action: none"></div>
        <div class="md-focus-thumb"></div>
        <div class="md-focus-ring md-min"></div>
        <div class="md-sign">
          <span class="md-thumb-text">{{minValue}}</span>
        </div>
        <div class="md-disabled-thumb"></div>
      </div>
  
      <div class="md-thumb-container" style={{thumbContainerStyleMax}}>
        <div class="md-thumb" style="touch-action: none"></div>
        <div class="md-focus-thumb"></div>
        <div class="md-focus-ring md-max"></div>
        <div class="md-sign">
          <span class="md-thumb-text">{{maxValue}}</span>
        </div>
        <div class="md-disabled-thumb"></div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "5GlXJ9S2",
    "block": "[[[10,0],[14,0,\"md-slider-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-slider-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"md-track-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-track\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-track md-track-fill\"],[15,5,[36,0]],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-track-ticks\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"md-thumb-container\"],[15,5,[36,1]],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-thumb\"],[14,5,\"touch-action: none\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-thumb\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-ring md-min\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-sign\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"md-thumb-text\"],[12],[1,[34,2]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-disabled-thumb\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"md-thumb-container\"],[15,5,[36,3]],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-thumb\"],[14,5,\"touch-action: none\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-thumb\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-ring md-max\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-sign\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"md-thumb-text\"],[12],[1,[34,4]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-disabled-thumb\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"activeTrackStyle\",\"thumbContainerStyleMin\",\"minValue\",\"thumbContainerStyleMax\",\"maxValue\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-range-slider/template.hbs",
    "isStrictMode": false
  });
});