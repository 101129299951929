define("@trovedata/sunstone-ui-commons/models/equipment-location", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    geoLatitude: (0, _model.attr)('number'),
    geoLongitude: (0, _model.attr)('number'),
    premise: (0, _model.belongsTo)('premise')
  });
});