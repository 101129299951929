define("@trovedata/sunstone-ui-commons/models/data-pill", ["exports", "@ember/object/computed", "rsvp", "@ember/array", "@ember/object", "@ember-data/model", "ember-data-model-fragments/fragment", "@trovedata/sunstone-ui-commons/utils/verify-enum-value", "ember-data-model-fragments/attributes"], function (_exports, _computed, _rsvp, _array, _object, _model, _fragment, _verifyEnumValue, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  var _default = _exports.default = _fragment.default.extend({
    dataFieldId: (0, _model.attr)('string'),
    indexerId: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    dataType: (0, _model.attr)('string'),
    defaultThirdPartyField: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    visible: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    filters: (0, _attributes.fragmentArray)('data-field-filter'),
    rollbackPill: null,
    reloadFilters: null,
    score: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    statistics: (0, _array.A)(),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'statistics', (0, _array.A)([]));
    },
    isDefault: (0, _computed.bool)('defaultThirdPartyField'),
    isEnum: (0, _object.computed)('statistics.[]', function () {
      return this.statistics.isAny('enumerationId');
    }),
    enumValueType: (0, _object.computed)('dataType', 'enumValues.[]', function () {
      return this.enumValues.then(enumValues => {
        let valType = (0, _array.A)(enumValues.map(val => {
          return (0, _verifyEnumValue.default)(val, this.dataType);
        })).uniq();
        return valType.length === 1 ? valType[0] : null;
      });
    }),
    // The enum statistic is assumed to be the first for now
    enumValues: (0, _object.computed)('isEnum', 'statistics', 'store', function () {
      if (this.isEnum) {
        let enumStat = (0, _array.A)(this.statistics.filterBy('enumerationId'));
        return this.store.findRecord('enum', enumStat.get('firstObject.enumerationId')).then(enumType => {
          return (0, _object.get)(enumType, 'enumValues').then(values => {
            return values;
          });
        });
      } else {
        return new _rsvp.Promise(resolve => {
          resolve((0, _array.A)([]));
        });
      }
    }),
    updateFilterPositions() {
      let promise = new _rsvp.Promise(resolve => {
        this.filters.forEach((filter, index) => {
          (0, _object.set)(filter, 'position', index + 1);
        });
        resolve();
      });
      return promise;
    },
    addFilter() {
      let filter = {
        usedEnumValue: (0, _object.get)(this, 'enumValueType._result'),
        type: this.dataType
      };
      this.filters.addFragment(filter);
      return this.updateFilterPositions();
    },
    deserialize() {
      (0, _object.set)(this, 'reloadFilters', this.filters.copy());
      let filters = this.filters;
      return this.enumValueType.then(enumValueType => {
        if (this.isEnum && enumValueType) {
          return this.enumValues.then(enumValues => {
            filters.map(filter => {
              let maxValues = (0, _object.get)(filter, 'maxValues').map(value => {
                return enumValueType === 'numValue' ? enumValues.findBy(`${enumValueType}`, JSON.parse(value)) : enumValues.findBy(`${enumValueType}`, value);
              });
              let minValues = (0, _object.get)(filter, 'minValues').map(value => {
                return enumValueType === 'numValue' ? enumValues.findBy(`${enumValueType}`, JSON.parse(value)) : enumValues.findBy(`${enumValueType}`, value);
              });
              (0, _object.set)(filter, 'maxValues', maxValues);
              (0, _object.set)(filter, 'minValues', minValues);
              (0, _object.set)(filter, 'usedEnumValue', enumValueType);
            });
            (0, _object.set)(this, 'rollbackPill', JSON.stringify(this));
            return this.updateFilterPositions();
          });
        } else {
          (0, _object.set)(this, 'rollbackPill', JSON.stringify(this));
          return this.updateFilterPositions();
        }
      });
    },
    regenerateFilter(filters) {
      return this.enumValueType.then(enumValueType => {
        if (this.isEnum && enumValueType) {
          return this.enumValues.then(enumValues => {
            filters.map(filter => {
              let maxValues = (0, _object.get)(filter, 'maxValues').map(value => {
                return enumValueType === 'numValue' ? enumValues.findBy(`${enumValueType}`, JSON.parse(value)) : enumValues.findBy(`${enumValueType}`, value);
              });
              let minValues = (0, _object.get)(filter, 'minValues').map(value => {
                return enumValueType === 'numValue' ? enumValues.findBy(`${enumValueType}`, JSON.parse(value)) : enumValues.findBy(`${enumValueType}`, value);
              });
              (0, _object.set)(filter, 'maxValues', maxValues);
              (0, _object.set)(filter, 'minValues', minValues);
            });
            return this.updateFilterPositions();
          });
        } else {
          filters.map(filter => {
            let regeneratedfilter = {
              type: this.dataType,
              operatorName: filter.operatorName,
              maxValues: (0, _array.A)(filter.maxValues),
              minValues: (0, _array.A)(filter.minValues)
            };
            this.filters.addFragment(regeneratedfilter);
          });
          return this.updateFilterPositions();
        }
      });
    },
    removeFilter(position) {
      this.filters.removeAt(position - 1);
      return this.updateFilterPositions();
    },
    serializeFilters() {
      let promise = new _rsvp.Promise(resolve => {
        let filters = this.filters.map(filter => {
          return filter.serialize();
        });
        resolve(filters);
      });
      return promise;
    }
  });
});