define("ember-svg-jar/inlined/st-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M32.992 63.833c-.34 0-.68-.106-.97-.317l-7.089-5.151h-8.761c-.716 0-1.35-.46-1.57-1.142l-2.707-8.333-7.088-5.151a1.65 1.65 0 01-.6-1.845l2.705-8.332-2.706-8.333c-.221-.68.02-1.425.6-1.844l7.09-5.148L14.6 9.904a1.653 1.653 0 011.57-1.142h8.762l7.089-5.148a1.654 1.654 0 011.94 0l7.089 5.148h8.761c.716 0 1.35.459 1.57 1.142l2.707 8.333 7.088 5.148a1.65 1.65 0 01.6 1.844l-2.705 8.333 2.706 8.332c.221.68-.02 1.426-.6 1.845l-7.09 5.151-2.705 8.333a1.653 1.653 0 01-1.57 1.142H41.05l-7.089 5.15c-.29.212-.63.314-.97.314v.004zm-15.619-8.765h8.098c.35 0 .687.109.97.317l6.551 4.758 6.55-4.758c.281-.205.62-.317.97-.317h8.099l2.501-7.702c.11-.33.317-.62.6-.825l6.551-4.759-2.501-7.702a1.632 1.632 0 010-1.02l2.501-7.702-6.55-4.759a1.673 1.673 0 01-.6-.825l-2.502-7.702h-8.098c-.35 0-.687-.109-.97-.317l-6.551-4.758-6.55 4.758c-.281.205-.62.317-.97.317h-8.099l-2.501 7.702c-.11.33-.317.62-.6.825L7.72 25.358l2.501 7.702c.109.33.109.69 0 1.02l-2.501 7.702 6.55 4.759c.28.204.492.495.6.825l2.502 7.702z\"/><path d=\"M33 38.518c-.91 0-1.65-.739-1.65-1.65v-16.5a1.65 1.65 0 013.3 0v16.5c0 .911-.74 1.65-1.65 1.65zM33 48.418c-.91 0-1.65-.739-1.65-1.65v-3.3a1.65 1.65 0 013.3 0v3.3c0 .911-.74 1.65-1.65 1.65z\"/>",
    "attrs": {
      "width": "66",
      "height": "66",
      "viewBox": "0 0 66 66",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});