define("@trovedata/trove-scenario-planner/adapters/canopy-buffer", ["exports", "@trovedata/sunstone-ui-commons/adapters/application-json-api"], function (_exports, _applicationJsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CanopyBuffer extends _applicationJsonApi.default {
    getFallbackNamespace() {
      return this.config.get('trove.troveGisService.namespace');
    }
    getTargetBackendServiceId() {
      return 'gis-service';
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = CanopyBuffer;
});