define("@trovedata/sunstone-ui-commons/serializers/application", ["exports", "@ember/string", "@trovedata/sunstone-ui-commons/serializers/hal-serializer"], function (_exports, _string, _halSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-prototype-builtins */

  const COMPLIANCE_MAP = {
    lastUpdateBy: true,
    insertBy: true,
    orgId: true
  };
  var _default = _exports.default = _halSerializer.default.extend({
    normalizeResponse(store, typeClass, payload, id, requestType) {
      if (payload.content) {
        let data = payload.content;
        data._links = payload._links;
        payload = data;
      }
      return this._super(store, typeClass, payload, id, requestType);
    },
    extractRelationships(modelClass, payload) {
      modelClass.eachRelationship(key => {
        let relationshipKey = this.keyForRelationship(key);
        if (payload.hasOwnProperty(relationshipKey)) {
          payload._embedded = payload._embedded || {};
          payload._embedded[relationshipKey] = payload[relationshipKey];
        }
      });
      return this._super(...arguments);
    },
    extractMeta(store, typeClass, payload) {
      if (payload && payload.hasOwnProperty('page')) {
        let meta = payload.page;
        delete payload.page;
        return meta;
      }
    },
    keyForAttribute(attr) {
      return (0, _string.camelize)(attr);
    },
    keyForRelationship(attr) {
      return (0, _string.camelize)(attr);
    },
    serialize(snapshot, options) {
      let json = {};
      if (options && options.includeId) {
        const {
          id
        } = snapshot;
        if (id) {
          json[this.primaryKey] = id;
        }
      }
      snapshot.eachAttribute((name, attribute) => {
        this.serializeAttribute(snapshot, json, name, attribute);
      });
      snapshot.eachRelationship((name, relationship) => {
        if (!relationship.options.async && relationship.options.async !== undefined) {
          let relationshipKind = relationship.kind;
          let relationshipMethod = `serialize${(0, _string.capitalize)(relationshipKind)}`;
          this[relationshipMethod](snapshot, json, relationship);
        } else {
          let adapter = this.store.adapterFor(relationship.type);
          if (relationship.kind === 'belongsTo') {
            let belongsTo = snapshot.belongsTo(name);
            if (belongsTo) {
              json[name] = COMPLIANCE_MAP[relationship.name] || relationship.options && relationship.options.id ? belongsTo.record.id : adapter.buildURL(relationship.type.modelName || relationship.type, belongsTo.record.id);
            }
          }
          if (relationship.kind === 'hasMany') {
            let hasMany = snapshot.hasMany(name);
            if (hasMany && relationship.options?.serialize !== false) {
              let existingHasManyRelationships = hasMany.filter(hasManyRelationship => {
                return !hasManyRelationship.record.get('isNew');
              });
              json[name] = existingHasManyRelationships.map(hasManyRelationship => {
                return adapter.buildURL(hasManyRelationship.type.modelName || hasManyRelationship.type, hasManyRelationship.record.id);
              });
            }
          }
        }
      });
      return json;
    },
    serializeAttribute(snapshot, json, key) {
      if (this._canSerialize(key)) {
        let payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key) {
          payloadKey = this.keyForAttribute(key, 'serialize');
        }
        this._super(...arguments);
        json[payloadKey] = json.attributes[payloadKey];
        delete json.attributes;
      }
    },
    serializeHasMany(snapshot, json, relationship) {
      const {
        key
      } = relationship;
      if (this._shouldSerializeHasMany(snapshot, key, relationship)) {
        this._serializeRelationship(...arguments);
      }
    },
    serializeBelongsTo(snapshot, json, relationship) {
      const {
        key
      } = relationship;
      if (this._canSerialize(key)) {
        this._serializeRelationship(...arguments);
      }
    },
    _serializeRelationship(snapshot, json, relationship) {
      const {
        key,
        kind
      } = relationship;
      const relation = snapshot[kind](key);
      let data = null;
      if (relation !== undefined) {
        let payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, kind, 'serialize');
        }
        if (kind === 'hasMany') {
          data = relation.map(item => {
            return item.serialize({
              includeId: true
            });
          });
        } else {
          if (relation) {
            if (this._hasSerializeRecordsOption(key)) {
              data = relation.serialize({
                includeId: true
              });
            } else {
              data = relation.id;
            }
          }
        }
        json[payloadKey] = data;
      }
    },
    _hasEmbeddedAlwaysOption(key) {
      let option = this.get(`attrs.${key}`);
      return option && option.embedded === 'always';
    },
    _hasSerializeRecordsOption(key) {
      let alwaysEmbed = this._hasEmbeddedAlwaysOption(key);
      let option = this.get(`attrs.${key}`);
      return alwaysEmbed || option && option.serialize === 'records';
    }
  });
});