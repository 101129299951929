define("@trovedata/trove-scenario-planner/services/budget", ["exports", "@ember/application", "@ember/object", "@ember/service", "@glimmer/tracking", "@trovedata/trove-scenario-planner/models/budget-entry", "ember-concurrency-decorators"], function (_exports, _application, _object, _service, _tracking, _budgetEntry, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Budget = _exports.default = (_dec = (0, _emberConcurrencyDecorators.lastValue)('getRegionBudgetData'), _dec2 = (0, _object.computed)('byRegionBudgetData.[]'), (_class = class Budget extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "budgetYear", _descriptor, this);
      _initializerDefineProperty(this, "byRegionBudgetData", _descriptor2, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor3, this);
      _initializerDefineProperty(this, "config", _descriptor4, this);
      _defineProperty(this, "regionId", 1);
      _defineProperty(this, "endpointNamespace", 'budgetSummary');
    }
    get budgetDataByGrouping() {
      return this.byRegionBudgetData?.reduce((hash, entry) => {
        hash[`${entry.grouping.toLowerCase()}`] = entry;
        return hash;
      }, {});
    }
    async getBudgetSummaryByRegion() {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/byRegion?year=${this.budgetYear}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }
    async getBudgetSummaryByExpenditureType(regionName, year) {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/byExpenditureType?year=${year}&regionName=${regionName}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }
    async getBudgetSummaryByServiceArea(regionName, year) {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/bySubRegion?year=${year}&regionName=${regionName}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }

    /**
     * @deprecated To be removed in future version. Use getBudgetSummaryByServiceArea() instead.
     */
    async getBudgetSummaryBySubRegion() {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/bySubRegion?year=${this.budgetYear}&regionId=${this.regionId}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }

    /**
     * @deprecated To be removed in future version.  Use getBudgetSummaryByExpenditureType() instead.
     */
    async getBudgetSummaryByExpenseType() {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/byExpenseType?year=${this.budgetYear}&regionId=${this.regionId}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }

    /**
     * @deprecated To be removed in future version.  Discontinue all usage.
     */
    async getBudgetSummaryBySubRegionAndCategory() {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/bySubRegionAndCategory?year=${this.budgetYear}&regionId=${this.regionId}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }

    /**
     * @deprecated To be removed in future version.  Discontinue all usage.
     */
    async getBudgetSummaryByCategory() {
      const response = await this.troveFetch.request(`${this.endpointNamespace}/byCategory?year=${this.budgetYear}&regionId=${this.regionId}`, {
        namespace: this.config.get('trove.troveBudgetService.namespace'),
        serviceId: 'budget-service'
      });
      return this.parseBudgetSummary(response);
    }
    *getRegionBudgetData() {
      return yield this.troveFetch.resolvePromiseWithToast(this.getBudgetSummaryByRegion(), {
        errorMessage: 'Failed to get region budget data'
      });
    }
    parseBudgetSummary(response) {
      const parsedSummaries = response.map(item => _budgetEntry.default.create({
        container: (0, _application.getOwner)(this),
        ...item
      }));
      return parsedSummaries;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "budgetYear", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "byRegionBudgetData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "budgetDataByGrouping", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "budgetDataByGrouping"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getRegionBudgetData", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getRegionBudgetData"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});