define("@trovedata/trove-scenario-planner/services/agg-geom", ["exports", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators"], function (_exports, _service, _tracking, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AggGeom = _exports.default = (_class = class AggGeom extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "flashMessages", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "cachedLineSpanGeomGroups", {});
      _defineProperty(this, "limit", 50);
      _initializerDefineProperty(this, "fetchProgress", _descriptor3, this);
    }
    stopFetching() {
      // @ts-ignore
      this.getGeomsForSelectedAgg.cancelAll();
    }

    /**
     * Task responsible for checking if span geometries for the supplied line name have aleady been cached.  If they have not yet
     * been fetched and cached, this function will invoke the appropriate task to fetch the span geometries for the supplied line
     * and add them to the cache.
     */
    *cacheLineSpanGeoms(year, grouping, lineName, planId) {
      const {
        cachedLineSpanGeomGroups,
        cacheLineSpansTask
      } = this;
      if (!cachedLineSpanGeomGroups[lineName]) {
        // @ts-ignore
        cachedLineSpanGeomGroups[lineName] = yield cacheLineSpansTask.perform(year, grouping, lineName, planId);
      }
    }

    /**
     * Finds the spans belonging to the supplied line (which are also still within the plan).  Then, after spans are retrieved,
     * pulls the geometries for those spans and "caches" them as a mapping keyed by the line name where the value is another mapping
     * between the span name and the geometry.
     */
    *cacheLineSpansTask(year, grouping, lineName, planId) {
      const {
        flashMessages,
        getGeomsForSelectedAgg,
        store
      } = this;
      try {
        const spanAggs = yield store.query('span-agg', {
          endpoint: 'findAllCollectionBySpecification',
          params: {
            planId,
            region: grouping,
            search: `lineName:${lineName}`,
            year
          }
        });
        // @ts-ignore
        return yield getGeomsForSelectedAgg.perform(year, grouping, 'span-agg', spanAggs.toArray());
      } catch (error) {
        flashMessages.danger('Span agg caching failed. Try refreshing the page.');
        return;
      }
    }
    *fetchGeoms(year, region, aggs, aggType) {
      const model = aggType.replace('agg', 'geom');
      const endpoint = 'findByYearAndRegionAndNameIn';
      // @ts-ignore
      const names = aggs.mapBy('name').join(',');
      const params = {
        names,
        region,
        year
      };
      const geoms = yield this.store.query(model, {
        endpoint,
        params
      });
      // @ts-ignore
      return geoms.map(geom => ({
        agg: aggs.findBy('name', geom.name),
        geom
      }));
    }
    *getGeomsForSelectedAgg(year, groupingName, aggType, aggs) {
      if (!groupingName || !aggs?.length) return [];
      this.fetchProgress = 0;
      const {
        fetchGeoms,
        flashMessages,
        limit
      } = this;
      const aggGeomGroups = [];
      const totalPages = Math.ceil(aggs.length / limit);
      let page = 1;
      while (aggs.length) {
        this.fetchProgress = page / totalPages;
        const pagedAggs = aggs.splice(0, limit);
        let pagedGroups;
        try {
          // @ts-ignore
          pagedGroups = yield fetchGeoms.perform(year, groupingName, pagedAggs, aggType);
        } catch (error) {
          flashMessages.danger('Geom request failed.');
          return;
        }

        // @ts-ignore
        aggGeomGroups.pushObjects(pagedGroups);
        page++;
      }
      return aggGeomGroups;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetchProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "cacheLineSpanGeoms", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "cacheLineSpanGeoms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cacheLineSpansTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "cacheLineSpansTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchGeoms", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchGeoms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getGeomsForSelectedAgg", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getGeomsForSelectedAgg"), _class.prototype)), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});