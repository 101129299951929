define("ember-svg-jar/inlined/move-back-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M7.707 14.292l-2.293-2.293H19v5h2v-5a2 2 0 00-2-2H5.414l2.293-2.293-1.414-1.414-4.707 4.707 4.707 4.707 1.414-1.414z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});