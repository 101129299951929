define("@trovedata/sunstone-ui-commons/application/route", ["exports", "@ember/service", "@ember/routing/route", "@ember/object", "@ember/utils", "@ember/array"], function (_exports, _service, _route, _object, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // @ts-ignore
  let ApplicationRoute = _exports.default = (_class = class ApplicationRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "authenticationRoute", 'login');
      _defineProperty(this, "routeAfterAuthentication", 'application');
      _initializerDefineProperty(this, "appConfig", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "sunstoneEnv", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "config", _descriptor5, this);
      _initializerDefineProperty(this, "keycloakSession", _descriptor6, this);
      _defineProperty(this, "controller", void 0);
    }
    activate() {
      super.activate(...arguments);
      const $loader = document.querySelector('.app-loader');
      const loaderText = document.querySelector('.app-loader-text');
      if ($loader && loaderText) {
        loaderText.setAttribute('class', 'app-loader-text loaded');
        loaderText.addEventListener('transitionend', () => {
          if ($loader) {
            $loader.remove();
          }
        }, false);
      }
    }

    /**
     * Collect keycloak options and install the Keycloak service.
     */
    init() {
      super.init(...arguments);
      const {
        config
      } = this;
      const options = {
        url: config.get('PUBLIC_KEYCLOAK_APP_URL'),
        realm: config.get('PUBLIC_KEYCLOAK_REALM'),
        clientId: config.get('PUBLIC_KEYCLOAK_CLIENT_ID')
      };
      this.keycloakSession.installKeycloak(options);
    }
    beforeModel() {
      return this.keycloakSession.initKeycloak();
    }
    async model() {
      // @ts-ignore
      await this.appConfig.getAppConfig.perform();
      return {};
    }
    async afterModel() {
      await this.keycloakSession.loadUserProfile();
    }
    setupController(controller) {
      super.setupController(...arguments);
      controller.themeObserver();
    }

    /*
     * Implements the RouteInfo Metadata API
     */
    buildRouteInfoMetadata() {
      return {
        updateKeycloakToken: true
      };
    }
    catchError(error) {
      if (error && error.isAdapterError && error.errors.cause) {
        (0, _object.set)(this, 'controller.error', error.errors);
      } else if (error && error.errors && (0, _utils.typeOf)(error.errors) === 'array' && error.errors.length) {
        let selectedError = (0, _object.get)((0, _array.A)(error.errors.filter(err => {
          return err.status && err.status !== '200' && err.status !== '401' && err.status !== '404' && err.detail;
        })), 'firstObject');
        if (selectedError) {
          this.controller.set('error', {
            cause: selectedError.detail,
            error: selectedError.error
          });
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appConfig", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sunstoneEnv", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "catchError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "catchError"), _class.prototype)), _class);
});