define("@trovedata/sunstone-ui-commons/adapters/application-json-api", ["exports", "ember-data", "@ember/service", "@ember/string", "@ember/object"], function (_exports, _emberData, _service, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    JSONAPIAdapter,
    AdapterError
  } = _emberData.default;
  let ApplicationJsonApi = _exports.default = (_dec = (0, _object.computed)('keycloakSession.keycloak'), (_class = class ApplicationJsonApi extends JSONAPIAdapter {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "apiGateway", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      _initializerDefineProperty(this, "keycloakSession", _descriptor3, this);
    }
    init() {
      super.init();
      const id = this.getTargetBackendServiceId();
      const serviceInfo = this.apiGateway.getServiceById(id);
      if (serviceInfo) {
        if (serviceInfo.baseRoute.startsWith('/')) {
          // The service is behind the api-gateway
          this.host = this.config.get('trove.apiGatewayBaseUrl');
          this.namespace = serviceInfo.baseRoute.substring(1, serviceInfo.baseRoute.length - 1);
        } else {
          // The service is on a unique host
          this.host = `https://${serviceInfo.baseRoute}`;
          this.namespace = '';
        }
      } else {
        // We were unable to get serviceinfo from the api-gateway, fallback to the
        // old logic from the config
        this.host = this.config.get('trove.api.host');
        this.namespace = this.getFallbackNamespace();
        console.warn(`serviceInfo for backend service ${this.getTargetBackendServiceId()} not found, defaulting to ${this.namespace}`);
      }
    }
    handleResponse(_status, _headers, payload, _options) {
      this._super(...arguments);
      if (this.isSuccess(_status, this.headers, payload)) {
        return payload;
      } else {
        return new AdapterError(payload);
      }
    }
    normalizeErrorResponse(status, headers, payload) {
      if (payload && typeof payload === 'object') {
        return [{
          detail: 'Uncaught Exception',
          source: {
            pointer: '/data'
          }
        }];
      }
      return this._super(status, headers, payload);
    }
    isInvalid(status) {
      return status >= 400 && status !== 401;
    }
    ajaxOptions(url, type, options) {
      const newOptions = super.ajaxOptions(url, type, options);
      newOptions.xhrFields = {
        withCredentials: true
      };
      if (newOptions.data && newOptions.type !== 'GET') {
        newOptions.contentType = 'application/hal+json; charset=utf/8;';
      }
      return newOptions;
    }
    get headers() {
      const headers = {
        'Accept': 'application/hal+json,application/json'
      };
      if (this.keycloakSession.token) {
        headers['Authorization'] = `Bearer ${this.keycloakSession.token}`;
      }
      return headers;
    }
    shouldReloadAll() {
      return true;
    }
    pathForType(type) {
      return (0, _string.camelize)(type);
    }
    query(_store, type, query) {
      let url = this.buildURL(type.modelName, null, null, 'query', query);
      if (query.endpoint || query.endpoint === '') {
        query = query.params;
      }
      if (this.sortQueryParams) {
        query = this.sortQueryParams(query ? query : {});
      }
      return this.ajax(url, 'GET', {
        data: query
      });
    }

    // @ts-ignore
    urlForQueryRecord() {
      // @ts-ignore
      return this.urlForQuery(...arguments);
    }

    // @ts-ignore
    urlForQuery(query, modelName) {
      // @ts-ignore
      const baseUrl = this._buildURL(modelName);
      if (query.endpoint) {
        let endpoint = `${baseUrl}/search/${query.endpoint}`;
        delete query.endpoint;
        Object.keys(query.params ? query.params : {}).forEach(key => {
          query[key] = query.params[key];
        });
        delete query.params;
        return endpoint;
      } else {
        let queryKeys = Object.keys(query);
        let searchableQueryKeys = queryKeys.filter(key => ['page', 'size', 'projection'].indexOf(key) === -1);
        return searchableQueryKeys.length === 1 && query.endpoint === undefined ? `${baseUrl}/search/findBy${(0, _string.capitalize)((0, _string.camelize)(searchableQueryKeys[0]))}` : baseUrl;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiGateway", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
});