define("ember-svg-jar/inlined/business-graph-line-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20 4H4c-1.104 0-2 .898-2 2v12c0 1.103.896 2 2 2h16c1.104 0 2-.897 2-2V6c0-1.102-.896-2-2-2zm-.003 14H4V6h16l-.003 12z\"/><path d=\"M13.895 8.553a1.001 1.001 0 00-1.824.076l-1.437 3.592-.928-.928a1 1 0 00-1.262-.125L6 12.798v2.404l2.873-1.915 1.42 1.419a1 1 0 001.636-.335l1.173-2.932 1.004 2.008a1 1 0 001.45.385L18 12.202V9.798l-2.612 1.742-1.493-2.987z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});