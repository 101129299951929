define("ember-svg-jar/inlined/location-globe-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M10 18c-4.411 0-8-3.589-8-8 0-2.021.759-3.864 2-5.274V10h2.279l2.211 6.633L14 12.5V6h-3V2.069c3.94.495 7 3.859 7 7.931 0 .276-.014.55-.042.819l1.99.202c.034-.335.052-.676.052-1.021 0-5.514-4.486-10-10-10S0 4.486 0 10s4.486 10 10 10c.375 0 .745-.021 1.108-.06l-.219-1.989A8.163 8.163 0 0110 18zm2-10v3.5l-2.49 1.867L7.721 8H6V3.083A7.933 7.933 0 019 2.07V8h3z\"/><path d=\"M21.293 16.293L19 18.586V13h-2v5.586l-2.293-2.293-1.414 1.414L18 22.414l4.707-4.707z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});