define("@trovedata/sunstone-ui-commons/components/success-animation/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg 
    id="successAnimation" 
    class="animated" 
    xmlns="http://www.w3.org/2000/svg" 
    width={{size}} 
    height={{size}} 
    viewBox="0 0 70 70"
  >
    <path 
      id="successAnimationResult" 
      fill="#D8D8D8" 
      d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
    />
    <circle 
      id="successAnimationCircle" 
      cx="35" 
      cy="35"
      r="24"
      stroke="#979797" 
      stroke-width="2" 
      stroke-linecap="round" 
      fill="transparent"
    />
    <polyline 
      id="successAnimationCheck" 
      stroke="#979797"
      stroke-width="2" 
      points="23 34 34 43 47 27" 
      fill="transparent"
    />
  </svg>
  
  */
  {
    "id": "robVw1N9",
    "block": "[[[10,\"svg\"],[14,1,\"successAnimation\"],[14,0,\"animated\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[15,\"width\",[36,0]],[15,\"height\",[36,0]],[14,\"viewBox\",\"0 0 70 70\"],[12],[1,\"\\n  \"],[10,\"path\"],[14,1,\"successAnimationResult\"],[14,\"fill\",\"#D8D8D8\"],[14,\"d\",\"M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z\"],[12],[13],[1,\"\\n  \"],[10,\"circle\"],[14,1,\"successAnimationCircle\"],[14,\"cx\",\"35\"],[14,\"cy\",\"35\"],[14,\"r\",\"24\"],[14,\"stroke\",\"#979797\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"fill\",\"transparent\"],[12],[13],[1,\"\\n  \"],[10,\"polyline\"],[14,1,\"successAnimationCheck\"],[14,\"stroke\",\"#979797\"],[14,\"stroke-width\",\"2\"],[14,\"points\",\"23 34 34 43 47 27\"],[14,\"fill\",\"transparent\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"size\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/success-animation/template.hbs",
    "isStrictMode": false
  });
});