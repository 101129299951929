define("ember-svg-jar/inlined/st-major-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"32\" height=\"32\" rx=\"16\" fill=\"#D84656\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.586 7.586a2 2 0 012.828 0l6 6a2 2 0 11-2.828 2.828L18 13.828v1.764l3.317 2.903a2 2 0 01-2.634 3.01L18 20.907V24a2 2 0 11-4 0v-3.093l-.683.598a2 2 0 01-2.634-3.01L14 15.592v-1.764l-2.586 2.586a2 2 0 11-2.828-2.828l6-6z\" fill=\"#2C3134\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});