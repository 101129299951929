define("@trovedata/sunstone-ui-commons/models/segment", ["exports", "@ember/object/computed", "@ember/array", "rsvp", "@ember/object", "jquery", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _computed, _array, _rsvp, _object, _jquery, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  var _default = _exports.default = _model.default.extend({
    code: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    name: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    description: (0, _model.attr)('string'),
    color: (0, _model.attr)('string'),
    profileId: (0, _model.attr)('number'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    startTime: (0, _model.attr)('basic-date'),
    completeTime: (0, _model.attr)('basic-date'),
    executionTime: (0, _model.attr)('number'),
    definition: (0, _attributes.fragment)('definition'),
    visible: true,
    transactionId: null,
    previewed: false,
    count: null,
    pillsChanged: false,
    clondedSegment: null,
    init() {
      this._super(...arguments);
      // set(this, 'transactionId', uuid()); // Find another dep for uuid()
    },
    dataFieldPills: (0, _computed.alias)('definition.dataFieldPills'),
    allPillsInvisible: (0, _object.computed)('definition.dataFieldPills.[]', function () {
      let test = (0, _object.get)(this, 'definition.dataFieldPills').every(pill => {
        return !(0, _object.get)(pill, 'visible');
      });
      return test;
    }),
    generateDefaults(defaults) {
      let promises = defaults.map(dataField => {
        return this.createPill(dataField).then(dataFieldPill => {
          (0, _object.set)(dataFieldPill, 'visible', false);
          return dataFieldPill;
        });
      });
      return (0, _rsvp.allSettled)(promises).then(arr => {
        return (0, _array.A)(arr).getEach('value');
      });
    },
    generatePills() {
      return (0, _object.get)(this, 'definition.dataFieldPills').map(dataField => {
        return this.createPill(_object.default.create(dataField)).then(dataFieldPill => {
          return dataFieldPill;
        });
      });
    },
    removePill(dataFieldPill) {
      (0, _object.get)(this, 'definition.dataFieldPills').removeObject(dataFieldPill);
    },
    createPill(dataField) {
      let dataFieldId = (0, _object.get)(dataField, 'dataFieldId') ? (0, _object.get)(dataField, 'dataFieldId') : (0, _object.get)(dataField, 'id');
      let dataFieldPills = (0, _object.get)(this, 'definition.dataFieldPills');
      let newPill = {
        dataFieldId,
        name: (0, _object.get)(dataField, 'name'),
        displayName: (0, _object.get)(dataField, 'displayName'),
        dataType: (0, _object.get)(dataField, 'dataType'),
        defaultThirdPartyField: (0, _object.get)(dataField, 'defaultThirdPartyField'),
        description: (0, _object.get)(dataField, 'description')
      };
      if ((0, _object.get)(dataField, 'indexerId') !== undefined) {
        (0, _object.set)(newPill, 'indexerId', (0, _object.get)(dataField, 'indexerId'));
      } else {
        let sortedArr = dataFieldPills.sortBy('indexerId');
        if (sortedArr.length) {
          let index = (0, _object.get)(sortedArr, 'lastObject.indexerId') + 1;
          (0, _object.set)(newPill, 'indexerId', index);
        } else {
          (0, _object.set)(newPill, 'indexerId', 1);
        }
      }
      (0, _object.get)(this, 'definition.dataFieldPills').addFragment(newPill);
      let pillFragment = (0, _object.get)(this, 'definition.dataFieldPills').findBy('indexerId', (0, _object.get)(newPill, 'indexerId'));
      return pillFragment.addFilter().then(() => {
        return pillFragment;
      });
    },
    getDataPillData() {
      let pills = (0, _object.get)(this, 'definition.dataFieldPills').map(pill => {
        if ((0, _object.get)(pill, 'filters.length')) {
          return pill;
        } else {
          let dataFieldId = (0, _object.get)(pill, 'dataFieldId');
          return this.store.query('data-field-statistic', {
            dataFieldId
          }).then(statistics => {
            (0, _object.set)(pill, 'statistics', (0, _array.A)(statistics));
            let dataPillFilters = (0, _array.A)((0, _object.get)(this, 'segment.filters')).findBy('indexerId', JSON.parse(pill.indexerId)).operators;
            return pill.regenerateFilter(dataPillFilters).then(() => {
              return this.store.findRecord('dataField', dataFieldId).then(dataField => {
                (0, _object.set)(pill, 'dataField', dataField);
                return pill;
              });
            });
          });
        }
      });
      return (0, _rsvp.allSettled)(pills).then(arr => {
        return arr;
      });
    },
    generateQuery() {
      let query = {
        dataFields: [],
        filters: []
      };
      let dataFieldPills = (0, _object.get)(this, 'definition.dataFieldPills');
      dataFieldPills.forEach(dataFieldPill => {
        // eslint-disable-next-line ember/no-jquery
        query.dataFields.push(_jquery.default.extend({
          indexerId: (0, _object.get)(dataFieldPill, 'indexerId'),
          visible: (0, _object.get)(dataFieldPill, 'visible')
        }, (0, _object.get)(dataFieldPill, 'dataField').getProperties(['id', 'name', 'displayName', 'dataType', 'defaultThirdPartyField', 'ontologyReference'])));
        query.filters.push({
          dataFieldId: (0, _object.get)(dataFieldPill, 'dataField.id'),
          indexerId: (0, _object.get)(dataFieldPill, 'indexerId'),
          dataType: (0, _object.get)(dataFieldPill, 'dataField.dataType'),
          operators: dataFieldPill.serializeFilters()
        });
      });
      return query;
    },
    generateDescription() {
      return this.getDataPillData().then(() => {
        let query = this.generateQuery();
        (0, _object.set)(this, 'segment', query);
        return this;
      });
    }
  });
});