define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default
  });
});