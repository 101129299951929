define("ember-svg-jar/inlined/data-download-13", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .007h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20.994 9.001a.993.993 0 00-.162-.555l-4-6A.999.999 0 0016 2.001H8a.999.999 0 00-.832.445l-4 6a.993.993 0 00-.162.555H3v11c0 1.102.896 2 2 2h14c1.104 0 2-.898 2-2v-11h-.006zm-2.863-1H13v-4h2.465l2.666 4zm-9.596-4H11v4H5.869l2.666-4zM5 20.001v-10h14v10H5z\"/><path d=\"M14 12.001h-4v3H8l4 4 4-4h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});