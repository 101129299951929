define("ember-svg-jar/inlined/calendar-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><path d=\"M20 5h-1V2h-2v5h-1V5H9V2H7v5H6V5H4c-1.103 0-2 .898-2 2v13c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V7c0-1.102-.897-2-2-2zm-.003 15H4V9h16l-.003 11z\" fill=\"#757575\"/><g><path fill=\"#757575\" d=\"M6 11h4v4H6z\"/></g><g><path fill=\"#757575\" d=\"M11 11h4v4h-4z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});