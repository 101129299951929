define("ember-svg-jar/inlined/content-view-module-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .003h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M6 8.001H4c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4z\" fill=\"#757575\"/></g><g><path d=\"M6 15.001H4c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4z\" fill=\"#757575\"/></g><g><path d=\"M6 22.001H4c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4z\" fill=\"#757575\"/></g><g><path d=\"M13 8.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g><g><path d=\"M13 15.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g><g><path d=\"M13 22.001h-2c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g><g><path d=\"M20 8.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g><g><path d=\"M20 15.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g><g><path d=\"M20 22.001h-2c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});