define("@trovedata/sunstone-ui-commons/adapters/sunstone-user-setting", ["exports", "@trovedata/sunstone-ui-commons/adapters/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    pathForType(type) {
      type = (0, _emberInflector.pluralize)(type);
      return this._super(type);
    }
  });
});