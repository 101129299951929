define("@trovedata/trove-scenario-planner/services/map-filter-service", ["exports", "@ember/object", "@ember/runloop", "@ember/service", "@glimmer/tracking", "@trovedata/trove-scenario-planner/utils/map-utils", "@trovedata/trove-scenario-planner/utils/operator-utils", "ember-concurrency-decorators", "@trovedata/trove-scenario-planner/utils/service-utils"], function (_exports, _object, _runloop, _service, _tracking, _mapUtils, _operatorUtils, _emberConcurrencyDecorators, _serviceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MapFilterService = _exports.default = (_class = class MapFilterService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor3, this);
      _defineProperty(this, "geoDataVersion", void 0);
      _defineProperty(this, "groupingId", void 0);
      _defineProperty(this, "inititalTaskInstance", void 0);
      _defineProperty(this, "mapData", void 0);
      _defineProperty(this, "snapshotFeatures", []);
      _initializerDefineProperty(this, "centroidFilterCategories", _descriptor4, this);
      _initializerDefineProperty(this, "centroids", _descriptor5, this);
      _initializerDefineProperty(this, "isLoading", _descriptor6, this);
      _initializerDefineProperty(this, "mapFilters", _descriptor7, this);
    }
    get showFilters() {
      return this.centroidFilterCategories && this.centroids.length;
    }
    get filteredCentroidAssetNames() {
      return this.centroids.filter(({
        data,
        series
      }) => {
        return this.mapFilters.every(({
          field,
          operator,
          value
        }) => {
          const featVal = field === 'series' ? series : data[field];
          return _operatorUtils.OPERATOR_MAP[operator](featVal, value);
        });
        // @ts-ignore
      }).mapBy('assetName');
    }
    get refinedCentroidFilterCategories() {
      return Object.values(this.centroidFilterCategories)?.filter(category => category.groupingKey !== 'series');
    }
    validateFilter(groupingKey, value, operator) {
      const {
        lowerBound,
        upperBound
      } = this.centroidFilterCategories[groupingKey];
      const hasFailed = value === lowerBound && operator === '>=' || value === upperBound && operator === '<=';
      if (hasFailed) {
        const filter = this.mapFilters.find(f => {
          return f.field === groupingKey && f.operator === operator;
        });
        // @ts-ignore
        if (filter) this.mapFilters.removeObject(filter);
        return false;
      } else {
        return true;
      }
    }
    replaceFilters(groupingKey, value, operator) {
      if (!this.validateFilter(groupingKey, value, operator)) return;
      const v = this.centroidFilterCategories[groupingKey].displayName;
      const displayValue = `${v} ${operator} ${value}`;
      const filters = [...this.mapFilters];
      const filter = filters.find(f => {
        return f.field === groupingKey && f.operator === operator;
      });
      if (filter) {
        (0, _object.set)(filter, 'displayValue', displayValue);
        (0, _object.set)(filter, 'value', value);
      } else {
        filters.push({
          displayValue,
          field: groupingKey,
          operator,
          value
        });
      }
      this.mapFilters = filters;
    }
    *applyMapFilter() {
      this.isLoading = true;
      // Wait for initial task instance to finish first
      if (this.inititalTaskInstance) yield this.inititalTaskInstance;
      (0, _runloop.next)(this, () => {
        const lineIds = [];
        const {
          filteredCentroidAssetNames,
          mapData,
          snapshotFeatures
        } = this;
        if (!mapData) return;
        /**
         * Generate a filtered array of features based on the centroid whitelist
         * from the original snapshot (source of truth).
         */
        const filteredFeatures = snapshotFeatures.filter(({
          name
        }) => {
          return filteredCentroidAssetNames.includes(name);
        });
        // @ts-ignore
        const filteredFeatureNames = filteredFeatures.mapBy('name');
        /**
         * See if all the current points on the map meet all the filters. If it
         * passes all the filters save the statId. If not, remove it from the map.
         */
        mapData.forEach(f => {
          const agg = f.getProperty('agg');
          if (!agg) return; // Disregard grouping feature data
          const retain = filteredFeatureNames.includes(agg.name);
          if (retain) {
            lineIds.push(agg.id);
          } else {
            mapData.remove(f);
          }
        });
        /**
         * See if any features from `filteredFeatures` that needs to be added.
         */
        const features = filteredFeatures.filter(({
          properties
        }) => {
          return !lineIds.includes(properties.agg.id);
        });
        if (features.length) mapData.addGeoJson({
          features,
          type: _mapUtils.TYPE
        });
        this.isLoading = false;
      });
    }
    isSameCriteria(geoDataVersion, groupingId) {
      return this.geoDataVersion === geoDataVersion && this.groupingId === groupingId;
    }
    *initiate(geoDataVersion, groupingId, mapData) {
      this.mapData = mapData;
      // @ts-ignore
      yield this.getAssetCentroidFilterCategories.perform(geoDataVersion, groupingId);
      // @ts-ignore
      yield this.fetchCentroidStats.perform(geoDataVersion, groupingId);
      this.geoDataVersion = geoDataVersion;
      this.groupingId = groupingId;
    }
    *getAssetCentroidFilterCategories(geoDataVersion, groupingId) {
      if (this.isSameCriteria(geoDataVersion, groupingId)) return;
      const {
        troveFetch
      } = this;
      const url = `assetCentroid/search/getFilterCategories?year=${geoDataVersion}&groupingId=${groupingId}`;
      const promise = troveFetch.request(url, {
        namespace: _serviceUtils.QUERY_SERVICE_NAMESPACE,
        serviceId: _serviceUtils.QUERY_SERVICE_ID
      });
      const cats = yield troveFetch.resolve(promise, {
        errorDefault: []
      });
      this.centroidFilterCategories = cats.sortBy('displayOrder').reduce((hash, entry) => {
        hash[`${entry.groupingKey}`] = entry;
        return hash;
      }, {
        series: {
          displayName: 'Risk',
          displayOrder: -1,
          groupingKey: 'series',
          lowerBound: 1,
          upperBound: 10
        }
      });
    }
    *fetchCentroidStats(geoDataVersion, groupingId) {
      if (this.isSameCriteria(geoDataVersion, groupingId)) return;
      const {
        store,
        troveFetch
      } = this;
      const promise = store.query('asset-centroid', {
        endpoint: 'findAllByYearAndGroupingId',
        params: {
          groupingId,
          year: geoDataVersion
        }
      });
      const errorMessage = 'Failed to get centroid stats';
      const stats = yield troveFetch.resolve(promise, {
        errorMessage
      });
      this.centroids = stats.toArray();
    }
    addFilters(attr, startValue, endValue) {
      this.replaceFilters(attr, startValue, '>=');
      this.replaceFilters(attr, endValue, '<=');
      // @ts-ignore
      this.applyMapFilter.perform();
    }
    removeFilter(filter) {
      // @ts-ignore
      this.mapFilters.removeObject(filter);
      // @ts-ignore
      this.applyMapFilter.perform();
    }
    clearFilters() {
      this.mapFilters = [];
      // @ts-ignore
      this.applyMapFilter.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "centroidFilterCategories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "centroids", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mapFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "applyMapFilter", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "applyMapFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initiate", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "initiate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAssetCentroidFilterCategories", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAssetCentroidFilterCategories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchCentroidStats", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCentroidStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});