define("ember-svg-jar/inlined/sunny", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zM11 2h2v3h-2z\"/><path transform=\"rotate(45.001 18.01 5.99)\" d=\"M17.011 4.489h2v3.002h-2z\"/><path d=\"M19 11h3v2h-3z\"/><path transform=\"rotate(-135.016 18.01 18.011)\" d=\"M16.51 17.011h3.002v1.999H16.51z\"/><path d=\"M11 19h2v3h-2z\"/><path transform=\"rotate(-135.008 5.99 18.01)\" d=\"M4.991 16.511H6.99v3H4.991z\"/><path d=\"M2 11h3v2H2z\"/><path transform=\"rotate(45.017 5.99 5.99)\" d=\"M4.49 4.99h3v2h-3z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});