define("@trovedata/sunstone-ui-commons/helpers/json-pretty-print", ["exports", "@ember/component/helper", "ember"], function (_exports, _helper, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    String: Str
  } = _ember.default;
  class JsonPrettyPrintHelper extends _helper.default {
    compute(params, hash) {
      let [json] = params;
      let prettyJson = JSON.stringify(json, undefined, 4);
      prettyJson = hash && hash.highlight ? this.synstaxHighlight(prettyJson) : prettyJson;
      let lines = prettyJson.split(/\r\n|\r|\n|<br\/>/);
      if (hash && hash.limit && lines.length > hash.limit) {
        lines = lines.slice(0, hash.limit);
        prettyJson = lines.join('<br/>');
        prettyJson = `${prettyJson}<br/><span class="info">...Click to View More</span>`;
      }
      return Str.htmlSafe(prettyJson);
    }
    synstaxHighlight(json) {
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      json = json.split('\\n').join('<br/>');
      json = json.split('\\t').join('&#9;');
      json = json.split('\\n').join('<br/>');
      json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, match => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
      return json;
    }
  }
  _exports.default = JsonPrettyPrintHelper;
});