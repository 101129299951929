define("@trovedata/sunstone-ui-commons/utils/color-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.interpolateColor = interpolateColor;
  function interpolateColor(color1, color2, factor) {
    const color1Arr = color1.match(/\d+/g)?.map(Number);
    const color2Arr = color2.match(/\d+/g)?.map(Number);
    if (arguments.length < 3) {
      factor = 0.5;
    }
    let result = color1Arr?.slice();
    if (result && color1Arr && color2Arr) {
      for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2Arr[i] - color1Arr[i]));
      }
    }
    return result ? `rgb(${result.join(',')})` : undefined;
  }
  ;
});