define("@trovedata/sunstone-ui-commons/components/paper-select-multiple/component", ["exports", "@ember/array", "@ember/object", "@ember-decorators/component", "@trovedata/sunstone-ui-commons/components/paper-select/template", "ember-paper/components/paper-select/component"], function (_exports, _array, _object, _component, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperSelectMultiple = _exports.default = (_dec = (0, _component.layout)(_template.default), _dec(_class = (_class2 = class PaperSelectMultiple extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "closeOnSelect", true);
      _defineProperty(this, "onCloseSelect", void 0);
      _defineProperty(this, "publicAPI", void 0);
    }
    init() {
      // @ts-ignore 
      this.set('triggerComponent', 'paper-select-multiple-trigger');
      // @ts-ignore 
      this.set('beforeOptionsComponent', 'paper-select-multiple-before-options');
      super.init(...arguments);
      if (this.publicAPI) {
        //@ts-ignore 
        this.set('publicAPI.searchField', (0, _object.get)(this, 'searchField'));
      }
    }
    close() {
      if ((0, _object.get)(this, 'onCloseSelect')) {
        (0, _object.get)(this, 'onCloseSelect')();
      }
      const onClose = super.actions.close.bind(this);
      return onClose(...arguments);
    }
    onOpen() {
      // @ts-ignore 
      let options = (0, _array.A)((0, _object.get)(this, 'options'));
      let filterOptions;

      // @ts-ignore
      if ((0, _object.get)(options, 'firstObject.groupName')) {
        filterOptions = options;
      } else {
        // @ts-ignore 
        filterOptions = (0, _array.A)(options.sortBy(`${(0, _object.get)(this, 'searchField')}`));
        // @ts-ignore 
        filterOptions.removeObjects((0, _object.get)(this, 'selected'));
        // @ts-ignore 
        filterOptions.unshiftObjects((0, _object.get)(this, 'selected'));
      }

      // @ts-ignore 
      (0, _object.set)(this, 'options', filterOptions);
      const onOpen = super.actions.onOpen.bind(this);
      return onOpen(...arguments);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onOpen"), _class2.prototype)), _class2)) || _class);
});