define("ember-svg-jar/inlined/interface-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path fill=\"#757575\" d=\"M11.001 3.84h2v3h-2z\"/></g><g><path transform=\"rotate(60 8.75 6.37)\" fill=\"#757575\" d=\"M7.25 5.37h3v2h-3z\"/></g><g><path transform=\"rotate(30.006 6.37 8.75)\" fill=\"#757575\" d=\"M4.871 7.75h3v2h-3z\"/></g><g><path fill=\"#757575\" d=\"M4.001 11h3v2h-3z\"/></g><g><path transform=\"rotate(60.007 6.372 15.248)\" fill=\"#757575\" d=\"M5.372 13.749h2v3h-2z\"/></g><g><path transform=\"rotate(29.991 8.752 17.629)\" fill=\"#757575\" d=\"M7.752 16.128h2v3h-2z\"/></g><g><path fill=\"#757575\" d=\"M11.001 16.997h2v3h-2z\"/></g><g><path transform=\"rotate(60.012 15.251 17.627)\" fill=\"#757575\" d=\"M13.751 16.626h2.999v2h-2.999z\"/></g><g><path transform=\"rotate(30.026 17.63 15.248)\" fill=\"#757575\" d=\"M16.129 14.248h3v1.999h-3z\"/></g><g><path fill=\"#757575\" d=\"M16.999 10.997h3v2h-3z\"/></g><g><path transform=\"rotate(60.007 17.627 8.747)\" fill=\"#757575\" d=\"M16.627 7.247h2v3h-2z\"/></g><g><path transform=\"rotate(29.991 15.249 6.37)\" fill=\"#757575\" d=\"M14.248 4.869h2v3h-2z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});