define("@trovedata/sunstone-ui-commons/transforms/utc-timestamp", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UtcTimestamp extends _emberData.default.Transform {
    deserialize(date) {
      if (date) {
        return (0, _moment.default)(date);
      } else {
        return null;
      }
    }
    serialize(date) {
      if (typeof date === 'string') {
        date = (0, _moment.default)(date);
      }
      if (_moment.default.isMoment(date) || date instanceof Date) {
        return (0, _moment.default)(date).toISOString();
      } else {
        return null;
      }
    }
  }
  _exports.default = UtcTimestamp;
});