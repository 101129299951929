define("@trovedata/sunstone-ui-commons/services/restheart-query", ["exports", "@ember/service", "@trovedata/sunstone-ui-commons/utils/serialize-query-params", "@trovedata/sunstone-ui-commons/utils/restheart-utils"], function (_exports, _service, _serializeQueryParams, _restheartUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RestheartQuery = _exports.default = (_class = class RestheartQuery extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "troveFetch", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
    }
    async getTimeIndex(db, collectionName) {
      const collectionIndexes = await this.request(`${db}/${collectionName}/_indexes`);
      const timeIndex = collectionIndexes.find(index => {
        return index._id === 'time';
      });
      return timeIndex;
    }
    async request(uri, params = {}) {
      return this.call('request', uri, params);
    }
    async put(uri, options = {}) {
      return this.call('put', uri, options.params || {}, options.body || {});
    }
    async call(type, uri, params = {}, body) {
      return this.troveFetch[type](`${uri}?${Object.keys(params).length ? (0, _serializeQueryParams.default)(params) : ''}`, {
        contentType: 'application/json',
        data: body ? body : undefined,
        namespace: this.config.get('trove.restheart.namespace')
      });
    }
    async query(body) {
      const params = {};
      if (body.sort) {
        params.sort = (0, _restheartUtils.parseSort)(body.sort);
      }
      if (body.pagesize) {
        params.pagesize = body.pagesize;
      }
      if (body.count) {
        params.count = body.count;
      }
      if (body.filter && body.filter.length) {
        params.filter = (0, _restheartUtils.parseFilter)(body.filter);
      }
      const response = await this.request(body.uri, params);
      return response;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});