define("ember-svg-jar/inlined/DataCatalog-White", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#fff}</style><g><path class=\"st0\" d=\"M91.4 97.1l-56.6-5.2-1.1 12.1 56.6 5.2 1.1-12.1zm0 0M90.1 112.6l-56.8.1v12.1l56.8-.1v-12.1zm0 0M102.4 67.3l-49-28.9-6.2 10.4 48.9 28.9 6.3-10.4zm0 0M95.2 81.2L40.3 66.5l-3.1 11.7 54.9 14.7 3.1-11.7zm0 0\"/><path class=\"st0\" d=\"M99.8 134.4H25.2V84.8h-9.7v59.1h.1l2.8.1h91v-.1l.1-2.8V84.8h-9.6v49.6zm0 0M118.8 0l-12 2.1 9.7 56 12-2.1-9.7-56zm0 0M72.3 18.7l32.1 46.9 10-6.8-32-47-10.1 6.9zm0 0\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
});