define("@trovedata/sunstone-ui-commons/mixins/ember-data-hash-route", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _exports.default = _mixin.default.create({
    resetController() {
      let modelName = this.resettableModel;
      let model = (0, _object.get)(this, `controller.model.${modelName}`);
      if (!(0, _object.get)(model, 'isDeleted')) {
        model.rollbackAttributes();
      }
    },
    willTransitionConfirm() {
      return true;
    },
    actions: {
      willTransition(transition) {
        let modelName = this.resettableModel;
        let model = (0, _object.get)(this.modelFor(this.routeName), modelName);
        if ((0, _object.get)(model, 'hasDirtyAttributes') && !this.willTransitionConfirm(transition)) {
          transition.abort();
        }
      }
    }
  });
});