define("ember-svg-jar/inlined/location-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><path d=\"M20.555 17.832A1 1 0 0021 17V3a.998.998 0 00-1.554-.832l-5.392 3.595-4.429-3.544a1.073 1.073 0 00-.59-.211 1.064 1.064 0 00-.59.16l-6 4A1.003 1.003 0 002 7v14a1 1 0 001.555.832l5.392-3.594 4.429 3.543c.18.145.401.219.624.219a.855.855 0 00.549-.176l6.006-3.992zM4 7.535l4-2.666v11.596l-4 2.667V7.535zm9 11.385l-3-2.4V5.082l3 2.399V18.92zm6-2.455l-4 2.667V7.535l4-2.666v11.596z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});