define("ember-svg-jar/inlined/health-heart-pulse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M11.995 19.71A37.337 37.337 0 018.18 16H5.559c2.563 3.22 5.607 5.619 5.828 5.79a.994.994 0 001.226 0c.197-.153 2.648-2.084 4.996-4.79h-2.713a41.201 41.201 0 01-2.901 2.71zM4.104 9A5.67 5.67 0 014 8c0-2.43 1.57-4 4-4 1.289 0 2.352.938 2.787 2h2.426c.435-1.062 1.498-2 2.787-2 2.43 0 4 1.57 4 4 0 .653-.133 1.324-.35 2h2.062c.173-.668.288-1.337.288-2 0-3.533-2.467-6-6-6-1.594 0-3.07.837-4 2.08C11.07 2.837 9.594 2 8 2 4.467 2 2 4.467 2 8c0 .332.038.666.084 1h2.02z\"/><path d=\"M19.697 12l-2.489 1.659-4.125-5.156L9 12.586l-3-3L3.586 12H1v2h3.414L6 12.415l3 3 3.917-3.917 3.875 4.843L20.303 14H23v-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});