define("@trovedata/sunstone-ui-commons/adapters/model-instance-parameter", ["exports", "@trovedata/sunstone-ui-commons/adapters/application", "@ember/object/computed"], function (_exports, _application, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModelInstanceParameter extends _application.default.extend({
    namespace: (0, _computed.readOnly)('config.trove.analyticService.namespace')
  }) {}

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = ModelInstanceParameter;
});