define("ember-svg-jar/inlined/subtract-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path fill=\"#757575\" d=\"M7 11.002h10v2H7z\"/></g><g><path d=\"M19 21.002H5a2 2 0 01-2-2v-14a2 2 0 012-2h14a2 2 0 012 2v14c0 1.103-.896 2-2 2zm-14-16v14h13.997l.003-14H5z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});