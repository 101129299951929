define("@trovedata/trove-scenario-planner/utils/service-utils", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.QUERY_SERVICE_NAMESPACE = _exports.QUERY_SERVICE_ID = _exports.COMMAND_SERVICE_NAMESPACE = _exports.COMMAND_SERVICE_ID = void 0;
  const QUERY_SERVICE_ID = _exports.QUERY_SERVICE_ID = 'scenario-planner-query-service';
  const QUERY_SERVICE_NAMESPACE = _exports.QUERY_SERVICE_NAMESPACE = _emberGetConfig.default.trove.troveScenarioPlannerView.namespace;
  const COMMAND_SERVICE_ID = _exports.COMMAND_SERVICE_ID = 'scenario-planner-command-service';
  const COMMAND_SERVICE_NAMESPACE = _exports.COMMAND_SERVICE_NAMESPACE = _emberGetConfig.default.trove.troveScenarioPlannerCommand.namespace;
});