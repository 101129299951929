define("ember-svg-jar/inlined/navigation-filter-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .002h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M4 6.001H2v15a1 1 0 001 1h15v-2H4v-14z\"/><path d=\"M21 2.001H7a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1v-14a1 1 0 00-1-1zm-1 14H8v-12h12v12z\"/><path d=\"M13 14.001h2v-3h3v-2h-3v-3h-2v3h-3v2h3z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});