define("@trovedata/trove-scenario-planner/lib/outage-chart", ["exports", "@ember/application", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators", "moment", "@trovedata/trove-scenario-planner/utils/service-utils"], function (_exports, _application, _service, _tracking, _emberConcurrencyDecorators, _moment, _serviceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let OutageChart = _exports.default = (_class = class OutageChart {
    get isAvailable() {
      return this.outageStatistics.length > 0;
    }
    get categories() {
      // @ts-ignore
      return this.outageStatistics.mapBy('monthYear');
    }
    get chartData() {
      const {
        outageStatistics
      } = this;
      // @ts-ignore
      const severeStats = outageStatistics.mapBy('severeCount');
      // @ts-ignore
      const moderateStats = outageStatistics.mapBy('moderateCount');
      // @ts-ignore
      const typicalStats = outageStatistics.mapBy('typicalCount');
      return [{
        color: '#FF0000',
        data: severeStats,
        name: 'Severe'
      }, {
        color: '#F05D2B',
        data: moderateStats,
        name: 'Moderate'
      }, {
        color: '#8BC34A',
        data: typicalStats,
        name: 'Typical'
      }];
    }
    constructor(owner, params) {
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor3, this);
      _defineProperty(this, "groupingName", void 0);
      _defineProperty(this, "tooltipFormatter", function () {
        let total = 0;
        const itemsMarkup = this.points.reduce((markup, point) => {
          total += point.y;
          markup = `${markup}
        <div class="layout-column layout-margin-right">
          <h2 style="color: ${point.color};" class="md-subhead">
            <b>${point.y}</b>
          </h2>
          <h3 class="md-body-1">${point.series.name}</h3>
        </div>
      `;
          return markup;
        }, '');
        return `
      <div class="trove-chart-tooltip layout-padding-sm md-round md-primary">
        <div class="layout-column layout-padding-left layout-padding-right">
          <h2 class="layout-margin-bottom">
            ${(0, _moment.default)(this.x).format('MMMM YYYY')}
          </h2>
          <div class="layout-row">
            ${itemsMarkup}
            <div class="layout-column layout-margin-right">
              <h2 class="md-subhead"><b>${total}</b></h2>
              <h3 class="md-body-1">Total</h3>
            </div>
          </div>
        </div>
      </div>
    `;
      });
      _defineProperty(this, "xAxisFormatter", function () {
        return (0, _moment.default)(this.value).format('MMM YY');
      });
      _defineProperty(this, "year", void 0);
      _initializerDefineProperty(this, "outageStatistics", _descriptor4, this);
      (0, _application.setOwner)(this, owner);
      this.groupingName = params.groupingName;
      this.year = params.year;
      // @ts-ignore
      this.fetchOutageMetrics.perform();
    }
    generateUrl() {
      const currentYear = (0, _moment.default)().format('YYYY');
      const namespace = currentYear === this.year ? 'findMonthlyByGroupingId' : 'findMonthlyByGroupingIdAndYear';
      let url = `/outageStatistic/search/${namespace}?groupingId=${this.groupingName}`;
      if (currentYear !== this.year) url = `${url}&year=${this.year}`;
      return url;
    }
    *fetchOutageMetrics() {
      const {
        store,
        troveFetch
      } = this;
      const url = this.generateUrl();
      const promise = troveFetch.request(url, {
        namespace: _serviceUtils.QUERY_SERVICE_NAMESPACE,
        serviceId: _serviceUtils.QUERY_SERVICE_ID
      });
      const errorMessage = 'Error getting outage statistics.';
      const results = yield troveFetch.resolve(promise, {
        errorMessage
      });
      const statHash = results?.monthlyStatistics;
      if (statHash && Object.keys(statHash).length > 0) {
        const data = Object.keys(statHash).map(key => {
          return store.normalize('outage-statistic', {
            ...statHash[key],
            monthYear: key
          });
        });
        const stats = store.push({
          data
        });
        // @ts-ignore
        this.outageStatistics = stats.sortBy('monthYear');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "outageStatistics", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchOutageMetrics", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOutageMetrics"), _class.prototype)), _class);
});