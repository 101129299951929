define("@trovedata/sunstone-ui-commons/services/api-gateway", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApiGateway = _exports.default = (_class = class ApiGateway extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
    }
    get dukeServices() {
      const baseRoute = this.config.get('activeProfiles') === 'production,duke' ? 'duke.ds.esource.com' : 'staging.duke.ds.esource.com';
      return [{
        serviceId: 'scenario-planner-ui',
        displayName: 'scenario-planner-ui',
        serviceType: 'FRONTEND_PORTAL',
        baseRoute,
        webSocketRoute: null,
        swaggerRoute: null,
        icon: null
      }, {
        serviceId: 'budget-service',
        displayName: 'budget-service',
        serviceType: 'BACKEND_SERVICE',
        baseRoute: '/trove-budget-service/',
        webSocketRoute: null,
        swaggerRoute: null,
        icon: null
      }, {
        serviceId: 'scenario-planner-command-service',
        displayName: 'scenario-planner-command-service',
        serviceType: 'BACKEND_SERVICE',
        baseRoute: '/scenario-planner-command-service/',
        webSocketRoute: null,
        swaggerRoute: null,
        icon: null
      }, {
        serviceId: 'spring-cloud-config-server',
        displayName: 'spring-cloud-config-server',
        serviceType: 'SYSTEM',
        baseRoute: '/spring-cloud-config-server/',
        webSocketRoute: null,
        swaggerRoute: null,
        icon: null
      }, {
        serviceId: 'scenario-planner-query-service',
        displayName: 'scenario-planner-query-service',
        serviceType: 'BACKEND_SERVICE',
        baseRoute: '/scenario-planner-view/',
        webSocketRoute: null,
        swaggerRoute: null,
        icon: null
      }];
    }
    get allServices() {
      return this.config.get('environment') !== 'test' ? this.dukeServices : [];
    }

    /**
     * Get a list of the frontend services only
     */
    get frontendServices() {
      return this.allServices
      // @ts-ignore
      .filterBy('serviceType', 'FRONTEND_PORTAL')
      // @ts-ignore
      .rejectBy('serviceId', 'user-manager-portal')
      // @ts-ignore
      .rejectBy('serviceId', 'logging-portal');
    }

    /**
     * Lookup map of serviceId to ServiceInfo
     */
    get serviceMap() {
      return new Map(this.allServices.map(service => [service.serviceId, service]));
    }

    /**
     * Get the service info for the given serviceId, or undefined if not found
     */
    getServiceById(id) {
      return this.serviceMap.has(id) ? this.serviceMap.get(id) : undefined;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});