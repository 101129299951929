define("ember-svg-jar/inlined/programming-bug-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M7 8a1 1 0 001 1h8a1 1 0 001-1 4.966 4.966 0 00-.833-2.753l1.54-1.54-1.414-1.414-1.54 1.541a4.959 4.959 0 00-5.505-.001L7.707 2.292 6.293 3.707l1.54 1.54A4.974 4.974 0 007 8zm5-3c1.304 0 2.416.836 2.829 2H9.172A3.004 3.004 0 0112 5zM17 16v-1h4v-2h-4v-1.18l4.196-.84-.392-1.96-4.903.98H8.099l-4.903-.98-.392 1.96L7 11.82V13H3v2h4v1c0 .06.012.119.015.177l-4.211.843.393 1.961 4.43-.886c1.146 1.996 3.495 3.532 3.839 3.75A.99.99 0 0012 22a.986.986 0 00.536-.156c.344-.218 2.691-1.758 3.837-3.75l4.431.886.393-1.961-4.211-.842c.002-.058.014-.117.014-.177zm-2 0c0 1.214-1.666 2.823-2.999 3.79C10.773 18.894 9 17.25 9 16v-4h6v4z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});