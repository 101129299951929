define("ember-svg-jar/inlined/bookmark-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M20 3h-7V2a1 1 0 00-1-1H6a1 1 0 00-1 1v1H4a2 2 0 00-2 2v15a2 2 0 002 2h16a2 2 0 002-2V5a2 2 0 00-2-2zM7 3h4v8.586l-1.293-1.293a.997.997 0 00-1.414 0L7 11.586V3zm12.997 17H4V5h1v9a1 1 0 001.707.707L9 12.414l2.293 2.293A1 1 0 0013 14V5h7l-.003 15z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M14 7h4v2h-4z\"/></g><g><path fill=\"#757575\" d=\"M14 10h4v2h-4z\"/></g><g><path fill=\"#757575\" d=\"M14 13h4v2h-4z\"/></g><g><path fill=\"#757575\" d=\"M6 16h12v2H6z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});