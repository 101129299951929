define("@trovedata/sunstone-ui-commons/services/trove-theme", ["exports", "ember-paper/services/paper-theme", "@ember/debug", "tinycolor2", "@ember/array", "ember-paper/utils/palettes", "@ember/object"], function (_exports, _paperTheme, _debug, _tinycolor, _array, _palettes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TroveThemeService = _exports.default = (_dec = (0, _object.computed)(), _dec2 = (0, _object.computed)('palettes.@each.name'), _dec3 = (0, _object.computed)('palettes.@each.name'), _dec4 = (0, _object.computed)('palettes.@each.name'), _dec5 = (0, _object.computed)('palettes.@each.name'), _dec6 = (0, _object.computed)('palettes.@each.name'), (_class = class TroveThemeService extends _paperTheme.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "foregroundLight", {
        1: 'rgba(0, 0, 0, 0.87)',
        2: 'rgba(0, 0, 0, 0.54)',
        3: 'rgba(0, 0, 0, 0.38)',
        4: 'rgba(0, 0, 0, 0.12)'
      });
      _defineProperty(this, "foregroundDark", {
        1: 'white',
        2: 'rgba(255, 255, 255, 0.7)',
        3: 'rgba(255, 255, 255, 0.5)',
        4: 'rgba(255, 255, 255, 0.12)'
      });
      _defineProperty(this, "primaryLight", {
        name: 'grey',
        palette: {
          '50': '#ffffff',
          '100': '#f7f7f7',
          '200': '#ebeced',
          '300': '#dedfe0',
          '400': '#c5c8cb',
          '500': '#95999c',
          '600': '#757575',
          '700': '#616161',
          '800': '#424242',
          '900': '#212121',
          'A100': '#f0f6fb',
          'A200': '#d8e8fc',
          'A400': '#c4d2e0',
          'A700': '#6d767d',
          'background': '#F5F7FA',
          'contrastDefaultColor': 'rgba(0, 0, 0, 0.87)',
          'contrastDarkColors': (0, _array.A)(['50', '100', '200', '300', '400', '500', 'A100', 'A200', 'A400', 'background']),
          'contrastStrongLightColors': (0, _array.A)(['600', '700', '800', '900', 'A700'])
        }
      });
      _defineProperty(this, "primaryDark", {
        name: 'grey',
        palette: {
          '50': '#d3d3d4',
          '100': '#9a9d9f',
          '200': '#7b7f83',
          '300': '#64696d',
          '400': '#404547',
          '500': '#2c3134',
          '600': '#25292c',
          '700': '#1e2124',
          '800': '#12181e',
          '900': '#04070a',
          'A100': '#a1a8ac',
          'A200': '#8e969e',
          'A400': '#525d61',
          'A700': '#323940',
          'background': '#343A3E',
          'contrastDefaultColor': 'rgba(255, 255, 255, 0.87)',
          'contrastDarkColors': (0, _array.A)(['50', '100', 'A100', 'A200']),
          'contrastStrongLightColors': (0, _array.A)(['200', '300', '400', '500', '600', '700', '800', '900', 'A400', 'A700', 'background'])
        }
      });
    }
    get palettes() {
      return Object.keys(_palettes.default).map(key => {
        let palette = _palettes.default[key];
        return {
          name: key,
          palette
        };
      });
    }
    get accent() {
      return this.palettes.find(p => p.name === 'light-green');
    }
    get warn() {
      return this.palettes.find(p => p.name === 'red');
    }
    get alternative() {
      return this.palettes.find(p => p.name === 'blue-grey');
    }
    get info() {
      return this.palettes.find(p => p.name === 'blue');
    }
    get alert() {
      return this.palettes.find(p => p.name === 'orange');
    }
    generateCssVariables(theme) {
      return ['primary', 'accent', 'warn', 'background', 'foreground', 'alternative', 'info', 'alert'].reduce((vars, intention) => {
        let palette = theme[intention];
        (false && !(!!palette) && (0, _debug.assert)(`A theme must have a \`${intention}\` key containing a palette.`, !!palette));
        let hues = Object.keys(palette).filter(hue => {
          return hue.indexOf('contrast') === -1;
        });
        return vars += hues.map(hue => {
          let color = (0, _tinycolor.default)(palette[hue]).toRgb();
          let contrast = (0, _tinycolor.default)(this.getContrastColor(palette, hue)).toRgb();
          return intention === 'foreground' ? `
          --${intention}-${hue}: rgba(${color.r}, ${color.g}, ${color.b}, ${color.a});
        ` : `
          --${intention}-${hue}: ${color.r}, ${color.g}, ${color.b};
          --${intention}-${hue}-contrast: ${contrast.r}, ${contrast.g}, ${contrast.b};
        `;
        }).join('');
      }, '');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "palettes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "palettes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "accent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "accent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "warn", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "warn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alternative", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "alternative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "info", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "info"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alert", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "alert"), _class.prototype)), _class));
});