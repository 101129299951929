define("@trovedata/sunstone-ui-commons/utils/element-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollToBottom = scrollToBottom;
  function scrollToBottom(selector, smooth = false) {
    const element = document.querySelector(selector);
    if (element) {
      let style = element.getAttribute('style');
      style = style ? style : '';
      if (smooth) {
        element.setAttribute('style', `scroll-behavior: smooth;${style}`);
      }
      element.scrollTop = element.scrollHeight;
      if (smooth) {
        element.setAttribute('style', style);
      }
    }
  }
});