define("@trovedata/sunstone-ui-commons/serializers/model-instance-parameter", ["exports", "@trovedata/sunstone-ui-commons/serializers/dynamic-transform"], function (_exports, _dynamicTransform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dynamicTransform.default.extend({
    attrs: {
      modelParameter: {
        embedded: 'always'
      }
    }
  });
});