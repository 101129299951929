define("ember-svg-jar/inlined/user-magnify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M21.657 20.293l-5.363-5.363A7.93 7.93 0 0018.001 10a7.945 7.945 0 00-2.344-5.656C14.146 2.832 12.138 2 10.001 2s-4.146.832-5.656 2.344C2.833 5.854 2.001 7.863 2.001 10s.833 4.146 2.344 5.656A7.94 7.94 0 0010.001 18c1.787 0 3.48-.59 4.872-1.664l5.371 5.371 1.413-1.414zM5.759 14.242A5.956 5.956 0 014.001 10c0-1.604.624-3.109 1.758-4.242C6.892 4.624 8.398 4 10.001 4s3.109.624 4.242 1.759A5.952 5.952 0 0116.001 10a5.956 5.956 0 01-1.758 4.242C13.11 15.376 11.604 16 10.001 16s-3.109-.624-4.242-1.758z\"/><g><path d=\"M6.95 14c0-1.841 1.159-3 3-3s3 1.159 3 3h-6z\"/><circle cx=\"9.95\" cy=\"8\" r=\"2\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});