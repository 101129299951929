define("@trovedata/trove-scenario-planner/scenario-planner/index/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndexRoute extends _route.default {
    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);

      // @ts-ignore
      controller.getAssetCentroidFilterCategories.perform();
    }
  }
  _exports.default = IndexRoute;
});