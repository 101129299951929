define("ember-svg-jar/inlined/cog-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M19.726 14H17.65c-.826 2.328-3.044 4-5.65 4-3.309 0-6-2.69-6-6 0-2.606 1.674-4.823 4-5.65V4.274a7.977 7.977 0 00-2.493 1.111L5.989 3.868 3.868 5.989l1.518 1.518A7.913 7.913 0 004.264 10H2v4h2.264a7.903 7.903 0 001.122 2.493l-1.518 1.518 2.121 2.122 1.518-1.518A7.99 7.99 0 0010 19.737V22h4v-2.263a7.968 7.968 0 002.493-1.122l1.518 1.518 2.121-2.122-1.518-1.518A7.98 7.98 0 0019.726 14z\"/><path d=\"M22 7.909V6.09h-1.492a3.585 3.585 0 00-.358-.876l.941-.942-1.363-1.363-.943.941a3.537 3.537 0 00-.877-.358V2h-1.817v1.492a3.546 3.546 0 00-.876.358l-.942-.941-1.364 1.363.942.942a3.627 3.627 0 00-.359.876H12v1.818h1.491c.082.312.204.604.359.877l-.942.942 1.364 1.363.942-.94c.272.155.564.277.876.358V12h1.817v-1.491c.313-.082.604-.203.877-.358l.942.94 1.363-1.363-.941-.942c.155-.273.277-.565.358-.877H22zM17 9a2 2 0 110-4 2 2 0 010 4z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});