define("@trovedata/sunstone-ui-commons/adapters/data-set-data-field", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlForUpdateRecord(id, modelName) {
      let toReplace = this.pathForType(modelName);
      let url = this._buildURL(modelName, id);
      return url.replace(toReplace, 'datasetMap');
    }
  });
});