define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#trove-button iconButton=true click=(disable-bubbling toggleActions)}}
    {{paper-icon (if showActions 'close' 'settings')}}
  {{/trove-button}}
  
  */
  {
    "id": "Tl037/Rc",
    "block": "[[[6,[39,0],null,[[\"iconButton\",\"click\"],[true,[28,[37,1],[[33,2]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],[[52,[33,5],\"close\",\"settings\"]],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"trove-button\",\"disable-bubbling\",\"toggleActions\",\"paper-icon\",\"if\",\"showActions\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon/template.hbs",
    "isStrictMode": false
  });
});