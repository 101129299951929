define("@trovedata/trove-scenario-planner/adapters/plan", ["exports", "@trovedata/sunstone-ui-commons/adapters/application-json-api"], function (_exports, _applicationJsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Plan extends _applicationJsonApi.default {
    getFallbackNamespace() {
      return this.config.get('trove.troveScenarioPlanner.namespace');
    }
    getTargetBackendServiceId() {
      return 'scenario-planner-command-service';
    }
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data
      });
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = Plan;
});