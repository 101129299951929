define("ember-search-helper/helpers/search", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils", "@ember/array"], function (_exports, _helper, _object, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.search = search;
  let testValue = function (value, query, caseSensitive, exactMatch) {
    value = String(value);
    if (!caseSensitive) {
      value = value.toLowerCase();
    }
    return exactMatch ? value === query : value.indexOf(query) !== -1;
  };
  function search([query, collection], {
    properties = [],
    caseSensitive = false,
    exactMatch = false
  }) {
    if ((0, _utils.isEmpty)(query) || (0, _utils.isEmpty)(collection)) {
      return collection;
    }
    if (!caseSensitive) {
      query = query.toLowerCase();
    }
    let collectionLength = (0, _object.get)(collection, 'length');
    let propertiesLength = (0, _object.get)(properties, 'length');
    let foundItems = (0, _array.A)();
    for (let i = 0; i < collectionLength; i++) {
      let item = collection.objectAt(i);
      if (propertiesLength) {
        for (let x = 0; x < propertiesLength; x++) {
          let value = (0, _object.get)(item, properties.objectAt(x));
          if (testValue(value, query, caseSensitive, exactMatch)) {
            foundItems.pushObject(item);
            break;
          }
        }
      } else {
        if (testValue(item, query, caseSensitive, exactMatch)) {
          foundItems.pushObject(item);
        }
      }
    }
    return foundItems;
  }
  var _default = _exports.default = (0, _helper.helper)(search);
});