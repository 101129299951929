define("@trovedata/sunstone-ui-commons/services/current-user", ["exports", "@ember/object/computed", "@ember/string", "@ember/object/proxy", "@ember/utils", "@ember/service", "@ember/object", "@ember/array"], function (_exports, _computed, _string, _proxy, _utils, _service, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const groupKey = 'groupName';
  let CurrentUserService = _exports.default = (_dec = (0, _computed.alias)('user.id'), _dec2 = (0, _computed.alias)('user.username'), _dec3 = (0, _computed.alias)('user.metadata'), _dec4 = (0, _object.computed)('roles.[]'), _dec5 = (0, _object.computed)('permissions.[]'), _dec6 = (0, _object.computed)('settings.default.shared-profile'), _dec7 = (0, _computed.alias)('settings.default.default-profile'), _dec8 = (0, _object.computed)('userProfileId', 'sharedProfileIds'), (_class = class CurrentUserService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor2, this);
      _initializerDefineProperty(this, "config", _descriptor3, this);
      _defineProperty(this, "__settingCache__", {});
      _defineProperty(this, "__content__", {});
      _initializerDefineProperty(this, "id", _descriptor4, this);
      _initializerDefineProperty(this, "username", _descriptor5, this);
      _initializerDefineProperty(this, "metadata", _descriptor6, this);
      _defineProperty(this, "lastLogin", null);
      _defineProperty(this, "theme", 'dark');
      _defineProperty(this, "roles", void 0);
      _defineProperty(this, "settings", void 0);
      _defineProperty(this, "envDisplayTimezone", void 0);
      _defineProperty(this, "user", void 0);
      _initializerDefineProperty(this, "userProfileId", _descriptor7, this);
    }
    init() {
      super.init();
      this.settings = _proxy.default.create({
        content: this.__settingCache__
      });
    }
    logout() {
      // this.session.invalidate();
    }
    unknownProperty(key) {
      return this.__content__[key];
    }
    get isAdmin() {
      return this.roles?.length > 0 ? this.roles.some(({
        authority
      }) => authority === 'ROLE_ADMINS') : false;
    }
    get isReadOnly() {
      return this.roles?.length > 0 ? this.roles.some(({
        authority
      }) => authority === 'ROLE_READ_ONLY') : false;
    }
    hasAuthority(authority) {
      if (!this.roles) return false;
      let found = false;
      this.roles.forEach(role => {
        if (role.authority === authority) {
          found = true;
          return;
        }
      });
      return found;
    }
    async loadUser() {
      const {
        config,
        store,
        troveFetch
      } = this;
      const namespace = config.get('trove.userManagerBaseUrl') ? null : config.get('trove.troveUserManager.namespace');
      const options = {
        namespace
      };
      if (config.get('trove.userManagerBaseUrl')) {
        options.host = config.get('trove.userManagerBaseUrl');
      }
      const currentUser = await troveFetch.request('/currentUser', options);
      const user = await store.findRecord('user', currentUser.id);
      this.set('user', user);
      this.setProperties({
        lastLogin: currentUser.lastLogin,
        roles: currentUser.roles,
        envDisplayTimezone: currentUser.envDisplayTimezone
      });
      await this.loadSettings();
    }
    get permissions() {
      return (0, _array.A)((0, _object.get)(this, 'roles')).reduce((permissions, role) => {
        (0, _array.A)(role.permissions).forEach(permission => {
          // @ts-ignore
          if (!permissions.isAny('name', (0, _object.get)(permission, 'name'))) {
            permissions.pushObject(permission);
          }
        });
        return permissions;
      }, (0, _array.A)());
    }
    get canWrite() {
      // @ts-ignore
      return (0, _object.get)(this, 'permissions').isAny('name', 'WRITE_PERMISSION');
    }
    get sharedProfileIds() {
      return (0, _object.get)(this.settings, 'default.shared-profile') ? (0, _object.get)(this.settings, 'default.shared-profile').split(',') : (0, _array.A)();
    }
    get queryableProfiles() {
      let id = (0, _object.get)(this, 'userProfileId');
      let sharedProfileIds = (0, _object.get)(this, 'sharedProfileIds');
      let queryableProfiles = (0, _object.get)(sharedProfileIds, 'length') ? `${id},${sharedProfileIds.join(',')}` : id;
      return queryableProfiles;
    }
    async setSetting(groupName, settingName, value) {
      const index = this.user.settingIndexByName[settingName];
      if (index !== undefined && this.user.metadata && this.user.metadata.settings) {
        const setting = this.user.metadata.settings[index];
        (0, _object.set)(setting, 'value', value);
      }
      try {
        await this.user.save();
        return this.__setSetting(value, groupName, settingName);
      } catch (error) {}
    }
    async loadSettings() {
      if (this.metadata && this.metadata.settings && (0, _utils.typeOf)(this.metadata.settings) === 'array') {
        this.metadata.settings.forEach(setting => {
          const groupName = setting[groupKey];
          this.__setSetting(setting.value, groupName ? groupName : 'default', setting.name);
        });
      }
    }
    __setSetting(value, groupName, settingName) {
      groupName = (0, _string.dasherize)(groupName);
      settingName = (0, _string.dasherize)(settingName);
      let groupSettings = (0, _object.get)(this.settings, `${groupName}`);
      if ((0, _utils.isEmpty)(groupSettings)) {
        (0, _object.set)(this.settings, `${groupName}`, {});
      }
      return (0, _object.set)(this.settings, `${groupName}.${settingName}`, value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "username", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "logout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "permissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canWrite", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canWrite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sharedProfileIds", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sharedProfileIds"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userProfileId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryableProfiles", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "queryableProfiles"), _class.prototype)), _class));
});