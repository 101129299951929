define("ember-svg-jar/inlined/power-shortage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Filled_Icons\"><path fill=\"#757575\" d=\"M21.707 12.295l-9-9a.999.999 0 00-1.414 0l-9 9A1 1 0 003 14.002h2v8h5v-5h4v5h5v-8h2a1 1 0 00.707-1.707zM11 14.002v-4H8l4-5v4h3l-4 5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});