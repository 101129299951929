define("@trovedata/sunstone-ui-commons/adapters/config-property", ["exports", "@trovedata/sunstone-ui-commons/adapters/restheart-adapter"], function (_exports, _restheartAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConfigProperty extends _restheartAdapter.default {}

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = ConfigProperty;
});