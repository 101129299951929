define("@trovedata/sunstone-ui-commons/components/toggle-group-item/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/toggle-group-item/template", "ember-composability-tools", "@ember/object"], function (_exports, _component, _template, _emberComposabilityTools, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberComposabilityTools.ChildMixin, {
    layout: _template.default,
    classNames: 'toggle',
    classNameBindings: ['active:md-active', 'disabled:disabled'],
    attributeBindings: ['href', 'target'],
    init() {
      this._super(...arguments);
      if (this.href) {
        this.setProperties({
          hoverable: true,
          tagName: 'a'
        });
      }
    },
    click() {
      if (this.onClick && !this.disabled) {
        this.onClick(...arguments);
      }
      if (this.onSelect && !this.disabled) {
        this.onSelect(this);
      }
    },
    active: (0, _object.computed)('selected', 'value', function () {
      return `${this.selected}` === `${this.value}`;
    })
  });
});