define("@trovedata/trove-scenario-planner/scenario-planner/controller", ["exports", "ember-concurrency-decorators", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@glimmer/tracking", "@trovedata/trove-scenario-planner/utils/service-utils"], function (_exports, _emberConcurrencyDecorators, _controller, _object, _computed, _service, _tracking, _serviceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ScenarioPlannerController = _exports.default = (_dec = (0, _computed.equal)('router.currentRouteName', 'scenario-planner.index'), _dec2 = (0, _computed.gt)('groupingTypes.length', 1), _dec3 = (0, _computed.reads)('featureFlag.showGroupingDashboardMap'), _dec4 = (0, _computed.reads)('model'), _dec5 = (0, _emberConcurrencyDecorators.lastValue)('getAllLineStats'), _dec6 = (0, _emberConcurrencyDecorators.lastValue)('getAllServiceAreas'), (_class = class ScenarioPlannerController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "customization", _descriptor2, this);
      _initializerDefineProperty(this, "featureFlag", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor6, this);
      _defineProperty(this, "queryParams", ['year']);
      _defineProperty(this, "showLinks", true);
      _initializerDefineProperty(this, "allPlanYearGroupingVersions", _descriptor7, this);
      _initializerDefineProperty(this, "appName", _descriptor8, this);
      _initializerDefineProperty(this, "breadCrumbs", _descriptor9, this);
      _initializerDefineProperty(this, "selectedGroupingTypeIndex", _descriptor10, this);
      _initializerDefineProperty(this, "showMap", _descriptor11, this);
      _initializerDefineProperty(this, "showPlanYearVersionDialog", _descriptor12, this);
      _initializerDefineProperty(this, "year", _descriptor13, this);
      _initializerDefineProperty(this, "isSplashPage", _descriptor14, this);
      _initializerDefineProperty(this, "showToggle", _descriptor15, this);
      _initializerDefineProperty(this, "mapEnabled", _descriptor16, this);
      _initializerDefineProperty(this, "groupingTypes", _descriptor17, this);
      _initializerDefineProperty(this, "allLineStats", _descriptor18, this);
      _initializerDefineProperty(this, "allServiceAreas", _descriptor19, this);
    }
    get hasPlanYears() {
      return this.planYears && this.planYears?.length > 0;
    }
    get selectedGroupingType() {
      return this.groupingTypes[this.selectedGroupingTypeIndex];
    }
    get planYears() {
      const allYears = this.allPlanYearGroupingVersions?.map(p => '' + p.planYear).sort();
      return [...new Set(allYears)];
    }
    get latestYear() {
      return this.planYears ? parseInt(this.planYears[this.planYears.length - 1]) : undefined;
    }
    init() {
      super.init();
      this.selectDefaultView();
    }
    selectDefaultView() {
      this.showMap = this.featureFlag.defaultGroupingMap;
    }
    *getAvailablePlanYears() {
      const {
        store,
        troveFetch
      } = this;
      const promise = store.findAll('plan-year-grouping-geo-data-version');
      const errorMessage = 'Failed to retrieve available plan years';
      const planGroupingYears = (yield troveFetch.resolvePromiseWithToast(promise, {
        errorMessage
      }))?.toArray();
      if (planGroupingYears?.length > 0 && !planGroupingYears.map(p => '' + p.planYear).includes(this.year)) {
        this.year = '' + planGroupingYears[planGroupingYears.length - 1].planYear;
      }
      this.allPlanYearGroupingVersions = planGroupingYears;
    }
    *getAllLineStats() {
      // @ts-ignore
      return (yield this.troveFetch.resolvePromiseWithToast(this.store.findAll('region-line-statistic'), {
        errorMessage: 'Failed to retrieve statistics'
      }))?.toArray();
    }
    *getAllServiceAreas() {
      const response = yield this.troveFetch.request('/region/serviceAreas', {
        namespace: _serviceUtils.QUERY_SERVICE_NAMESPACE,
        serviceId: _serviceUtils.QUERY_SERVICE_ID
      });
      return response.reject(({
        serviceArea
      }) => {
        return serviceArea === null || serviceArea === 'NULL';
      });
    }
    updatePlanYears() {
      this.showPlanYearVersionDialog = false;
      // @ts-ignore
      this.getAvailablePlanYears.perform();
    }
    onEnterToggle(index, {
      target
    }) {
      if (target.id !== `toggle-group-type-${index}`) return;
      this.selectedGroupingTypeIndex = index;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "featureFlag", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allPlanYearGroupingVersions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "breadCrumbs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedGroupingTypeIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showPlanYearVersionDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "year", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isSplashPage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showToggle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "mapEnabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "groupingTypes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "allLineStats", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "allServiceAreas", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getAvailablePlanYears", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAvailablePlanYears"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAllLineStats", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAllLineStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAllServiceAreas", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAllServiceAreas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePlanYears", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePlanYears"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEnterToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEnterToggle"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
});