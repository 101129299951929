define("ember-svg-jar/inlined/programming-flow-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M20 2v2h-4V2H2v6h14V6h4v5h-4V9H2v6h14v-2h4v5h-4v-2H2v6h14v-2h5a1 1 0 001-1V2h-2zm-6 4H4V4h10v2zm0 7H4v-2h10v2zm0 7H4v-2h10v2z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});