define("@trovedata/trove-scenario-planner/components/centroid-info-window/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PaperCard class="layout-padding layout-margin-none flex">
    <div class="layout-column title-container">
      <TroveLabel
        @bold={{true}}
        @label={{this.stat.assetName}}
        @size={{14}}
      />
      <TroveLabel @label="Circuit" @size={{12}}/>
    </div>
  
    <div class="layout-row layout-wrap prop-container">
      {{#each this.sortedMetadata as |data|}}
        <div class="layout-column flex-50">
          <TroveLabel
            @bold={{true}}
            @label={{if data.value data.value "--"}}
            @size={{12}}
            @ellipse={{true}}
          />
          <TroveLabel @label={{data.label}} @size={{10}} @ellipse={{true}}/>
        </div>
      {{/each}}
    </div>
  </PaperCard>
  
  */
  {
    "id": "MLclTjT2",
    "block": "[[[8,[39,0],[[24,0,\"layout-padding layout-margin-none flex\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"layout-column title-container\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"stat\",\"assetName\"]],14]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@label\",\"@size\"],[\"Circuit\",12]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"layout-row layout-wrap prop-container\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedMetadata\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"layout-column flex-50\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@bold\",\"@label\",\"@size\",\"@ellipse\"],[true,[52,[30,1,[\"value\"]],[30,1,[\"value\"]],\"--\"],12,true]],null],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@size\",\"@ellipse\"],[[30,1,[\"label\"]],10,true]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"data\"],false,[\"paper-card\",\"trove-label\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/centroid-info-window/template.hbs",
    "isStrictMode": false
  });
});