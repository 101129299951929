define("@trovedata/sunstone-ui-commons/adapters/application", ["exports", "@ember/service", "@ember/object/computed", "@ember/string", "@ember-data/adapter/rest", "@ember-data/adapter/error", "@ember/object"], function (_exports, _service, _computed, _string, _rest, _error, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    config: (0, _service.inject)(),
    keycloakSession: (0, _service.inject)(),
    host: (0, _computed.readOnly)('config.trove.api.host'),
    namespace: (0, _computed.readOnly)('config.trove.api.namespace'),
    handleResponse(status, headers, payload) {
      this._super(...arguments);
      if (this.isSuccess(...arguments)) {
        return payload;
      } else {
        return new _error.default(payload);
      }
    },
    normalizeErrorResponse(status, headers, payload) {
      if (payload && typeof payload === 'object') {
        return [{
          detail: 'Uncaught Exception',
          source: {
            pointer: '/data'
          }
        }];
      }
      return this._super(status, headers, payload);
    },
    isInvalid(status) {
      return status >= 400 && status !== 401;
    },
    ajaxOptions() {
      let options = this._super(...arguments);
      options.xhrFields = {
        withCredentials: true
      };
      if (options.data && options.type !== 'GET') {
        options.contentType = 'application/hal+json; charset=utf/8;';
      }
      return options;
    },
    headers: (0, _object.computed)('keycloakSession.keycloak', function () {
      const headers = {
        'Accept': 'application/hal+json,application/json'
      };
      if (this.keycloakSession.token) {
        headers['Authorization'] = `Bearer ${this.keycloakSession.token}`;
      }
      return headers;
    }),
    shouldReloadAll() {
      return true;
    },
    pathForType(type) {
      return (0, _string.camelize)(type);
    },
    query(store, type, query) {
      let url = this.buildURL(type.modelName, null, null, 'query', query);
      if (query.endpoint) {
        query = query.params;
      }
      if (this.sortQueryParams) {
        query = this.sortQueryParams(query);
      }
      return this.ajax(url, 'GET', {
        data: query
      });
    },
    urlForQueryRecord() {
      return this.urlForQuery(...arguments);
    },
    urlForQuery(query, modelName) {
      const baseUrl = this._buildURL(modelName);
      if (query.endpoint) {
        let endpoint = `${baseUrl}/search/${query.endpoint}`;
        delete query.endpoint;
        Object.keys(query.params ? query.params : {}).forEach(key => {
          query[key] = query.params[key];
        });
        delete query.params;
        return endpoint;
      } else {
        let queryKeys = Object.keys(query);
        let searchableQueryKeys = queryKeys.filter(key => ['page', 'size', 'projection'].indexOf(key) === -1);
        return searchableQueryKeys.length === 1 ? `${baseUrl}/search/findBy${(0, _string.capitalize)((0, _string.camelize)(searchableQueryKeys[0]))}` : baseUrl;
      }
    }
  });
});