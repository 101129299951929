define("@trovedata/sunstone-ui-commons/components/async-form/component", ["exports", "@ember/component", "@ember/object", "@trovedata/sunstone-ui-commons/components/async-form/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let component = _component.default.extend({
    layout: _template.default,
    tagName: 'form',
    attributeBindings: ['enctype'],
    didReceiveAttrs() {
      this._super(...arguments);
      let _action = this.paramAction || this.action;
      (0, _object.set)(this, '_action', _action);
    },
    submit(ev) {
      ev.preventDefault();
      (0, _object.set)(this, 'promise', this._action());
    }
  });
  component.reopenClass({
    positionalParams: ['paramAction']
  });
  var _default = _exports.default = component;
});