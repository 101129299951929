define("@trovedata/sunstone-ui-commons/adapters/datasource-mapping", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlForDeleteRecord(id) {
      const baseUrl = this._buildURL();
      return `${baseUrl}/sourceMapping/${id}`;
    }
  });
});