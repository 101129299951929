define("ember-svg-jar/inlined/organization-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M22 5c0-1.93-1.57-3.5-3.5-3.5S15 3.07 15 5c0 1.251.666 2.343 1.656 2.962l-1.24 4.547a3.454 3.454 0 00-1.568.425l-2.281-2.281A3.466 3.466 0 0012 9c0-1.93-1.57-3.5-3.5-3.5S5 7.07 5 9c0 1.107.527 2.084 1.332 2.727l-1.521 3.805c-.104-.011-.205-.032-.311-.032C2.57 15.5 1 17.07 1 19s1.57 3.5 3.5 3.5S8 20.93 8 19a3.485 3.485 0 00-1.332-2.727l1.521-3.805c.104.011.205.032.311.032.601 0 1.158-.166 1.652-.434l2.281 2.281A3.466 3.466 0 0012 16c0 1.93 1.57 3.5 3.5 3.5S19 17.93 19 16a3.493 3.493 0 00-1.656-2.962l1.24-4.547A3.5 3.5 0 0022 5zM4.5 20.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5S6 18.173 6 19s-.673 1.5-1.5 1.5zm14-17c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5S17 5.827 17 5s.673-1.5 1.5-1.5zm-10 4c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5S7 9.827 7 9s.673-1.5 1.5-1.5zm7 10c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});