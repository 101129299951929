define("ember-svg-jar/inlined/st-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_262_6)\" fill=\"#F05D2A\"><path d=\"M65.701 61.053h-59.4a1.65 1.65 0 01-1.439-2.462l29.7-52.8a1.655 1.655 0 011.44-.841c.596 0 1.144.32 1.438.841l29.7 52.8c.287.512.28 1.136-.013 1.644a1.645 1.645 0 01-1.426.818zm-56.578-3.3H62.88L36 9.97 9.123 57.753z\"/><path d=\"M36 44.55c-.91 0-1.65-.74-1.65-1.65V26.4a1.65 1.65 0 013.3 0v16.5c0 .91-.74 1.65-1.65 1.65zM36 54.45c-.91 0-1.65-.74-1.65-1.65v-3.3a1.65 1.65 0 013.3 0v3.3c0 .91-.74 1.65-1.65 1.65z\"/></g><defs><filter id=\"filter0_d_262_6\" x=\"-1\" y=\"0\" width=\"74\" height=\"74\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"4\"/><feGaussianBlur stdDeviation=\"2\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_262_6\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_262_6\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "72",
      "height": "70",
      "viewBox": "0 0 72 70",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});