define("ember-svg-jar/inlined/fill-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Line_Icons\"><path d=\"M17 0H1a1 1 0 00-1 1v4a1 1 0 001 1h16a1 1 0 001-1V1a1 1 0 00-1-1zm-1 4H6V2h10v2z\" transform=\"translate(3 9)\" fill=\"#757575\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});