define("@trovedata/trove-scenario-planner/components/asset-details-stat-label/component", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TroveLabel 
      @class={{if @assetProperty.customization.class @assetProperty.customization.class ""}}
      @label={{@assetProperty.displayName}}
  />
  {{#if (and (not-eq @fieldValue undefined) (not-eq @fieldValue null))}}
      {{#if (eq @assetProperty.dataType "numeric")}}
          <TroveLabel
              @bold={{true}}
              @condensed={{true}}
              @label={{concat (number @fieldValue precision=@assetProperty.customization.precision shorten=@assetProperty.customization.shorten) @units}}
              @primary={{true}}
              @size={{16}}
          />
      {{else if (eq @assetProperty.dataType "date")}}
          <TroveLabel
              @bold={{true}}
              @condensed={{true}}
              @label={{moment-format @fieldValue @assetProperty.customization.format}}
              @primary={{true}}
              @size={{16}}
          />
      {{else}}
          <TroveLabel
              @bold={{true}}
              @condensed={{true}}
              @label={{concat @fieldValue @units}}
              @primary={{true}}
              @size={{16}}
          />
      {{/if}}
  {{else}}
      <TroveLabel
          @bold={{true}}
          @condensed={{true}}
          @label="--"
          @primary={{true}}
          @size={{16}}
      />
  {{/if}}
  
  */
  {
    "id": "RPDdw0t8",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@label\"],[[52,[30,1,[\"customization\",\"class\"]],[30,1,[\"customization\",\"class\"]],\"\"],[30,1,[\"displayName\"]]]],null],[1,\"\\n\"],[41,[28,[37,2],[[28,[37,3],[[30,2],[27]],null],[28,[37,3],[[30,2],null],null]],null],[[[41,[28,[37,4],[[30,1,[\"dataType\"]],\"numeric\"],null],[[[1,\"        \"],[8,[39,0],null,[[\"@bold\",\"@condensed\",\"@label\",\"@primary\",\"@size\"],[true,true,[28,[37,5],[[28,[37,6],[[30,2]],[[\"precision\",\"shorten\"],[[30,1,[\"customization\",\"precision\"]],[30,1,[\"customization\",\"shorten\"]]]]],[30,3]],null],true,16]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,1,[\"dataType\"]],\"date\"],null],[[[1,\"        \"],[8,[39,0],null,[[\"@bold\",\"@condensed\",\"@label\",\"@primary\",\"@size\"],[true,true,[28,[37,7],[[30,2],[30,1,[\"customization\",\"format\"]]],null],true,16]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,0],null,[[\"@bold\",\"@condensed\",\"@label\",\"@primary\",\"@size\"],[true,true,[28,[37,5],[[30,2],[30,3]],null],true,16]],null],[1,\"\\n    \"]],[]]]],[]]]],[]],[[[1,\"    \"],[8,[39,0],null,[[\"@bold\",\"@condensed\",\"@label\",\"@primary\",\"@size\"],[true,true,\"--\",true,16]],null],[1,\"\\n\"]],[]]]],[\"@assetProperty\",\"@fieldValue\",\"@units\"],false,[\"trove-label\",\"if\",\"and\",\"not-eq\",\"eq\",\"concat\",\"number\",\"moment-format\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/asset-details-stat-label/component.hbs",
    "isStrictMode": false
  });
  class AssetDetailsStatLabel extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
    }
  }
  _exports.default = AssetDetailsStatLabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AssetDetailsStatLabel);
});