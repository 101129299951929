define("ember-svg-jar/inlined/file-statistic-refresh-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M8.001 15.006h-1v-7h-2v7H4v2h6v-5H8.001z\"/><path d=\"M2 2.002h10v4h4L16.001 10H18V4.588L13.414.003H2c-1.103 0-2 .897-2 2v18.001c0 1.103.897 2 2 2h8v-2H2V2.002z\"/><path d=\"M18 22.002c-1.077 0-2.063-.441-2.811-1.19L17 19.002h-5v5l1.762-1.761A5.934 5.934 0 0018 24.002c3.309 0 6-2.691 6-6h-2c0 2.206-1.794 4-4 4zM22.24 13.763A5.937 5.937 0 0018 12.002c-3.309 0-6 2.691-6 6h2c0-2.206 1.795-4 4-4 1.078 0 2.065.44 2.812 1.188L19 17.002h5v-5l-1.76 1.761z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});