define("@trovedata/sunstone-ui-commons/transforms/datasetattribute", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _transform.default.extend({
    deserialize(serialized) {
      return serialized;
    },
    serialize(deserialized) {
      return deserialized;
    }
  });
});