define("ember-svg-jar/inlined/navigation-down-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path fill=\"#757575\" d=\"M12 14.415L3.586 6 5 4.586l7 7 7-7L20.414 6z\"/></g><g><path fill=\"#757575\" d=\"M12 19.415L3.586 11 5 9.586l7 7 7-7L20.414 11z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});