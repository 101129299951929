define("@trovedata/trove-scenario-planner/scenario-planner/groupings/show/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupingsShowRoute extends _route.default {
    model({
      grouping_id
    }) {
      // @ts-ignore
      return this.store.findRecord('grouping', grouping_id);
    }
    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      this.setUpBreadCrumbs(controller, model);
    }
    resetController(controller, isExiting) {
      if (!isExiting) return;
      controller.applicationController.breadCrumbs = [];
    }
    setUpBreadCrumbs(controller, grouping) {
      controller.applicationController.breadCrumbs = [{
        label: `${grouping.name} Plans`
      }];
    }
  }
  _exports.default = GroupingsShowRoute;
});