define("@trovedata/sunstone-ui-commons/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(router) {
    router.route('registration');
    router.route('login');
    router.route('reset-password');
  }
});