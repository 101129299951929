define("ember-svg-jar/inlined/login-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><path fill=\"#757575\" d=\"M12.707 7.292l-1.414 1.416L13.59 11H2v2h11.582l-2.29 2.294 1.416 1.413 4.706-4.716z\"/><g><path d=\"M12 2C7.531 2 3.739 4.949 2.461 9h2.127C5.779 6.073 8.65 4 12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8c-3.35 0-6.221-2.072-7.411-5H2.461C3.738 19.052 7.53 22 12 22c5.514 0 10-4.486 10-10S17.514 2 12 2z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});