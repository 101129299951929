define("@trovedata/sunstone-ui-commons/components/sunstone-map-heatmap/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "hBueg45n",
    "block": "[[],[],false,[]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-map-heatmap/template.hbs",
    "isStrictMode": false
  });
});