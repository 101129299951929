define("ember-svg-jar/inlined/vote-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><path d=\"M18 22.001a1 1 0 01-.555-.168L12 18.203l-5.445 3.63a1 1 0 01-1.516-1.106l1.838-6.435-4.584-4.584A1.001 1.001 0 013 8.001h5.382l2.724-5.447c.339-.677 1.45-.677 1.789 0l2.724 5.447H21a1 1 0 01.707 1.707l-4.584 4.584 1.838 6.435A1 1 0 0118 22.001zm-6-6c.193 0 .387.057.555.168l3.736 2.491-1.252-4.384a.996.996 0 01.254-.982l3.293-3.293H15c-.379 0-.725-.214-.895-.553L12 5.237 9.895 9.448c-.17.339-.516.553-.895.553H5.414l3.293 3.293a1 1 0 01.254.982L7.709 18.66l3.736-2.491c.168-.111.362-.168.555-.168z\" fill=\"#757575\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});