define("@trovedata/sunstone-ui-commons/models/model-session", ["exports", "@ember/object", "rsvp", "ember-data", "moment", "@ember/array", "@trovedata/sunstone-ui-commons/models/trove-model", "@ember/service", "@ember/object/computed"], function (_exports, _object, _rsvp, _emberData, _moment, _array, _troveModel, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    PromiseObject,
    attr,
    belongsTo
  } = _emberData.default;
  let ModelSession = _exports.default = (_dec = attr('basic-date'), _dec2 = attr('basic-date'), _dec3 = attr('basic-date'), _dec4 = attr('string'), _dec5 = attr('number', {
    defaultValue: 1
  }), _dec6 = attr('boolean', {
    defaultValue: false
  }), _dec7 = belongsTo('model-instance', {
    async: true
  }), _dec8 = belongsTo('model-session-status', {
    async: false
  }), _dec9 = (0, _computed.alias)('modelInstance'), _dec10 = (0, _computed.alias)('modelSessionStatus'), _dec11 = (0, _computed.alias)('runTime'), _dec12 = attr(), _dec13 = (0, _object.computed)('reportMaps'), _dec14 = (0, _object.computed)('runTime', 'endTime'), _dec15 = (0, _object.computed)('runTime'), _dec16 = (0, _object.computed)('wasSuccessful'), _dec17 = (0, _object.computed)('wasSuccessful'), _dec18 = (0, _computed.reads)('status.name'), _dec19 = (0, _object.computed)('state'), _dec20 = (0, _object.computed)('state'), _dec21 = (0, _object.computed)('state'), _dec22 = (0, _object.computed)('state'), _dec23 = (0, _object.computed)('state'), _dec24 = (0, _object.computed)('isDone', 'isRunning', 'isPaused', 'hasFailed'), _dec25 = (0, _computed.not)('isRunning'), _dec26 = (0, _object.computed)('isDone', 'isRunning', 'hasFailed'), (_class = class ModelSession extends _troveModel.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "RUNNING_STATES", void 0);
      _defineProperty(this, "DONE_STATES", void 0);
      _defineProperty(this, "PAUSED_STATES", void 0);
      _defineProperty(this, "FAILED_STATES", void 0);
      _defineProperty(this, "SUCCESSFUL_STATES", void 0);
      _defineProperty(this, "percentComplete", void 0);
      _initializerDefineProperty(this, "troveFetch", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      // properties
      // @ts-ignore
      _initializerDefineProperty(this, "runTime", _descriptor3, this);
      // @ts-ignore
      _initializerDefineProperty(this, "endTime", _descriptor4, this);
      // @ts-ignore
      _initializerDefineProperty(this, "modTime", _descriptor5, this);
      _initializerDefineProperty(this, "name", _descriptor6, this);
      _initializerDefineProperty(this, "showError", _descriptor7, this);
      _initializerDefineProperty(this, "execute", _descriptor8, this);
      // relationships
      _initializerDefineProperty(this, "modelInstance", _descriptor9, this);
      _initializerDefineProperty(this, "modelSessionStatus", _descriptor10, this);
      // property aliases
      _initializerDefineProperty(this, "instance", _descriptor11, this);
      _initializerDefineProperty(this, "status", _descriptor12, this);
      _initializerDefineProperty(this, "startTime", _descriptor13, this);
      _initializerDefineProperty(this, "reportMaps", _descriptor14, this);
      _defineProperty(this, "update", false);
      _defineProperty(this, "validData", false);
      _initializerDefineProperty(this, "state", _descriptor15, this);
      _initializerDefineProperty(this, "notRunning", _descriptor16, this);
      this.setProperties({
        RUNNING_STATES: ['READY', 'RUNNING', 'STOPPING'],
        DONE_STATES: ['KILLED', 'STOPPED', 'FAILED', 'FINISHED'],
        PAUSED_STATES: ['PAUSED'],
        FAILED_STATES: ['FAILED', 'KILLED'],
        SUCCESSFUL_STATES: ['FINISHED']
      });
    }
    get reportSessions() {
      if ((0, _array.A)(this.get('reportMaps')).length > 0) {
        return this.get('reportMaps')[0].reportEntrySessions;
      } else {
        return undefined;
      }
    }
    get duration() {
      let startTime = this.get('runTime');
      let endTime = this.get('endTime');
      if (startTime && endTime) {
        let minutes = parseInt(_moment.default.duration((0, _moment.default)(endTime).diff((0, _moment.default)(startTime))).asMinutes().toString(), 10);
        let postscript = minutes !== 1 ? 'minutes' : 'minute';
        return `${minutes} ${postscript}`;
      } else {
        return null;
      }
    }
    get createdAt() {
      return new Date(this.get('runTime')).getTime();
    }
    get facts() {
      const wasSuccessful = this.get('wasSuccessful');
      let promise = _rsvp.default.resolve({
        participants: undefined
      });
      if (wasSuccessful) {
        promise = this.troveFetch.request(`fact/search/sumOfIntegerByModelSessionAndParameterName?modelSessionId=${(0, _object.get)(this, 'id')}&parameterName=customerCount`).then(count => {
          return {
            participants: count
          };
        }, () => {
          return {
            participants: undefined
          };
        });
      }
      //@ts-ignore
      return PromiseObject.create({
        promise
      });
    }
    set facts(value) {
      //@ts-ignore
      return value;
    }
    get graph() {
      const wasSuccessful = this.get('wasSuccessful');
      let promise = _rsvp.default.resolve({
        data: undefined
      });
      if (wasSuccessful) {
        promise = this.troveFetch.request(`graphing/${(0, _object.get)(this, 'id')}`).then(result => {
          return {
            data: result[0].body
          };
        }, () => {
          return {
            data: undefined
          };
        });
      }

      //@ts-ignore
      return PromiseObject.create({
        promise
      });
    }
    fetchPercentComplete() {
      let promise = new _rsvp.default.Promise(resolve => {
        if (this.get('isRunning')) {
          this._getPromisedAttribute('percentComplete').then(value => {
            let percentageValue = value || 0;
            this.set('percentComplete', `${(percentageValue * 100).toFixed(2)}%`);
            resolve();
          });
        } else {
          resolve();
        }
      });
      return promise;
    }
    get isDone() {
      return this.DONE_STATES.indexOf(this.get('state')) >= 0;
    }
    get isRunning() {
      return this.RUNNING_STATES.indexOf(this.get('state')) >= 0;
    }
    get isPaused() {
      return this.PAUSED_STATES.indexOf(this.get('state')) >= 0;
    }
    get hasFailed() {
      return this.FAILED_STATES.indexOf(this.get('state')) >= 0;
    }
    get wasSuccessful() {
      return this.SUCCESSFUL_STATES.indexOf(this.get('state')) >= 0;
    }
    set wasSuccessful(value) {
      //@ts-ignore
      return value;
    }
    get isInactive() {
      return ['isDone', 'isRunning', 'isPaused', 'hasFailed'].every(state => {
        //@ts-ignore
        return !this.get(state);
      });
    }
    get statusClass() {
      if (this.get('hasFailed')) {
        return 'danger';
      }
      if (this.get('isDone')) {
        return 'success';
      }
      if (this.get('isRunning')) {
        return 'info';
      }
      return 'default';
    }
    _getPromisedAttribute(property) {
      return this.troveFetch.request(`/modelExecution/${this.get('id')}/${property}`);
    }
    killSession() {
      return this.troveFetch.request(`modelSession/${this.id}/killSession`, {
        namespace: this.config.get('trove.analyticService.namespace')
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "runTime", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endTime", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showError", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "execute", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modelInstance", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "modelSessionStatus", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "instance", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "startTime", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "reportMaps", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reportSessions", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "reportSessions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duration", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "duration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "facts", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "facts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "graph", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "graph"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDone", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "isDone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRunning", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "isRunning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPaused", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "isPaused"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasFailed", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "hasFailed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wasSuccessful", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "wasSuccessful"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInactive", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "isInactive"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "notRunning", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statusClass", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "statusClass"), _class.prototype)), _class));
});