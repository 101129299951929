define("@trovedata/sunstone-ui-commons/transforms/basic-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BasicDate extends _emberData.default.DateTransform {
    serialize(date) {
      if (typeof date === 'string') date = (0, _moment.default)(date);
      return _moment.default.isMoment(date) || date instanceof Date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;
    }
  }
  _exports.default = BasicDate;
});