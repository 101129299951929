define("@trovedata/sunstone-ui-commons/mixins/trove-confirm-dialog", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-new-mixins */
  var _default = _exports.default = _mixin.default.create({
    actions: {
      willTransition(transition) {
        this._super(...arguments);
        if ((0, _object.get)(this, 'controller.stopTransition') && !(0, _object.get)(this, 'controller.prevTransition')) {
          (0, _object.set)(this, 'controller.prevTransition', transition);
          transition.abort();
          (0, _object.set)(this, 'controller.showTroveConfirm', true);
        }
      }
    }
  });
});