define("@trovedata/sunstone-ui-commons/components/success-animation/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/success-animation/template", "@ember-decorators/component"], function (_exports, _component, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let SuccessAnimation = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.tagName)('success-animation'), _dec(_class = _dec2(_class = class SuccessAnimation extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "size", 80);
    }
  }) || _class) || _class);
  ;
});