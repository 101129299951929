define("@trovedata/sunstone-ui-commons/components/paper-async-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    isValid=isValid
    isInvalid=isInvalid
    isTouched=isTouched
    isInvalidAndTouched=isInvalidAndTouched
    input=(component inputComponent
      parentComponent=this
      onValidityChange=(action "onValidityChange")
    )
    submit-button=(component submitButtonComponent
      promise=promise
      type="submit"
    )
    select=(component selectComponent
    	parentComponent=this
    	onValidityChange=(action "onValidityChange")
    )
    select-multiple=(component selectMultipleComponent
      parentComponent=this
      onValidityChange=(action "onValidityChange")
    )
    autocomplete=(component autocompleteComponent
    	parentComponent=this
    	onValidityChange=(action "onValidityChange")
    )
    radio=(component radioComponent
      parentComponent=this
      onValidityChange=(action "onValidityChange")
    )
    checkbox=(component checkboxComponent
      parentComponent=this
      onValidityChange=(action "onValidityChange")
    )
    onSubmit=(action "onSubmit")
  )}}
  
  */
  {
    "id": "YaMmd/cJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"input\",\"submit-button\",\"select\",\"select-multiple\",\"autocomplete\",\"radio\",\"checkbox\",\"onSubmit\"],[[33,2],[33,3],[33,4],[33,5],[50,[33,7],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,9],0,null,[[\"promise\",\"type\"],[[33,10],\"submit\"]]],[50,[33,11],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,12],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,13],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,14],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,15],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[28,[37,8],[[30,0],\"onSubmit\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"component\",\"inputComponent\",\"action\",\"submitButtonComponent\",\"promise\",\"selectComponent\",\"selectMultipleComponent\",\"autocompleteComponent\",\"radioComponent\",\"checkboxComponent\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-async-form/template.hbs",
    "isStrictMode": false
  });
});