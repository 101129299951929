define("@trovedata/trove-scenario-planner/utils/agg-utils", ["exports", "@trovedata/sunstone-ui-commons/utils/array-utils"], function (_exports, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcFilterSignature = calcFilterSignature;
  _exports.clearFilters = clearFilters;
  _exports.selectAgg = selectAgg;
  function selectAgg(agg, select = true) {
    const filters = [];
    let aggType = 'line-agg';
    if ('spanName' in agg) {
      // span agg
      aggType = 'span-agg';
      filters.push({
        field: 'lineName',
        fieldDisplayName: 'Line Name',
        operator: ':',
        value: agg.lineName
      }, {
        field: 'lineSegmentName',
        fieldDisplayName: 'Line Segment Name',
        operator: ':',
        value: agg.lineSegmentName
      }, {
        field: 'spanName',
        fieldDisplayName: 'Span Name',
        operator: ':',
        value: agg.spanName
      });
    } else if ('lineSegmentName' in agg) {
      // segment agg
      aggType = select ? 'span-agg' : 'segment-agg';
      filters.push({
        field: 'lineName',
        fieldDisplayName: 'Line Name',
        operator: ':',
        value: agg.lineName
      }, {
        field: 'lineSegmentName',
        fieldDisplayName: 'Line Segment Name',
        operator: ':',
        value: agg.name
      });
    } else {
      // line agg
      aggType = select ? 'segment-agg' : 'line-agg';
      filters.push({
        field: 'lineName',
        fieldDisplayName: 'Line Name',
        operator: ':',
        value: agg.name
      });
    }
    return {
      aggType,
      filters
    };
  }
  function clearFilters(aggType, existingFilters = []) {
    let filters = [];
    const filtersByFilterField = (0, _arrayUtils.buildHash)(existingFilters, 'field');
    if (aggType === 'span-agg' && filtersByFilterField.lineName) {
      filters = [filtersByFilterField.lineName];
    }
    return filters;
  }
  function calcFilterSignature(filters = []) {
    return filters.map(filter => filter.field).sort().join(',');
  }
});