define("@trovedata/sunstone-ui-commons/adapters/user-manager-role", ["exports", "@trovedata/sunstone-ui-commons/adapters/application-json-api"], function (_exports, _applicationJsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserManagerRole extends _applicationJsonApi.default {
    getFallbackNamespace() {
      return this.config.get('trove.troveUserManager.namespace');
    }
    getTargetBackendServiceId() {
      return 'user-manager-service';
    }
    pathForType() {
      const newType = 'role';
      let _super = super.pathForType.bind(this);
      return _super(newType);
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = UserManagerRole;
});