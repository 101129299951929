define("@trovedata/sunstone-ui-commons/components/sunstone-heatmap-layer/component", ["exports", "@ember/array", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/sunstone-heatmap-layer/template"], function (_exports, _array, _object, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global google */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    markers: (0, _array.A)(),
    _markers: (0, _array.A)(),
    _circles: (0, _array.A)(),
    color: null,
    radius: 10,
    opacity: 1,
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map) {
        this._circles.forEach(circle => {
          circle.setMap(null);
        });
        (0, _object.set)(this, '_circles', (0, _array.A)());
      }
    },
    didRender() {
      this._super(...arguments);
      if (this.map) {
        (0, _object.set)(this, '_markers', []);
        let bounds = new google.maps.LatLngBounds();
        this.markers.forEach(marker => {
          if (marker.get('latitude') && marker.get('longitude')) {
            let latlng = new google.maps.LatLng(marker.get('latitude'), marker.get('longitude'));
            this._markers.push(latlng);
            bounds.extend(latlng);
          }
        });
        this.map.fitBounds(bounds);

        // Circle
        this._markers.forEach(marker => {
          let circle = new google.maps.Circle({
            strokeColor: this.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: this.color,
            fillOpacity: 0.35,
            map: this.map,
            center: marker,
            radius: 1500
          });
          this._circles.pushObject(circle);
        });
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._circles.forEach(circle => {
        circle.setMap(null);
      });
      (0, _object.set)(this, '_circles', (0, _array.A)());
    }
  });
});