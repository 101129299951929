define("@trovedata/sunstone-ui-commons/components/paper-slider-plus/component", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="paper-slider-plus layout-row layout-align-start-center"
    ...attributes
  >
    {{#if @showButtons}}
      <TroveButton
        data-test-button-decrement
        @disabled={{eq this.min @value}}
        @iconButton={{true}}
        @onClick={{fn @onChange (sub @value this.step)}}
      >
        <TroveIcon @icon="subtract-circle-1" @size={{28}} />
      </TroveButton>
    {{/if}}
  
    {{#if @showSliderRange}}
      <TroveLabel
        class="layout-margin-horizontal"
        data-test-range-min
        @bold={{true}}
        @label={{this.min}}
        @size={{15}}
      />
    {{/if}}
  
    <PaperSlider
      class="flex md-range md-info layout-margin-horizontal md-inverted"
      @max={{this.max}}
      @min={{this.min}}
      @primary={{true}}
      @step={{this.step}}
      @tooltip={{@tooltip}}
      @value={{@value}}
      @onChange={{@onChange}}
    />
  
    {{#if @showSliderRange}}
      <TroveLabel
        class="layout-margin-horizontal"
        data-test-range-max
        @bold={{true}}
        @label={{this.max}}
        @size={{15}}
      />
    {{/if}}
  
    {{#if @showButtons}}
      <TroveButton
        data-test-button-increment
        @disabled={{eq this.max @value}}
        @iconButton={{true}}
        @onClick={{fn @onChange (add @value this.step)}}
      >
        <TroveIcon @icon="add-circle-1" @size={{28}} />
      </TroveButton>
    {{/if}}
  </div>
  
  */
  {
    "id": "Ad3uWzQD",
    "block": "[[[11,0],[24,0,\"paper-slider-plus layout-row layout-align-start-center\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],[[24,\"data-test-button-decrement\",\"\"]],[[\"@disabled\",\"@iconButton\",\"@onClick\"],[[28,[37,2],[[30,0,[\"min\"]],[30,3]],null],true,[28,[37,3],[[30,4],[28,[37,4],[[30,3],[30,0,[\"step\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@icon\",\"@size\"],[\"subtract-circle-1\",28]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[8,[39,6],[[24,0,\"layout-margin-horizontal\"],[24,\"data-test-range-min\",\"\"]],[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"min\"]],15]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,7],[[24,0,\"flex md-range md-info layout-margin-horizontal md-inverted\"]],[[\"@max\",\"@min\",\"@primary\",\"@step\",\"@tooltip\",\"@value\",\"@onChange\"],[[30,0,[\"max\"]],[30,0,[\"min\"]],true,[30,0,[\"step\"]],[30,6],[30,3],[30,4]]],null],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[8,[39,6],[[24,0,\"layout-margin-horizontal\"],[24,\"data-test-range-max\",\"\"]],[[\"@bold\",\"@label\",\"@size\"],[true,[30,0,[\"max\"]],15]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],[[24,\"data-test-button-increment\",\"\"]],[[\"@disabled\",\"@iconButton\",\"@onClick\"],[[28,[37,2],[[30,0,[\"max\"]],[30,3]],null],true,[28,[37,3],[[30,4],[28,[37,8],[[30,3],[30,0,[\"step\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@icon\",\"@size\"],[\"add-circle-1\",28]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@showButtons\",\"@value\",\"@onChange\",\"@showSliderRange\",\"@tooltip\"],false,[\"if\",\"trove-button\",\"eq\",\"fn\",\"sub\",\"trove-icon\",\"trove-label\",\"paper-slider\",\"add\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-slider-plus/component.hbs",
    "isStrictMode": false
  });
  class PaperSliderPlus extends _component2.default {
    get min() {
      return this.args.sliderRange[0];
    }
    get max() {
      return this.args.sliderRange[1];
    }
    get step() {
      return this.args.step || 1;
    }
  }
  _exports.default = PaperSliderPlus;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaperSliderPlus);
});