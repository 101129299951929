define("@trovedata/sunstone-ui-commons/serializers/sunstone-setting", ["exports", "@ember/object", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serialize(snapshot) {
      let json = {
        id: snapshot.id
      };
      snapshot.eachAttribute(key => {
        (0, _object.set)(json, key, snapshot.attr(key));
      });
      snapshot.eachRelationship((key, relationship) => {
        if (relationship.kind === 'belongsTo') {
          let id = snapshot.belongsTo(key, {
            id: true
          });
          if (id) {
            (0, _object.set)(json, 'enumTypeId', id);
          }
        }
      });
      return json;
    },
    normalizeResponse(store, primaryModelClass, payload) {
      if (payload._embedded) {
        payload._embedded.sunstoneSettings.forEach(setting => {
          setting.enumType = setting.enumTypeId;
        });
      } else {
        payload.enumType = payload.enumTypeId;
      }
      return this._super(...arguments);
    }
  });
});