define("ember-svg-jar/inlined/navigation-filter-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .002h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M21 18.001H7a1 1 0 01-1-1v-14a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zm-13-2h12v-12H8v12z\" fill=\"#757575\"/></g><g><path d=\"M18 22.001H3a1 1 0 01-1-1v-15h2v14h14v2z\" fill=\"#757575\"/></g><g><circle cx=\"11\" cy=\"7.001\" r=\"2\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M16 9.001l-2.588 3.882L12 11.001l-3 4h10z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});