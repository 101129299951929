define("@trovedata/sunstone-ui-commons/components/card-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      card=(component 'card-list/card-list-item' _list=this)
    )
  }}
  
  */
  {
    "id": "8ocQoM9c",
    "block": "[[[18,1,[[28,[37,1],null,[[\"card\"],[[50,\"card-list/card-list-item\",0,null,[[\"_list\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/template.hbs",
    "isStrictMode": false
  });
});