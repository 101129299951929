define("ember-svg-jar/inlined/check-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M22.042 22h-20c-1.103 0-2-.897-2-2V4c0-1.102.897-2 2-2h20c1.103 0 2 .898 2 2v16c0 1.103-.897 2-2 2zm-20-18v16h20.001l-.001-16h-20z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M7.462 16.784l-4.114-2.743 1.387-2.081 1.886 1.257L9.956 7.38l2.171 1.241z\"/></g><g><path fill=\"#757575\" d=\"M14.042 9h6v2h-6z\"/></g><g><path fill=\"#757575\" d=\"M14.042 13h6v2h-6z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});