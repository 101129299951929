define("@trovedata/sunstone-ui-commons/models/model-instance", ["exports", "@ember/object", "@ember/service", "@ember/object/computed", "ember-data", "@trovedata/sunstone-ui-commons/models/trove-model"], function (_exports, _object, _service, _computed, _emberData, _troveModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  let ModelInstance = _exports.default = (_dec = attr('string'), _dec2 = attr('boolean', {
    defaultValue: true
  }), _dec3 = attr('number'), _dec4 = attr('utc-timestamp'), _dec5 = attr('boolean', {
    defaultValue: false
  }), _dec6 = belongsTo('model', {
    async: true,
    inverse: null
  }), _dec7 = hasMany('model-instance-schedule', {
    async: true
  }), _dec8 = hasMany('model-instance-parameter', {
    async: true
  }), _dec9 = hasMany('model-session', {
    async: true
  }), _dec10 = (0, _computed.alias)('modelInstanceParameters'), _dec11 = (0, _computed.alias)('modelSessions'), _dec12 = (0, _computed.alias)('modelInstanceSchedules'), _dec13 = (0, _computed.sort)('modelInstanceParameters', 'parameterSortOrder'), _dec14 = (0, _object.computed)('sessions.[]'), _dec15 = (0, _object.computed)('modelInstanceParameters.@each.{value,modelParameter}'), (_class = class ModelInstance extends _troveModel.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "parameterSortOrder", Object.freeze(['displayOrder:asc']));
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor2, this);
      // properties
      _initializerDefineProperty(this, "name", _descriptor3, this);
      _initializerDefineProperty(this, "visible", _descriptor4, this);
      _initializerDefineProperty(this, "profileId", _descriptor5, this);
      //@ts-ignore
      _initializerDefineProperty(this, "nextRunTime", _descriptor6, this);
      _initializerDefineProperty(this, "favorite", _descriptor7, this);
      // relationships
      _initializerDefineProperty(this, "model", _descriptor8, this);
      _initializerDefineProperty(this, "modelInstanceSchedules", _descriptor9, this);
      _initializerDefineProperty(this, "modelInstanceParameters", _descriptor10, this);
      _initializerDefineProperty(this, "modelSessions", _descriptor11, this);
      // property aliases
      _initializerDefineProperty(this, "parameters", _descriptor12, this);
      _initializerDefineProperty(this, "sessions", _descriptor13, this);
      _initializerDefineProperty(this, "schedules", _descriptor14, this);
      _initializerDefineProperty(this, "sortedParameters", _descriptor15, this);
      _defineProperty(this, "validations", {
        name: {
          presence: true
        },
        model: true,
        parameters: true,
        sessions: true,
        schedules: true
      });
    }
    // computed properties
    get lastSession() {
      let lastSession = null;
      this.sessions.forEach(session => {
        lastSession = !lastSession || session.get('createdAt') > lastSession.get('createdAt') ? session : lastSession;
      });
      return lastSession;
    }
    get allRequiredParametersValid() {
      return this.modelInstanceParameters?.reduce((isValid, modelInstanceParameter) => {
        if (modelInstanceParameter.modelParameter?.get('required') === true && modelInstanceParameter.value === undefined) {
          isValid = false;
        }
        return isValid;
      }, true);
    }
    executeInstance() {
      return this.troveFetch.request(`executeModelInstance?modelInstanceId=${this.id}`, {
        namespace: this.config.get('trove.analyticService.namespace')
      });
    }
    getParameterByName(name) {
      return this.modelInstanceParameters?.find(modelInstanceParameter => {
        return modelInstanceParameter?.modelParameter.get('name') === name;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "visible", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "profileId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nextRunTime", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "favorite", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modelInstanceSchedules", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "modelInstanceParameters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "modelSessions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "parameters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sessions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "schedules", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "sortedParameters", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lastSession", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "lastSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allRequiredParametersValid", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "allRequiredParametersValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "executeInstance", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "executeInstance"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});