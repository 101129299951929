define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      action=(component 'card-list/card-list-item/card-list-item-content/card-list-item-actions/card-list-item-action' toggleActions=toggleActions)
    )
  }}
  
  */
  {
    "id": "cLHzpAzP",
    "block": "[[[18,1,[[28,[37,1],null,[[\"action\"],[[50,\"card-list/card-list-item/card-list-item-content/card-list-item-actions/card-list-item-action\",0,null,[[\"toggleActions\"],[[33,3]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"toggleActions\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/template.hbs",
    "isStrictMode": false
  });
});