define("@trovedata/sunstone-ui-commons/utils/serialize-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeQueryParams;
  function serializeQueryParams(params) {
    return Object.keys(params).reduce((query, key) => {
      let connector = query === '' ? '' : '&';
      return `${query}${connector}${key}=${params[key]}`;
    }, '');
  }
});