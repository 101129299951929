define("@trovedata/sunstone-ui-commons/components/trove-icon/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember", "@trovedata/sunstone-ui-commons/components/trove-icon/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "@ember-decorators/component"], function (_exports, _computed, _component, _object, _ember, _template, _troveColorMixin, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    String: Str
  } = _ember.default;
  let TroveIconComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.tagName)('md-icon'), _dec3 = (0, _component2.classNames)('paper-icon', 'md-font', 'material-icons', 'md-default-theme'), _dec4 = (0, _component2.className)('md-circle'), _dec5 = (0, _object.computed)('icon', 'positionalIcon'), _dec6 = (0, _computed.reads)('iconClass'), _dec7 = (0, _component2.attribute)('aria-label'), _dec8 = (0, _component2.attribute)('title'), _dec9 = (0, _component2.attribute)('role'), _dec10 = (0, _object.computed)('spin', 'reverseSpin'), _dec11 = (0, _object.computed)('size'), _dec12 = (0, _component2.attribute)('style'), _dec13 = (0, _object.computed)('size', 'iconColor'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class TroveIconComponent extends _component.default.extend(_troveColorMixin.default).reopenClass({
    positionalParams: ['positionalIcon']
  }) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "icon", '');
      _defineProperty(this, "positionalIcon", void 0);
      _defineProperty(this, "spin", false);
      _defineProperty(this, "reverseSpin", false);
      _defineProperty(this, "size", void 0);
      _defineProperty(this, "iconColor", void 0);
      _initializerDefineProperty(this, "circle", _descriptor, this);
      _initializerDefineProperty(this, "ariaLabel", _descriptor2, this);
      _initializerDefineProperty(this, "title", _descriptor3, this);
      _initializerDefineProperty(this, "role", _descriptor4, this);
    }
    get iconClass() {
      return this.getWithDefault('positionalIcon', this.get('icon'));
    }
    get spinClass() {
      if (this.get('spin')) {
        return 'md-spin';
      } else if (this.get('reverseSpin')) {
        return 'md-spin-reverse';
      } else {
        return null;
      }
    }
    get svgSize() {
      return this.size ? Str.htmlSafe(`${this.size}px`) : null;
    }
    get iconStyle() {
      let iconStyle = '';
      if (this.size) {
        iconStyle = `
        height: ${this.size}px;
        min-height: ${this.size}px;
        width: ${this.size}px;
        min-width: ${this.size}px;
        font-size: ${this.size}px;
        line-height: ${this.size}px;
      `;
      }
      if (this.iconColor) {
        iconStyle = `${iconStyle}
        color: ${this.iconColor} !important;
        fill: ${this.iconColor} !important;
      `;
      }
      return iconStyle ? Str.htmlSafe(iconStyle) : Str.htmlSafe('');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "circle", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "iconClass", [_component2.className, _dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClass"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ariaLabel", [_dec6, _dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "title", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "role", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'img';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "spinClass", [_component2.className, _dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "spinClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "svgSize", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "svgSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconStyle", [_dec12, _dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "iconStyle"), _class2.prototype)), _class2)) || _class) || _class) || _class);
});