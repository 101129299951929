define("@trovedata/sunstone-ui-commons/adapters/model-instance-config", ["exports", "@ember/object/computed", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _computed, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModelInstanceConfig extends _application.default.extend({
    namespace: (0, _computed.readOnly)('config.trove.analyticService.namespace')
  }) {}

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = ModelInstanceConfig;
});