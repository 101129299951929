define("@trovedata/sunstone-ui-commons/adapters/restheart-adapter", ["exports", "@trovedata/sunstone-ui-commons/adapters/application-json-api", "ember-data"], function (_exports, _applicationJsonApi, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const {
    AdapterError
  } = _emberData.default;
  class RestheartAdapter extends _applicationJsonApi.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "db", 'trovedata');
    }
    getFallbackNamespace() {
      return `${this.config.get('trove.restheart.namespace')}/${this.db}`;
    }
    getTargetBackendServiceId() {
      return 'restheart';
    }
    handleResponse(_status, _headers, payload /*options*/) {
      const missingDB = _status === 404 && payload.message === `Db '${this.db}' does not exist`;
      const messagePieces = (payload && payload.message ? payload.message : '').split('\'');
      const missingCollection = _status === 404 && messagePieces && messagePieces.length === 3 && messagePieces[0] === 'Collection ' && messagePieces[2] === ' does not exist';

      // if (missingDB) {
      //   await this.createMissingDBOrCollection(this.db);
      // }

      // if (missingCollection) {
      //   await this.createMissingDBOrCollection(this.db, messagePieces[1]);
      // }

      // if (missingDB || missingCollection) {
      //   return this.ajax(options.url, options.method);
      // }

      if (missingDB || missingCollection) {
        return new AdapterError(payload);
      }
      const externalLocation = _headers && _headers.location && !payload;
      if (externalLocation) {
        const locationPieces = _headers.location.split('/');
        payload = {
          _id: {
            $oid: locationPieces[locationPieces.length - 1]
          }
        };
      }
      if (this.isSuccess(_status, _headers, payload)) {
        return payload;
      } else {
        return new AdapterError(payload);
      }
    }

    // private async createMissingDBOrCollection(db: string, collection?: string) {
    //   return this.troveFetch.put(`${db}${collection ? '/' + collection : ''}`, {
    //     namespace: this.config.get('trove.restheart.namespace'),
    //     contentType: 'application/json'
    //   });
    // }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = RestheartAdapter;
});