define("ember-svg-jar/inlined/shrink-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M8 17.414V20h2v-6H4v2h2.586l-5.293 5.293 1.414 1.414zM20 8h-2.586l5.293-5.293-1.414-1.414L16 6.586V4h-2v6h6zM16 17.414l5.293 5.293 1.414-1.414L17.414 16H20v-2h-6v6h2zM4 8v2h6V4H8v2.586L2.707 1.293 1.293 2.707 6.586 8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});