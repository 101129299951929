define("ember-svg-jar/inlined/business-graph-bar-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M5 19v-1h13a1 1 0 001-1v-4a1 1 0 00-1-1h-7V9.999h3a1 1 0 001-1V5a1 1 0 00-1-1H5V3H3v17a1 1 0 001 1h17v-2H5zm12-3H5v-2h12v2zM5 12v-2h4v2H5zm8-6v1.999H5V6h8z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});