define("@trovedata/sunstone-ui-commons/utils/map-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildGeoJson;
  function buildGeoJson(type, coordinates, options) {
    const geoJson = {
      name: options?.name,
      properties: {
        icon: options?.icon,
        selectedColor: options?.selectedColor,
        fillColor: options?.fillColor,
        borderColor: options?.borderColor,
        name: options?.name,
        selectedWeight: options?.selectedWeight,
        weight: options?.weight,
        strokeWeight: options?.strokeWeight,
        componentInfoWindowProps: options?.componentInfoWindowProps,
        zIndex: options?.zIndex,
        bounds: options?.bounds,
        ...options?.extraProps
      },
      geometry: {
        type,
        coordinates
      },
      type: 'Feature'
    };
    return geoJson;
  }
});