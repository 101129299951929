define("@trovedata/sunstone-ui-commons/helpers/is-active-route", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _helper.default {
    compute([navRoute, currentRouteName]) {
      return currentRouteName.includes(navRoute);
    }
  }
  _exports.default = _default;
});