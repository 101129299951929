define("@trovedata/trove-scenario-planner/helpers/format-filter", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatFilter = formatFilter;
  const OPERATOR_MAP = {
    ';': 'in'
  };
  function formatFilter([fieldDisplayName, operator, displayValue, value]) {
    return `${fieldDisplayName} ${OPERATOR_MAP[operator] ?? operator} ${displayValue ? displayValue : value}`;
  }
  var _default = _exports.default = (0, _helper.helper)(formatFilter);
});