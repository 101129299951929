define("@trovedata/sunstone-ui-commons/components/sunstone-map/component", ["exports", "@ember/runloop", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/sunstone-map/template", "@ember-decorators/component"], function (_exports, _runloop, _object, _component, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SunstoneMap = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.className)('md-small'), _dec3 = (0, _component2.className)('md-medium'), _dec(_class = (_class2 = class SunstoneMap extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "map", void 0);
      _defineProperty(this, "mapType", 'sunstone-map-marker');
      _defineProperty(this, "options", {
        center: {
          lat: 39.8282,
          lng: -98.5795
        },
        zoom: 4,
        bounds: undefined
      });
      _defineProperty(this, "onClick", void 0);
      // @ts-ignore
      _defineProperty(this, "interval", void 0);
      _defineProperty(this, "google", void 0);
      _initializerDefineProperty(this, "small", _descriptor, this);
      _initializerDefineProperty(this, "medium", _descriptor2, this);
    }
    didInsertElement() {
      super.didInsertElement();
      this.set('interval', setInterval(() => {
        try {
          if (typeof google !== 'undefined') {
            clearInterval(this.interval);
            this.initialize();
            this.destroyInterval();
            this.set('google', google);
          }
        } catch (e) {
          // empty catch
        }
      }, 1000));
    }
    initialize() {
      let options = (0, _object.get)(this, 'options');
      let onClick = (0, _object.get)(this, 'onClick');
      // @ts-ignore
      let map = new google.maps.Map(this.element.querySelector('#map'), options);
      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        (0, _object.set)(this, 'map', map);
      });
      let {
        bounds
      } = options;
      if (bounds) {
        // @ts-ignore
        map.fitBounds(bounds);
        let center = map.getCenter();
        if (center) {
          map.panTo(center);
        }
      }
      if (onClick) {
        map.addListener('click', function (point) {
          onClick(point);
        });
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      let map = (0, _object.get)(this, 'map');
      if (map) {
        let {
          bounds
        } = (0, _object.get)(this, 'options');
        if (bounds) {
          map.fitBounds(bounds);
          let center = map.getCenter();
          if (center) {
            map.panTo(center);
          }
        }
      }
    }
    willDestroyElement() {
      super.willDestroyElement();
      (0, _object.set)(this, 'map', null);
      if (this.interval) {
        clearInterval(this.interval);
        this.destroyInterval();
      }
    }
    destroyInterval() {
      this.set('interval', undefined);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "small", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "medium", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
});