define("ember-svg-jar/inlined/control-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M19.707 10.707L23.414 7l-3.707-3.707-1.414 1.414L19.586 6H17c-3.905 0-4.943 1.489-5.858 2.803C10.32 9.982 9.611 11 6 11H0v2h6c3.611 0 4.32 1.018 5.142 2.197C12.057 16.511 13.095 18 17 18h2.586l-1.293 1.293 1.414 1.414L23.414 17l-3.707-3.707-1.414 1.414L19.586 16H17c-2.861 0-3.43-.816-4.217-1.946-.479-.687-1.016-1.458-2.029-2.054 1.014-.596 1.551-1.367 2.029-2.054C13.57 8.816 14.139 8 17 8h2.586l-1.293 1.293 1.414 1.414z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});