define("@trovedata/sunstone-ui-commons/loading/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="layout-column flex layout-fill layout-align-center-center">
    <TroveSpinner @accent={{true}} @diameter={{60}}/>
  </div>
  
  */
  {
    "id": "/gnhhaDc",
    "block": "[[[10,0],[14,0,\"layout-column flex layout-fill layout-align-center-center\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@accent\",\"@diameter\"],[true,60]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"trove-spinner\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/loading/template.hbs",
    "isStrictMode": false
  });
});