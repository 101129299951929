define("@trovedata/sunstone-ui-commons/utils/array-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildHash = buildHash;
  function buildHash(array, key, value) {
    return array?.reduce((hash, item) => {
      hash[`${key ? item[key] : item}`] = value ? item[value] : item;
      return hash;
    }, {}) || {};
  }
});