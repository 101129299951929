define("@trovedata/sunstone-ui-commons/utils/component-utils", ["exports", "@ember/application", "@ember/template"], function (_exports, _application, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcColorVar = calcColorVar;
  _exports.calcStyles = calcStyles;
  _exports.default = initComponent;
  _exports.initGlimmerComponent = initGlimmerComponent;
  function initComponent(scope, name, props = {}) {
    const owner = (0, _application.getOwner)(scope);
    // @ts-ignore
    const factory = owner.factoryFor(`component:${name}`);
    const comp = factory.create(props);
    comp.didReceiveAttrs();
    return comp;
  }
  function initGlimmerComponent(scope, name, named = {}) {
    const owner = (0, _application.getOwner)(scope);
    // @ts-ignore
    let componentManager = owner.lookup('component-manager:glimmer');
    // @ts-ignore
    let {
      class: componentClass
    } = owner.factoryFor(`component:${name}`);
    return componentManager.createComponent(componentClass, {
      named
    });
  }
  function calcColorVar(className, value) {
    if (typeof value === 'boolean' && value) {
      return className;
    }
    if (typeof value === 'number' && value) {
      return `${className} color-${value}`;
    }
    return undefined;
  }
  function calcStyles(stylesConf = {}) {
    let styles = '';
    if (stylesConf.opacity !== undefined) {
      styles = `${styles}--opacity: ${stylesConf.opacity};`;
    }
    return (0, _template.htmlSafe)(styles);
  }
});