define("@trovedata/sunstone-ui-commons/components/alias-prop/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/alias-prop/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let aliasProp = _component.default.extend({
    layout: _template.default,
    tagName: ''
  });
  aliasProp.reopenClass({
    positionalParams: ['prop']
  });
  var _default = _exports.default = aliasProp;
});