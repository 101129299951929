define("@trovedata/sunstone-ui-commons/models/geo-location-boundry", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('integer'),
    validBoundry: (0, _model.attr)('boolean'),
    coordinates: (0, _model.attr)('string'),
    nameCount: (0, _model.attr)('integer'),
    weight: (0, _model.attr)('integer')
  });
});