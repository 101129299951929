define("@trovedata/sunstone-ui-commons/components/sunstone-draggable-dropzone/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  
  */
  {
    "id": "V3PLDTuj",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-draggable-dropzone/template.hbs",
    "isStrictMode": false
  });
});