define("@trovedata/sunstone-ui-commons/application/controller", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/application", "@ember/object/computed", "@ember-decorators/object", "@trovedata/sunstone-ui-commons/utils/string-utils"], function (_exports, _controller, _service, _object, _application, _computed, _object2, _stringUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let walkRoutes = (fn, context) => {
    let currentRouteName = (0, _object.get)(context, 'currentPath');
    let routeTree = currentRouteName.split('.');
    let needle = undefined;
    for (let i = routeTree.length; i > 0; i--) {
      // @ts-ignore
      let route = (0, _application.getOwner)(context).lookup(`route:${routeTree.slice(0, i).join('.')}`);
      needle = fn(route);
      if (needle !== undefined) {
        break;
      }
    }
    return needle;
  };
  let ApplicationController = _exports.default = (_dec = (0, _computed.alias)('router.currentRouteName'), _dec2 = (0, _object.computed)('theme'), _dec3 = (0, _object.computed)('theme'), _dec4 = (0, _computed.alias)('currentUser.theme'), _dec5 = (0, _object2.observes)('theme'), _dec6 = (0, _object.computed)('currentRouteName'), _dec7 = (0, _object.computed)('currentRouteName'), _dec8 = (0, _object.computed)('currentRouteName'), _dec9 = (0, _object.computed)('currentRouteName'), _dec10 = (0, _object.computed)('currentRouteName'), _dec11 = (0, _object.computed)('currentRouteName'), (_class = class ApplicationController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "troveTheme", _descriptor5, this);
      _defineProperty(this, "queryParams", ['host', 'redirect']);
      _defineProperty(this, "error", void 0);
      _defineProperty(this, "host", void 0);
      _defineProperty(this, "redirect", void 0);
      _initializerDefineProperty(this, "currentRouteName", _descriptor6, this);
      _initializerDefineProperty(this, "theme", _descriptor7, this);
      _defineProperty(this, "synthesisConfig", [{
        route: 'data-catalog.datasource-viewer',
        externalRoute: 'data-catalog',
        icon: 'DataConnections-White',
        title: 'commons.connectionsTitle'
      }, {
        route: 'data-catalog.attribute-fusion',
        externalRoute: 'data-catalog/attribute-fusion/fuse-jobs/location/rates/match',
        icon: 'TROVEdata-White',
        title: 'commons.troveDataTitle'
      }, {
        route: 'data-catalog.browser.data-field',
        externalRoute: 'data-catalog/browser/data-field',
        icon: 'DataCatalog-White',
        title: 'commons.catalogTitle'
      }, {
        route: 'data-catalog.viewer.results',
        externalRoute: 'data-catalog/viewer/results',
        icon: 'DataFrames-White',
        title: 'commons.framesTitle'
      }, {
        route: 'analytics-console.modeling',
        externalRoute: 'analytics-console/execution',
        icon: 'Analytics-White',
        title: 'commons.analyticsTitle'
      }]);
      _defineProperty(this, "loggingConfig", {
        route: 'logging-portal',
        externalRoute: 'logging-portal',
        icon: 'file-information-2',
        title: 'Logs'
      });
      _defineProperty(this, "isLoggedIn", true);
    }
    get foreground() {
      switch (this.theme) {
        case 'light':
          return this.troveTheme.foregroundLight;
        case 'dark':
          return this.troveTheme.foregroundDark;
        default:
          return this.troveTheme.foregroundDark;
      }
    }
    get primary() {
      switch (this.theme) {
        case 'light':
          return this.troveTheme.primaryLight;
        case 'dark':
          return this.troveTheme.primaryDark;
        default:
          return this.troveTheme.primaryDark;
      }
    }
    themeObserver() {
      this.troveTheme.installTheme('main', {
        primary: this.get('primary').palette,
        accent: this.troveTheme.accent.palette,
        warn: this.troveTheme.warn.palette,
        alternative: this.troveTheme.alternative.palette,
        info: this.troveTheme.info.palette,
        alert: this.troveTheme.alert.palette,
        foreground: this.get('foreground')
      });
      let $body = document.querySelector('body');
      if ($body) {
        let classes = $body.className.replace(' md-dark', '').replace(' md-light', '');
        $body.setAttribute('class', `${classes} md-${this.theme ? this.theme : 'dark'}`);
      }
    }
    get currentController() {
      const currentRouteName = (0, _object.get)(this, 'currentRouteName');
      // @ts-ignore
      return (0, _application.getOwner)(this).lookup(`controller:${currentRouteName}`);
    }
    get overrideApplicationTemplate() {
      return walkRoutes(route => {
        return (0, _object.get)(route, 'controller.overrideApplicationTemplate');
      }, this);
    }
    get showLinks() {
      return walkRoutes(route => {
        return (0, _object.get)(route, 'controller.showLinks');
      }, this);
    }
    get logo() {
      return walkRoutes(route => {
        return (0, _object.get)(route, 'controller.logo');
      }, this);
    }
    get hideSynthesis() {
      return walkRoutes(route => {
        return (0, _object.get)(route, 'controller.hideSynthesis');
      }, this);
    }
    get leftNavConfig() {
      return walkRoutes(route => {
        let config = (0, _object.get)(route, 'leftNavConfig');
        if (config === undefined) {
          let controller = (0, _object.getWithDefault)(route, 'controller', undefined);
          if (controller && controller !== this) {
            config = (0, _object.get)(controller, 'leftNavConfig');
          }
        }
        return config;
      }, this);
    }
    copyError(error) {
      (0, _stringUtils.copyToClipboard)(error);
    }
    dismissError() {
      this.set('error', null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "troveTheme", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "foreground", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "foreground"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "primary", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "primary"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "themeObserver", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "themeObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentController", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "currentController"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "overrideApplicationTemplate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "overrideApplicationTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showLinks", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "logo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideSynthesis", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "hideSynthesis"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "leftNavConfig", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "leftNavConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismissError"), _class.prototype)), _class));
});