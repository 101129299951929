define("@trovedata/sunstone-ui-commons/serializers/model-resource", ["exports", "@trovedata/sunstone-ui-commons/serializers/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModelResource extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      ((0, _utils.typeOf)(payload) === 'array' ? payload : [payload]).forEach(record => {
        record.id = record.modelId;
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
  _exports.default = ModelResource;
});