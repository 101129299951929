define("@trovedata/trove-scenario-planner/components/navigation-show-more/component", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TroveIcon @icon="navigation-show-more-horizontal" @size={{20}} />
  
  */
  {
    "id": "x9ISdsd4",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@size\"],[\"navigation-show-more-horizontal\",20]],null],[1,\"\\n\"]],[],false,[\"trove-icon\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/navigation-show-more/component.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});