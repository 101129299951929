define("@trovedata/sunstone-ui-commons/serializers/fuse-session", ["exports", "@trovedata/sunstone-ui-commons/serializers/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FuseSessionSerializer extends _application.default {
    serialize(snapshot) {
      let json = {
        id: snapshot.id
      };
      snapshot.eachAttribute(key => {
        (0, _object.set)(json, key, snapshot.attr(key));
      });
      snapshot.eachRelationship((key, relationship) => {
        if (relationship.kind === 'belongsTo' && relationship.key === 'modelSessionStatus') {
          let status = snapshot.belongsTo(key);
          if (status) {
            (0, _object.set)(json, 'modelSessionStatus', {
              id: status.id,
              name: status._attributes.name
            });
          }
        }
      });
      return json;
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
  _exports.default = FuseSessionSerializer;
});