define("@trovedata/sunstone-ui-commons/models/trove-export-file", ["exports", "@ember/object", "@ember/object/computed", "@ember/array", "@ember-data/model"], function (_exports, _object, _computed, _array, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    filename: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    processed: (0, _model.attr)('boolean'),
    troveExportRequest: (0, _model.belongsTo)('trove-export-request'),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'downloadRequests', (0, _array.A)([]));
    },
    isDownloading: (0, _object.computed)('downloadRequests.@each.status', function () {
      return this.downloadRequests.any(downloadReq => {
        return downloadReq.status === 'PROCESSING' || downloadReq.status === 'PENDING';
      });
    }),
    isProcessing: (0, _object.computed)('status', function () {
      return this.status === 'PROCESSING' || this.status === 'PENDING' || this.status === 'CREATED' || this.status === 'GENERATING';
    }),
    isFinished: (0, _object.computed)('status', function () {
      return this.status === 'COMPLETE' || this.status === 'ERROR';
    }),
    isError: (0, _computed.equal)('status', 'ERROR'),
    isComplete: (0, _computed.equal)('status', 'COMPLETE')
  });
});