define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g fill=\"#757575\"><path d=\"M3.514 16.227l1.414-1.414-.707-.707a1.002 1.002 0 010-1.415l8.486-8.485c.188-.189.439-.293.707-.293s.518.104.708.293l.707.707L16.242 3.5l-.706-.707c-1.134-1.134-3.111-1.134-4.243 0l-8.485 8.484a3.003 3.003 0 000 4.243l.706.707zM21.192 8.451l-.708-.707-1.414 1.414.708.707a.99.99 0 01.292.707.996.996 0 01-.292.706l-8.485 8.486a1.027 1.027 0 01-1.415 0l-.707-.707-1.414 1.413.707.707a2.98 2.98 0 002.122.879 2.98 2.98 0 002.121-.879l8.485-8.485a2.979 2.979 0 00.878-2.12c0-.801-.312-1.555-.878-2.121z\"/></g><g><path transform=\"rotate(45.001 16.95 7.036)\" fill=\"#757575\" d=\"M15.949 2.036h2v10h-2z\"/></g><g><path transform=\"rotate(44.992 7.05 16.934)\" fill=\"#757575\" d=\"M6.05 11.934h1.999v10.001H6.05z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});