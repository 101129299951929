define("@trovedata/sunstone-ui-commons/helpers/duration", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { run } from '@ember/runloop';

  const order = ['d', 'h', 'm', 's'];
  const rules = [86400000, 3600000, 60000, 1000];
  let duration = function ([startTime, endTime], {
    interval = true
  }) {
    let difference = 0;
    let result = '';
    if (!startTime) {
      return null;
    }
    if (!_moment.default.isMoment(startTime)) {
      startTime = (0, _moment.default)(startTime);
    }
    if (endTime) {
      if (!_moment.default.isMoment(endTime)) {
        endTime = (0, _moment.default)(endTime);
      }
      difference = endTime.diff(startTime);
    } else {
      difference = (0, _moment.default)().diff(startTime);
    }
    if (difference < 1000) {
      return '0s';
    }
    order.forEach((key, index) => {
      let denominator = rules[index];
      let value = Math.floor(difference / denominator);
      difference = difference - value * denominator;
      if (value) {
        result = `${result} ${value}${key}`;
      }
    });
    if (!endTime && interval) {
      setTimeout(() => {
        this.recompute();
      }, parseInt(1000, 10));
    }
    return result;
  };
  var _default = _exports.default = _helper.default.extend({
    compute: duration
  });
});