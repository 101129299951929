define("@trovedata/sunstone-ui-commons/adapters/model-session-resource", ["exports", "@trovedata/sunstone-ui-commons/adapters/application", "@ember/object/computed"], function (_exports, _application, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlForQuery(query, modelName) {
      return this._buildURL(modelName);
    },
    namespace: (0, _computed.readOnly)('config.trove.analyticService.namespace')
  });
});