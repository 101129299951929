define("@trovedata/trove-scenario-planner/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const defaults = {
    path: '/scenario-planner'
  };
  function _default(router, options = defaults) {
    router.route('scenario-planner', options, function () {
      this.route('plan', function () {
        this.route('edit');
      });
      this.route('groupings', function () {
        this.route('show', {
          path: ':grouping_id'
        }, function () {
          this.route('plans');
        });
      });
      this.route('not-found', {
        path: '/*'
      });
    });
  }
});