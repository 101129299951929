define("ember-svg-jar/inlined/paragraph-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20 2H4c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-.003 18H4V4h16l-.003 16z\"/><path d=\"M6 7h12v2H6zM6 11h12v2H6zM6 15h9v2H6z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});