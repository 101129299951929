define("@trovedata/sunstone-ui-commons/models/sunstone-report-session", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    runDate: (0, _model.attr)('basic-date'),
    reportType: (0, _model.attr)('string', {
      defaultValue: 'EXCEL'
    }),
    executed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sunstoneReport: (0, _model.belongsTo)('sunstone-report', {
      async: true
    }),
    parameters: (0, _model.hasMany)('sunstone-report-session-parameter', {
      async: false
    }),
    report: (0, _computed.alias)('sunstoneReport')
  });
});