define("@trovedata/sunstone-ui-commons/models/data-field-filter", ["exports", "@ember/array", "@ember/utils", "@ember/object", "@ember-data/model", "ember-data-model-fragments/fragment", "@trovedata/sunstone-ui-commons/utils/verify-enum-value", "ember-data-model-fragments/attributes"], function (_exports, _array, _utils, _object, _model, _fragment, _verifyEnumValue, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    position: 0,
    type: '',
    operatorName: (0, _model.attr)('string', {
      defaultValue: 'EQUAL'
    }),
    minValues: (0, _attributes.array)('string'),
    maxValues: (0, _attributes.array)('string'),
    active: false,
    name: (0, _object.computed)('position', function () {
      return `Filter  ${this.position}`;
    }),
    usedEnumValue: null,
    serialize() {
      let minValues = (0, _array.A)();
      let maxValues = (0, _array.A)();
      this.minValues.forEach(val => {
        if (val && (0, _utils.typeOf)(val) !== 'string') {
          let enumKey = this.usedEnumValue ? this.usedEnumValue : (0, _verifyEnumValue.default)(val, this.type);
          let usedEnumValue = (0, _object.get)(val, `${enumKey}`);
          minValues.pushObject(`${usedEnumValue}`);
        } else {
          minValues.pushObject(val);
        }
      });
      this.maxValues.forEach(val => {
        if (val && (0, _utils.typeOf)(val) !== 'string') {
          let enumKey = this.usedEnumValue ? this.usedEnumValue : (0, _verifyEnumValue.default)(val, this.type);
          let usedEnumValue = (0, _object.get)(val, `${enumKey}`);
          maxValues.pushObject(`${usedEnumValue}`);
        } else {
          maxValues.pushObject(val);
        }
      });
      (0, _object.set)(this, 'minValues', minValues);
      (0, _object.set)(this, 'maxValues', maxValues);
      return this;
    }
  });
});