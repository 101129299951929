define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _component, _template, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _exports.default = _component.default.extend(_troveColorMixin.default, {
    layout: _template.default,
    tagName: 'li',
    classNames: ['card'],
    classNameBindings: ['showActions'],
    showActions: false,
    _list: null,
    didInsertElement() {
      this._super(...arguments);
      this._list._registerItem(this);
    },
    willDestroy() {
      this._super(...arguments);
      this._list._unregisterItem(this);
    },
    actions: {
      toggleActions() {
        let value = this.toggleProperty('showActions');
        if (value) {
          this._list._closeInactiveItems(this);
        }
      }
    }
  });
});