define("@trovedata/sunstone-ui-commons/components/paper-async-form/component", ["exports", "@ember/object", "ember-paper/components/paper-form", "@trovedata/sunstone-ui-commons/components/paper-async-form/template"], function (_exports, _object, _paperForm, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _paperForm.default.extend({
    layout: _template.default,
    submitButtonComponent: 'trove-button',
    selectMultipleComponent: 'paper-select-multiple',
    radioComponent: 'paper-radio-group',
    checkboxComponent: 'paper-checkbox',
    submit() {
      (0, _object.set)(this, 'promise', this.onSubmit());
      return false;
    }
  });
});