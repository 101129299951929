define("@trovedata/sunstone-ui-commons/models/model-session-resource", ["exports", "@ember/object/computed", "@ember/object", "ember-data/model", "ember-data"], function (_exports, _computed, _object, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr
  } = _emberData.default;
  let ModelSessionReourceModel = _exports.default = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('basic-date'), _dec4 = (0, _computed.alias)('startTime'), _dec5 = attr('basic-date'), _dec6 = attr('string'), _dec7 = attr('number'), _dec8 = (0, _object.computed)('startTime'), _dec9 = (0, _object.computed)('isDone', 'isRunning', 'hasFailed'), _dec10 = (0, _computed.alias)('sessionStatus'), _dec11 = (0, _object.computed)('status'), _dec12 = (0, _object.computed)('status'), _dec13 = (0, _object.computed)('status'), _dec14 = (0, _object.computed)('status'), _dec15 = (0, _object.computed)('status'), _dec16 = (0, _object.computed)('isDone', 'isRunning', 'isPaused', 'hasFailed'), _dec17 = (0, _computed.not)('isRunning'), (_class = class ModelSessionReourceModel extends _model.default.reopenClass({
    RUNNING_STATES: ['READY', 'RUNNING', 'STOPPING'],
    DONE_STATES: ['KILLED', 'STOPPED', 'FAILED', 'FINISHED'],
    PAUSED_STATES: ['PAUSED'],
    FAILED_STATES: ['FAILED', 'KILLED'],
    SUCCESSFUL_STATES: ['FINISHED']
  }) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "modelInstanceName", _descriptor, this);
      _initializerDefineProperty(this, "modelName", _descriptor2, this);
      _initializerDefineProperty(this, "startTime", _descriptor3, this);
      _initializerDefineProperty(this, "runTime", _descriptor4, this);
      _initializerDefineProperty(this, "endTime", _descriptor5, this);
      _initializerDefineProperty(this, "sessionStatus", _descriptor6, this);
      _initializerDefineProperty(this, "modelInstanceId", _descriptor7, this);
      _initializerDefineProperty(this, "status", _descriptor8, this);
      _initializerDefineProperty(this, "notRunning", _descriptor9, this);
    }
    get createdAt() {
      return new Date((0, _object.get)(this, 'startTime')).getTime();
    }
    get statusClass() {
      if (this.get('hasFailed')) {
        return 'warn';
      }
      if (this.get('isDone')) {
        return 'accent';
      }
      if (this.get('isRunning')) {
        return 'info';
      }
      return 'default';
    }
    get isDone() {
      //@ts-ignore
      return this.constructor.DONE_STATES.indexOf(this.get('status')) >= 0;
    }
    get isRunning() {
      //@ts-ignore
      return this.constructor.RUNNING_STATES.indexOf(this.get('status')) >= 0;
    }
    get isPaused() {
      //@ts-ignore
      return this.constructor.PAUSED_STATES.indexOf(this.get('status')) >= 0;
    }
    get hasFailed() {
      //@ts-ignore
      return this.constructor.FAILED_STATES.indexOf(this.get('status')) >= 0;
    }
    get wasSuccessful() {
      //@ts-ignore
      return this.constructor.SUCCESSFUL_STATES.indexOf(this.get('status')) >= 0;
    }
    get isInactive() {
      return ['isDone', 'isRunning', 'isPaused', 'hasFailed'].every(status => {
        return !this[status];
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelInstanceName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "startTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "runTime", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "endTime", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sessionStatus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modelInstanceId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "statusClass", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "statusClass"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDone", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isDone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRunning", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isRunning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPaused", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isPaused"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasFailed", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "hasFailed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wasSuccessful", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "wasSuccessful"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInactive", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isInactive"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "notRunning", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});