define("@trovedata/sunstone-ui-commons/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  class Json extends Transform {
    deserialize(serialized) {
      return serialized;
    }
    serialize(deserialized) {
      return deserialized;
    }
  }
  _exports.default = Json;
});