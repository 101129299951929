define("@trovedata/sunstone-ui-commons/mirage/config", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const {
    trove
  } = _emberGetConfig.default;
  function _default() {
    this.namespace = trove.troveUserManager.namespace;
    this.get('/currentUser', () => {
      return currentUser.call(this);
    });
    this.get('/user/:id', (schema, request) => {
      return user.call(this, request.params.id);
    });
    this.namespace = trove.restheart.namespace;
    this.namespace = trove.api.namespace;
    this.get('/profiles/:id', (schema, request) => {
      return profiles.call(this, request.params.id);
    });
    this.get('/organization', () => {
      return organization.call(this);
    });
    let organization = function () {
      return {
        "_embedded": {
          "organization": [{
            "id": 1,
            "name": "Sunstone",
            "description": "Sunstone Organization",
            "insertBy": 1,
            "insertTime": "2016-01-01T00:00:00.000+0000",
            "lastUpdateBy": 1,
            "lastUpdateTime": "2016-01-01T00:00:00.000+0000",
            "dataSource": "1",
            "_links": {
              "self": {
                "href": `${this.urlPrefix}/${this.namespace}/organization/1`
              },
              "organization": {
                "href": `${this.urlPrefix}/${this.namespace}/api/organization/1{?projection}`,
                "templated": true
              }
            }
          }]
        },
        "_links": {
          "self": {
            "href": `${this.urlPrefix}/${this.namespace}/organization`
          },
          "profile": {
            "href": `${this.urlPrefix}/${this.namespace}/profile/organization`
          },
          "search": {
            "href": `${this.urlPrefix}/${this.namespace}/organization/search`
          }
        },
        "page": {
          "size": 20,
          "totalElements": 1,
          "totalPages": 1,
          "number": 0
        }
      };
    };

    // let sunstoneSettingMap = [{
    //   id: "1",
    //   name: 'Default Profile',
    //   description: 'Default Profile',
    //   orgId: 1,
    //   insertBy: 1,
    //   insertTime: '2016-10-31T00:00:00.000+0000',
    //   lastUpdateBy: 1,
    //   lastUpdateTime: '2016-10-31T00:00:00.000+0000',
    //   recordVersionNumber: 1,
    //   dataSource: '',
    //   type: 'LONG',
    //   defaultValue: '1',
    //   displayName: null,
    //   enumTypeId: null,
    //   _links: {
    //     self: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/1`
    //     },
    //     sunstoneSetting: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/1`
    //     }
    //   }
    // }, {
    //   id: "234",
    //   name: 'Theme',
    //   description: 'Theme',
    //   orgId: 1,
    //   insertBy: 1,
    //   insertTime: '2017-04-13T00:00:00.000+0000',
    //   lastUpdateBy: 1,
    //   lastUpdateTime: '2017-04-13T00:00:00.000+0000',
    //   recordVersionNumber: 1,
    //   dataSource: null,
    //   type: 'STRING',
    //   defaultValue: 'dark',
    //   displayName: null,
    //   enumTypeId: null,
    //   _links: {
    //     self: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/234`
    //     },
    //     sunstoneSetting: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/234`
    //     }
    //   }
    // },{
    //   id: "505",
    //   name: "Shared Profile",
    //   description: "Shared Profile",
    //   orgId: 1,
    //   insertBy: 352,
    //   insertTime: "2017-12-05T16:07:53.150+0000",
    //   lastUpdateBy: 352,
    //   lastUpdateTime: "2017-12-05T16:07:53.150+0000",
    //   recordVersionNumber: 1,
    //   dataSource: "trove_sdk",
    //   type: "LONG",
    //   defaultValue: null,
    //   displayName: null,
    //   enumTypeId: null,
    //   _links: {
    //     self: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/505`
    //     },
    //     sunstoneSetting: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/505`
    //     }
    //   }
    // }];

    // let sunstoneSetting = function(id) {
    // return A(sunstoneSettingMap).findBy('id', id);
    // let name = id === 1 ? 'Default Profile' : 'Theme';
    // let description = id === 1 ? 'Default Profile' : 'Theme';
    // let type = id == 1 ? 'LONG' : 'STRING';
    // let defaultValue = id == 1 ? '1' : 'dark';
    // return {
    //   id,
    //   name,
    //   description,
    //   orgId: 1,
    //   insertBy: 1,
    //   insertTime: '2016-10-31T00:00:00.000+0000',
    //   lastUpdateBy: 1,
    //   lastUpdateTime: '2016-10-31T00:00:00.000+0000',
    //   recordVersionNumber: 1,
    //   dataSource: '',
    //   type,
    //   defaultValue,
    //   displayName: null,
    //   enumTypeId: null,
    //   _links: {
    //     self: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/${id}`
    //     },
    //     sunstoneSetting: {
    //       href: `${this.urlPrefix}/${this.namespace}/sunstoneSettings/${id}`
    //     }
    //   }
    // }
    // };

    let user = function (id) {
      return {
        "id": id,
        "enabled": true,
        "accountExpired": false,
        "accountLocked": false,
        "pending": false,
        "username": "ss_user",
        "email": "noreply@trovedata.com",
        "lastLogin": null,
        "metadata": {
          "settings": [{
            "name": "Theme",
            "value": "dark"
          }, {
            "name": "Default Profile",
            "value": "1262"
          }, {
            "name": "Shared Profile",
            "value": "1"
          }, {
            "name": "Timezone",
            "value": "America/New_York"
          }]
        },
        "accountNonExpired": true,
        "accountNonLocked": true,
        "credentialsNonExpired": true,
        "_links": {
          "self": {
            "href": `${this.urlPrefix}/${this.namespace}/user/${id}`
          },
          "user": {
            "href": `${this.urlPrefix}/${this.namespace}/${id}{?projection}`,
            "templated": true
          },
          "roles": {
            "href": `${this.urlPrefix}/${this.namespace}/user/${id}/roles`
          }
        }
      };
    };
    let currentUser = function () {
      return {
        id: 352,
        orgId: 1,
        insertBy: 1,
        insertTime: 1458051918398,
        lastUpdateBy: 1,
        lastUpdateTime: 1458051918398,
        recordVersionNumber: 1,
        dataSource: null,
        username: 'ss_user',
        password: 'X6uJ6cYY33F0vMZkhsEFpA==',
        enabled: true,
        accountExpired: false,
        accountLocked: false,
        emailAddress: 'noreply@trovedata.com',
        lastLogin: 1492529122362,
        organization: '',
        roles: [{
          id: 5,
          orgId: 1,
          insertBy: 1,
          insertTime: 1458051917203,
          lastUpdateBy: 1,
          lastUpdateTime: 1458051917203,
          recordVersionNumber: 1,
          dataSource: null,
          permissions: [{
            id: 1,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051915964,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051915964,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'READ_PERMISSION'
          }],
          authority: 'ROLE_BASE'
        }, {
          id: 3,
          orgId: 1,
          insertBy: 1,
          insertTime: 1458051916799,
          lastUpdateBy: 1,
          lastUpdateTime: 1458051916799,
          recordVersionNumber: 1,
          dataSource: null,
          permissions: [{
            id: 1,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051915964,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051915964,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'READ_PERMISSION'
          }],
          authority: 'ROLE_DEVELOPERS'
        }, {
          id: 1,
          orgId: 1,
          insertBy: 1,
          insertTime: 1458051916342,
          lastUpdateBy: 1,
          lastUpdateTime: 1458051916342,
          recordVersionNumber: 1,
          dataSource: null,
          permissions: [{
            id: 1,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051915964,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051915964,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'READ_PERMISSION'
          }],
          authority: 'ROLE_MASTERS'
        }, {
          id: 2,
          orgId: 1,
          insertBy: 1,
          insertTime: 1458051916566,
          lastUpdateBy: 1,
          lastUpdateTime: 1458051916566,
          recordVersionNumber: 1,
          dataSource: null,
          permissions: [{
            id: 1,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051915964,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051915964,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'READ_PERMISSION'
          }, {
            id: 2,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051916155,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051916155,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'WRITE_PERMISSION'
          }],
          authority: 'ROLE_ADMINS'
        }, {
          id: 4,
          orgId: 1,
          insertBy: 1,
          insertTime: 1458051916987,
          lastUpdateBy: 1,
          lastUpdateTime: 1458051916987,
          recordVersionNumber: 1,
          dataSource: null,
          permissions: [{
            id: 1,
            orgId: 1,
            insertBy: 1,
            insertTime: 1458051915964,
            lastUpdateBy: 1,
            lastUpdateTime: 1458051915964,
            recordVersionNumber: 1,
            dataSource: null,
            name: 'READ_PERMISSION'
          }],
          authority: 'ROLE_USERS'
        }],
        envDisplayTimezone: 'America/Los_Angeles',
        graphiteAvailable: 'Y',
        authorities: ['ROLE_USERS', 'ROLE_BASE', 'ROLE_ADMINS', 'ROLE_MASTERS', 'ROLE_DEVELOPERS']
      };
    };
    let profiles = function (id) {
      return {
        id,
        name: 'Admin Profile',
        description: 'Admin Profile for internal use only',
        modDate: null,
        _links: {
          self: {
            href: `${this.urlPrefix}/${this.namespace}/profiles/${id}`
          },
          profile: {
            href: `${this.urlPrefix}/${this.namespace}/profiles/${id}`
          },
          datasources: {
            href: `${this.urlPrefix}/${this.namespace}/profiles/${id}/datasources`
          },
          categories: {
            href: `${this.urlPrefix}/${this.namespace}/profiles/${id}/categories`
          }
        }
      };
    };
  }
});