define("@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/component", ["exports", "@ember/object/computed", "@ember/object", "@ember/array", "@ember/component", "@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/template"], function (_exports, _computed, _object, _array, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    selectedItems: (0, _computed.alias)('select.selected'),
    selectedItemsText: (0, _object.computed)('selectedItems.[]', function () {
      let displayText = null;
      let selectedItems = (0, _array.A)((0, _object.get)(this, 'selectedItems'));
      if ((0, _object.get)(selectedItems, 'length')) {
        let searchField = (0, _object.get)(this, 'searchField');
        let value = searchField && (0, _object.get)(selectedItems, `firstObject`)?.[searchField] ? (0, _object.get)(selectedItems, `firstObject`)?.[searchField] : (0, _object.get)(selectedItems, 'firstObject');
        displayText = (0, _object.get)(selectedItems, 'length') === 1 ? value : `${(0, _object.get)(selectedItems, 'length')} items selected`;
      }
      return displayText;
    })
  });
});