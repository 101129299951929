define("@trovedata/sunstone-ui-commons/helpers/keys", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keys = keys;
  function keys(params) {
    return Object.keys(params[0]);
  }
  var _default = _exports.default = (0, _helper.helper)(keys);
});