define("ember-svg-jar/inlined/logo-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\" transform=\"matrix(.85333 0 0 -.85333 0 250.107)\"><path d=\"M90.453 236.237H68.35l-22.21-47.643 11.08-24z\" fill=\"#231F20\"/><path d=\"M57.22 164.595l-11.08 24-22.048 47.643H1.912l33.125-71.643z\" fill=\"#8cc63f\"/></g><g><path d=\"M121.21 23.629v-9.6h-5.29a23.935 23.935 0 00-3.518-8.42l3.763-3.763-6.787-6.787-3.768 3.763c-2.51-1.665-5.348-2.89-8.405-3.504v-5.29h-9.6v5.29c-3.063.62-5.9 1.844-8.415 3.51l-3.758-3.76-6.787 6.788 3.758 3.758a23.815 23.815 0 00-3.513 8.415H63.6v9.6h5.29a24.016 24.016 0 003.513 8.419l-3.758 3.763 6.792 6.783 3.753-3.764c2.52 1.67 5.362 2.895 8.42 3.51v5.294h9.6v-5.295a23.815 23.815 0 008.414-3.513l3.763 3.758 6.783-6.792-3.764-3.754a23.791 23.791 0 003.514-8.41h5.29zm-28.81 4.8c-5.304 0-9.6-4.301-9.6-9.6 0-5.3 4.296-9.6 9.6-9.6 5.3 0 9.6 4.3 9.6 9.6 0 5.294-4.3 9.6-9.6 9.6z\" fill=\"#757575\"/></g>",
    "attrs": {
      "viewBox": "0 0 124 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});