define("ember-svg-jar/inlined/business-graph-bar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M22 22V9a1 1 0 00-1-1h-4a1 1 0 00-1 1v13h-1v-7.001a1 1 0 00-1-1h-4a1 1 0 00-1 1V22H8v-9.001a1 1 0 00-1-1H3a1 1 0 00-1 1V22H0v2h24v-2h-2zm-4-12h2v11.999h-2V10zm-7 5.999h2v6h-2v-6zm-7-2h2v8H4v-8z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});