define("@trovedata/trove-scenario-planner/services/feature-flag", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FeatureFlag = _exports.default = (_class = class FeatureFlag extends _service.default {
    constructor(...args) {
      super(...args);
      // Controls whether he budget services are retreived and used for plan creation and some display
      _initializerDefineProperty(this, "budgetEnabled", _descriptor, this);
      // Whether the plan creation, cloning, and rollover features are enabled
      _initializerDefineProperty(this, "createPlanEnabled", _descriptor2, this);
      // Whether the custom Duke editor is available for plans, if false then the user can only add, remove, or exclude assets
      _initializerDefineProperty(this, "customPlannerEnabled", _descriptor3, this);
      // Defealt distribution tab for the legacy Duke distribution charts
      _initializerDefineProperty(this, "defaultDistTab", _descriptor4, this);
      // Whether the map should be shown by default on the grouping dashboard
      _initializerDefineProperty(this, "defaultGroupingMap", _descriptor5, this);
      // Whether the ability to edit a plan is available
      _initializerDefineProperty(this, "editPlanEnabled", _descriptor6, this);
      // Whethe rpublishing a plan is enabled
      _initializerDefineProperty(this, "publishPlanEnabled", _descriptor7, this);
      // Whether the legacy threat matrix driven charts are enabled on agg details view
      _initializerDefineProperty(this, "showAssetDetailThreatData", _descriptor8, this);
      // Whether the goruping dashboard map feature is enabled
      _initializerDefineProperty(this, "showGroupingDashboardMap", _descriptor9, this);
      // Whether projected cost stats are shown on the view explorer
      _initializerDefineProperty(this, "showProjectedStats", _descriptor10, this);
      // Whether to use legacy components or newer customized components that are configuration driven
      _initializerDefineProperty(this, "useCustomizedStatComponents", _descriptor11, this);
      // Whether the shared assets feature is enabled
      _initializerDefineProperty(this, "useSharedAssets", _descriptor12, this);
      // Whether the what if plan comparison feature is enabled
      _initializerDefineProperty(this, "whatIfEnabled", _descriptor13, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "budgetEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createPlanEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customPlannerEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "defaultDistTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'type';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "defaultGroupingMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editPlanEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "publishPlanEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showAssetDetailThreatData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showGroupingDashboardMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showProjectedStats", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "useCustomizedStatComponents", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "useSharedAssets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "whatIfEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class);
});