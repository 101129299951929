define("@trovedata/sunstone-ui-commons/components/async-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if hasBlock}}
    {{yield this.isPending this.text this.icon}}
  {{else}}
    {{#if (and this.isPending this.showSpinner)}}
      {{trove-icon this.icon spin=true}}
    {{/if}}
    {{this.text}}
  {{/if}}
  */
  {
    "id": "+nesZzZW",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"isPending\"]],[30,0,[\"text\"]],[30,0,[\"icon\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,0,[\"isPending\"]],[30,0,[\"showSpinner\"]]],null],[[[1,\"    \"],[1,[28,[35,4],[[30,0,[\"icon\"]]],[[\"spin\"],[true]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[30,0,[\"text\"]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"and\",\"trove-icon\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/async-button/template.hbs",
    "isStrictMode": false
  });
});