define("ember-svg-jar/inlined/pencil-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0-.001h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M21.081 2.919c-1.185-1.184-3.25-1.185-4.436 0L5.314 14.25c-.076.077-.14.164-.188.261l-3.021 6.043a.997.997 0 00.188 1.153.996.996 0 001.153.188l6.043-3.021a.965.965 0 00.26-.188L21.081 7.354c1.185-1.185 1.184-3.25 0-4.435zM8.45 17.157l-3.215 1.608 1.608-3.216 8.999-8.997 1.607 1.608-8.999 8.997zM19.667 5.942l-.803.804-1.607-1.608.804-.804a1.163 1.163 0 011.607 0 1.14 1.14 0 01-.001 1.608z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});