define("@trovedata/sunstone-ui-commons/components/sunstone-map-heatmap/component", ["exports", "@ember/array", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/sunstone-map-heatmap/template"], function (_exports, _array, _object, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global google */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    markers: (0, _array.A)(),
    _markers: (0, _array.A)(),
    heatmap: null,
    gradient: null,
    radius: 10,
    opacity: 1,
    didUpdateAttrs() {
      this._super(...arguments);
      if (this.heatmap) {
        this.heatmap.setMap(null);
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map) {
        (0, _object.set)(this, '_markers', (0, _array.A)([]));
        let bounds = new google.maps.LatLngBounds();
        this.markers.forEach(marker => {
          if (marker.get('latitude') && marker.get('longitude')) {
            let latlng = new google.maps.LatLng(marker.get('latitude'), marker.get('longitude'));
            this._markers.push(latlng);
            bounds.extend(latlng);
          }
        });
        this.map.fitBounds(bounds);
        (0, _object.set)(this, 'heatmap', new google.maps.visualization.HeatmapLayer({
          data: this._markers,
          map: this.map
        }));
        this.heatmap.set('gradient', this.gradient);
        this.heatmap.set('radius', this.radius);
        this.heatmap.set('opacity', this.opacity);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.heatmap) {
        this.heatmap.setMap(null);
      }
    }
  });
});