define("@trovedata/sunstone-ui-commons/utils/check-resource-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkResourceStatus;
  async function checkResourceStatus(path, environment, apps = [], appRoute) {
    let isActive = false;
    let response = {};
    path = `${location.origin}/${path}/`;
    try {
      response = await fetch(path);
    } catch (error) {
      isActive = false;
    }
    if (environment !== 'production' && Object.keys(apps).join('') === appRoute || response.status === 200 && response.url === path) {
      isActive = true;
    }
    return isActive;
  }
});