define("@trovedata/trove-scenario-planner/adapters/grouping-statistic", ["exports", "@trovedata/sunstone-ui-commons/adapters/application-json-api", "@trovedata/trove-scenario-planner/utils/service-utils"], function (_exports, _applicationJsonApi, _serviceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupingStatistic extends _applicationJsonApi.default {
    getFallbackNamespace() {
      return _serviceUtils.QUERY_SERVICE_NAMESPACE;
    }
    getTargetBackendServiceId() {
      return _serviceUtils.QUERY_SERVICE_ID;
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = GroupingStatistic;
});