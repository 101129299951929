define("@trovedata/sunstone-ui-commons/helpers/char-at", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.charAt = charAt;
  _exports.default = void 0;
  function charAt([index, str]) {
    if (typeof str !== 'string') {
      return undefined;
    }
    index = parseInt(index, 10);
    return str[index];
  }
  var _default = _exports.default = (0, _helper.helper)(charAt);
});