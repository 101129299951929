define("@trovedata/trove-scenario-planner/components/outage-chart/component", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="outage-chart layout-column layout-padding-none flex"
    ...attributes
  >
    {{#if @isLoading}}
      <div class="layout-row flex layout-align-center-center">
        <TroveSpinner @accent={{true}} @diameter={{60}} />
      </div>
    {{else}}
      <TroveLabel
        class="layout-margin-bottom-md"
        @bold={{true}}
        @label="Outage Metrics"
        @size={{16}}
      />
  
      {{#if @outageChart.isAvailable}}
        <TroveChart
          class="layout-padding-none"
          @chartOptions={{hash
            chart=(hash spacingBottom=8 spacingLeft=0 type="areaspline")
            legend=(hash
              enabled=true
              itemStyle=(hash fontSize="10px")
              padding=0
            )
            plotOptions=(hash
              areaspline=(hash fillOpacity=0.2)
              colorByPoint=true
              column=(hash animation=false)
            )
            title=(hash text=null)
            tooltip=(hash
              backgroundColor="rgba(255,255,255,0)"
              borderWidth=0
              formatter=@outageChart.tooltipFormatter
              shadow=false
              shared=true
              useHTML=true
            )
            xAxis=(hash
              categories=@outageChart.categories
              labels=(hash
                enabled=true
                formatter=@outageChart.xAxisFormatter
              )
            )
            yAxis=(hash
              labels=(hash enabled=true)
              title=(hash text="Count")
            )
          }}
          @content={{@outageChart.chartData}}
        />
      {{else}}
        <IconMessage @icon="business-graph-plot" @message="No Outage Data" />
      {{/if}}
    {{/if}}
  </div>
  
  */
  {
    "id": "rEgbAeQa",
    "block": "[[[11,0],[24,0,\"outage-chart layout-column layout-padding-none flex\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"layout-row flex layout-align-center-center\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@accent\",\"@diameter\"],[true,60]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"layout-margin-bottom-md\"]],[[\"@bold\",\"@label\",\"@size\"],[true,\"Outage Metrics\",16]],null],[1,\"\\n\\n\"],[41,[30,3,[\"isAvailable\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"layout-padding-none\"]],[[\"@chartOptions\",\"@content\"],[[28,[37,4],null,[[\"chart\",\"legend\",\"plotOptions\",\"title\",\"tooltip\",\"xAxis\",\"yAxis\"],[[28,[37,4],null,[[\"spacingBottom\",\"spacingLeft\",\"type\"],[8,0,\"areaspline\"]]],[28,[37,4],null,[[\"enabled\",\"itemStyle\",\"padding\"],[true,[28,[37,4],null,[[\"fontSize\"],[\"10px\"]]],0]]],[28,[37,4],null,[[\"areaspline\",\"colorByPoint\",\"column\"],[[28,[37,4],null,[[\"fillOpacity\"],[0.2]]],true,[28,[37,4],null,[[\"animation\"],[false]]]]]],[28,[37,4],null,[[\"text\"],[null]]],[28,[37,4],null,[[\"backgroundColor\",\"borderWidth\",\"formatter\",\"shadow\",\"shared\",\"useHTML\"],[\"rgba(255,255,255,0)\",0,[30,3,[\"tooltipFormatter\"]],false,true,true]]],[28,[37,4],null,[[\"categories\",\"labels\"],[[30,3,[\"categories\"]],[28,[37,4],null,[[\"enabled\",\"formatter\"],[true,[30,3,[\"xAxisFormatter\"]]]]]]]],[28,[37,4],null,[[\"labels\",\"title\"],[[28,[37,4],null,[[\"enabled\"],[true]]],[28,[37,4],null,[[\"text\"],[\"Count\"]]]]]]]]],[30,3,[\"chartData\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,5],null,[[\"@icon\",\"@message\"],[\"business-graph-plot\",\"No Outage Data\"]],null],[1,\"\\n\"]],[]]]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@isLoading\",\"@outageChart\"],false,[\"if\",\"trove-spinner\",\"trove-label\",\"trove-chart\",\"hash\",\"icon-message\"]]",
    "moduleName": "@trovedata/trove-scenario-planner/components/outage-chart/component.hbs",
    "isStrictMode": false
  });
  class OutageChart extends _component2.default {}
  _exports.default = OutageChart;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OutageChart);
});