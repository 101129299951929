define("ember-svg-jar/inlined/synthesis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g><g transform=\"translate(0 80)\"><defs><path id=\"SVGID_1_\" d=\"M75 0h319.1v319H75z\"/></defs><clipPath id=\"SVGID_2_\"><use xlink:href=\"#SVGID_1_\" overflow=\"visible\"/></clipPath><path class=\"st0\" d=\"M148.2 31.9c11 0 21.4 4.3 29.2 12.1l93 93 22.5-22.5-93-93c-28.6-28.6-75-28.6-103.6 0-28.6 28.6-28.6 75 0 103.6l11.9 11.9 22.5-22.5-11.7-12c-16.1-16.1-16.1-42.3 0-58.4 7.8-7.9 18.1-12.2 29.2-12.2m224.4 162.2l-11.9-11.9-22.6 22.5 12 11.9c16.1 16.1 16.1 42.3 0 58.4-7.8 7.8-18.2 12.1-29.2 12.1s-21.4-4.3-29.2-12.1l-93-93-22.5 22.6 93 93c28.6 28.6 75 28.6 103.6 0 28.4-28.5 28.4-74.9-.2-103.5\"/></g><g transform=\"translate(0 80)\"><defs><path id=\"SVGID_3_\" d=\"M75 0h319.1v319H75z\"/></defs><clipPath id=\"SVGID_4_\"><use xlink:href=\"#SVGID_3_\" overflow=\"visible\"/></clipPath><path class=\"st1\" d=\"M212 123.7l-22.6-22.5-93 93c-28.6 28.6-28.6 75 0 103.6 28.6 28.6 75 28.6 103.6 0l11.9-11.9-22.5-22.5-11.9 11.9c-7.8 7.8-18.2 12.1-29.2 12.1s-21.4-4.3-29.2-12.1c-16.1-16.1-16.1-42.3 0-58.5l92.9-93.1M372.6 21.4c-28.6-28.6-75-28.6-103.6 0l-11.9 11.9 22.6 22.5L291.6 44c7.8-7.8 18.2-12.1 29.2-12.1S342.2 36.2 350 44c7.8 7.8 12.1 18.2 12.1 29.2s-4.3 21.4-12.1 29.2l-93 93 22.6 22.5 93-93c28.6-28.5 28.6-74.8 0-103.5\"/></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 469 469",
      "xml:space": "preserve"
    }
  };
});