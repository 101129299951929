define("ember-svg-jar/inlined/synchronize-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M13 6.08c3.191.465 5.738 3.104 5.98 6.398.013.172.02.346.02.522h2c0-.226-.009-.449-.025-.669-.319-4.335-3.737-7.793-7.975-8.269V1L9 5l4 4V6.08zM5.504 15.596A7.007 7.007 0 015 13a7.03 7.03 0 013.5-6.064L7.498 5.204A9.036 9.036 0 003 13c0 1.245.268 2.47.762 3.602l-2.654 1.532 5.465 1.464 1.463-5.464-2.532 1.462zM21.893 19.866l-1.465-5.464-5.464 1.464 2.525 1.458A6.975 6.975 0 0112 20a6.926 6.926 0 01-3.172-.758l-.907 1.782A8.892 8.892 0 0012 22a8.975 8.975 0 007.242-3.664l2.651 1.53z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});