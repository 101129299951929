define("ember-basic-dropdown/components/basic-dropdown-trigger", ["exports", "@glimmer/component", "@ember/object", "ember-basic-dropdown/utils/has-moved"], function (_exports, _component, _object, _hasMoved) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let BasicDropdownTrigger = _exports.default = (_class = class BasicDropdownTrigger extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "toggleIsBeingHandledByTouchEvents", false);
      _defineProperty(this, "touchMoveEvent", void 0);
    }
    // Actions
    /**
     * Allows similair behaviour to `ember-composable-helpers`' `optional` helper.
     * Avoids adding extra dependencies.
     * Can be removed when the template `V1` compatability event handlers are removed.
     *
     * @see https://github.com/cibernox/ember-basic-dropdown/issues/498
     * @memberof BasicDropdownContent
     */
    noop() {}
    handleMouseDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }
      if (this.args.eventType !== 'mousedown' || e.button !== 0) return;
      if (this.args.stopPropagation) {
        e.stopPropagation();
      }
      this._stopTextSelectionUntilMouseup();
      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }
      this.args.dropdown.actions.toggle(e);
    }
    handleClick(e) {
      if (typeof document === 'undefined') return;
      if (this.isDestroyed || !this.args.dropdown || this.args.dropdown.disabled) {
        return;
      }
      if (this.args.eventType !== undefined && this.args.eventType !== 'click' || e.button !== 0) return;
      if (this.args.stopPropagation) {
        e.stopPropagation();
      }
      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }
      this.args.dropdown.actions.toggle(e);
    }
    handleKeyDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }
      if (e.keyCode === 13) {
        // Enter
        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 32) {
        // Space
        e.preventDefault(); // prevents the space to trigger a scroll page-next
        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 27) {
        this.args.dropdown.actions.close(e);
      }
    }
    handleTouchStart() {
      document.addEventListener('touchmove', this._touchMoveHandler);
    }
    handleTouchEnd(e) {
      this.toggleIsBeingHandledByTouchEvents = true;
      if (e && e.defaultPrevented || this.args.dropdown.disabled) {
        return;
      }
      if (!(0, _hasMoved.default)(e, this.touchMoveEvent)) {
        this.args.dropdown.actions.toggle(e);
      }
      this.touchMoveEvent = undefined;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      // This next three lines are stolen from hammertime. This prevents the default
      // behaviour of the touchend, but synthetically trigger a focus and a (delayed) click
      // to simulate natural behaviour.
      let target = e.target;
      if (target !== null) {
        target.focus();
      }
      setTimeout(function () {
        if (!e.target) {
          return;
        }
        try {
          let event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          e.target.dispatchEvent(event);
        } catch (e) {
          event = new Event('click');
          e.target.dispatchEvent(event);
        }
      }, 0);
      e.preventDefault();
    }
    removeGlobalHandlers() {
      if (typeof document === 'undefined') return;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      document.removeEventListener('mouseup', this._mouseupHandler, true);
    }
    _mouseupHandler() {
      document.removeEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.remove('ember-basic-dropdown-text-select-disabled');
    }
    _touchMoveHandler(e) {
      this.touchMoveEvent = e;
      document.removeEventListener('touchmove', this._touchMoveHandler);
    }

    // Methods
    _stopTextSelectionUntilMouseup() {
      document.addEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.add('ember-basic-dropdown-text-select-disabled');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleMouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeGlobalHandlers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeGlobalHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_mouseupHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_mouseupHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_touchMoveHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_touchMoveHandler"), _class.prototype)), _class);
});