define("@trovedata/sunstone-ui-commons/components/login-form/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/login-form/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    username: '',
    password: '',
    logoPath: '@trovedata/sunstone-ui-commons/images/esource/ES-logo-color-tagline.png'
  });
});