define("@trovedata/sunstone-ui-commons/helpers/hash-obj", ["exports", "@ember/component/helper", "@ember/polyfills"], function (_exports, _helper, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hashObj = hashObj;
  function hashObj(params, hash) {
    let obj = {};
    (0, _polyfills.assign)(obj, hash);
    return obj;
  }

  // temporary until Highcharts gets it together
  // see https://github.com/emberjs/ember.js/issues/14668
  var _default = _exports.default = (0, _helper.helper)(hashObj);
});