define("@trovedata/sunstone-ui-commons/helpers/date", ["exports", "@ember/component/helper", "@ember/object", "@ember/service", "moment"], function (_exports, _helper, _object, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = _helper.default.extend({
    sunstoneEnv: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    compute([date], {
      format
    }) {
      let timezone = (0, _object.get)(this, 'sunstoneEnv.timezone');
      if (!date) {
        return undefined;
      }
      date = (0, _moment.default)(date);

      // babel does not allow 'default' in the destructured arguments, for some reason
      format = format || 'default';
      format = `date_${format}`;
      return this.intl.formatDate(date.toISOString(), {
        format: format,
        timeZone: timezone
      });
    },
    settingsChanged: (0, _object.observer)('sunstoneEnv.timezone', 'intl.locale', function () {
      this.recompute();
    })
  });
});