define("@trovedata/sunstone-ui-commons/models/fuse-job", ["exports", "@trovedata/sunstone-ui-commons/models/model-instance", "ember-data", "@ember/service", "ember-concurrency-decorators", "@ember/object", "@ember/object/computed"], function (_exports, _modelInstance, _emberData, _service, _emberConcurrencyDecorators, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    hasMany
  } = _emberData.default;
  let FuseJob = _exports.default = (_dec = hasMany('fuse-session', {
    async: true
  }), _dec2 = (0, _computed.alias)('modelSessions'), _dec3 = (0, _object.computed)('id'), (_class = class FuseJob extends _modelInstance.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "troveFetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "modelSessions", _descriptor3, this);
      _initializerDefineProperty(this, "sessions", _descriptor4, this);
    }
    get modelSessionMetadata() {
      //@ts-ignore
      return this.getLatestModelSessionWithMetadata.perform();
    }
    set modelSessionMetadata(value) {
      //@ts-ignore
      return value;
    }
    *getLatestModelSessionWithMetadata() {
      let id = this.get('id');
      if (id) {
        let lastSessionAndMetadata = yield this.troveFetch.request(`api/modelInstance/${id}/findLastSessionWithMetaData`, {
          namespace: 'trove-attribute-fusion'
        });
        let sessionSerializer = this.store.serializerFor('fuse-session');
        let sessionModel = this.store.modelFor('fuse-session');
        let {
          lastError
        } = lastSessionAndMetadata;
        let {
          modelSession
        } = lastSessionAndMetadata;
        if (lastError) {
          let lastErrorId = lastError.id;
          // @ts-ignore
          lastError = sessionSerializer.normalizeResponse(this.store, sessionModel, lastError, lastErrorId);
          lastError = this.store.push(lastError);
        }
        if (modelSession) {
          let lastSessionId = modelSession.id;
          // @ts-ignore
          modelSession = sessionSerializer.normalizeResponse(this.store, sessionModel, modelSession, lastSessionId);
          modelSession = this.store.push(modelSession);
        }
        return {
          lastError,
          lastSession: modelSession,
          matchCount: lastSessionAndMetadata.matchCount,
          totalCount: lastSessionAndMetadata.totalCount
        };
      } else {
        return undefined;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modelSessions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "modelSessionMetadata", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "modelSessionMetadata"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getLatestModelSessionWithMetadata", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getLatestModelSessionWithMetadata"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});