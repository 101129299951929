define("@trovedata/sunstone-ui-commons/models/active-indicator-detail", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    activeIndicator: (0, _model.attr)('yn-boolean')
  });
});