define("@trovedata/sunstone-ui-commons/helpers/number", ["exports", "@ember/utils", "@ember/object", "@ember/service", "@ember/component/helper"], function (_exports, _utils, _object, _service, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = _helper.default.extend({
    intl: (0, _service.inject)(),
    compute([value], {
      precision = 2,
      shorten = true,
      shortenPrecision = 2
    }) {
      if ((0, _utils.isEmpty)(value)) {
        return undefined;
      }
      let abbrs = ['b', 'm', 'k'];
      let thresholds = [1000000000, 1000000, 1000];
      let divisors = [1000000000, 1000000, 1000];
      let selectedAbbr = '';
      if (shorten) {
        for (let index = 0; index < thresholds.length; index++) {
          if (value >= thresholds[index]) {
            value = value / divisors[index];
            selectedAbbr = abbrs[index];
            break;
          }
        }
      }
      value = this.intl.formatNumber(value, {
        maximumFractionDigits: !!selectedAbbr ? shortenPrecision : precision
      });
      return `${value}${selectedAbbr}`;
    },
    localeChanged: (0, _object.observer)('intl.locale', function () {
      this.recompute();
    })
  });
});