define("@trovedata/trove-scenario-planner/components/distribution-bar/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/template", "@trovedata/trove-scenario-planner/utils/tooltip", "@trovedata/trove-scenario-planner/components/distribution-bar/template"], function (_exports, _component, _component2, _object, _template, _tooltip, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DistributionBar = _exports.default = (_dec = (0, _component.layout)(_template2.default), _dec2 = (0, _component.tagName)('distribution-bar'), _dec3 = (0, _component.className)('inactive'), _dec4 = (0, _component.className)('md-lines'), _dec5 = (0, _object.computed)('pieces.[]'), _dec6 = (0, _object.computed)('pieces.[]', 'piecesTotal', 'totalOverride'), _dec7 = (0, _object.computed)('lines.[]', 'piecesTotal', 'totalOverride'), _dec(_class = _dec2(_class = (_class2 = class DistributionBar extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "popperOptions", _tooltip.POPPER_OPTIONS);
      _defineProperty(this, "pieces", []);
      _defineProperty(this, "lines", []);
      _initializerDefineProperty(this, "isInactive", _descriptor, this);
      _initializerDefineProperty(this, "showLines", _descriptor2, this);
      _defineProperty(this, "totalOverride", void 0);
    }
    get piecesTotal() {
      return this.pieces.reduce((total, piece) => {
        total += piece.value;
        return total;
      }, 0);
    }
    get temptablePieces() {
      const denominator = this.totalOverride ? this.totalOverride : this.piecesTotal;
      let piecesTotal = 0;
      return this.pieces?.map(piece => {
        const newPiecesTotal = piecesTotal + piece.value;
        let numerator = 0;
        if (piecesTotal < denominator) {
          numerator = newPiecesTotal <= denominator ? piece.value : denominator - piecesTotal;
        }
        piecesTotal = newPiecesTotal;
        return {
          style: (0, _template.htmlSafe)(`
          width: ${numerator / denominator * 100}%;
          background: ${piece.color}
        `),
          ...piece
        };
      });
    }
    get templateLines() {
      return this.lines?.map(line => {
        return {
          style: (0, _template.htmlSafe)(`
          left: ${line.value / (this.totalOverride ? this.totalOverride : this.piecesTotal) * 100}%;
          background: ${line.color}
        `),
          ...line
        };
      });
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      if (this.totalOverride !== undefined) {
        // Set as inactive if value is 0 or null
        (0, _object.set)(this, 'isInactive', !!!this.totalOverride);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isInactive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showLines", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "piecesTotal", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "piecesTotal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "temptablePieces", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "temptablePieces"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateLines", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "templateLines"), _class2.prototype)), _class2)) || _class) || _class);
});