define("ember-in-viewport/services/in-viewport", ["exports", "@ember/service", "@ember/object", "@ember/polyfills", "@ember/application", "@ember/runloop", "ember-in-viewport/utils/is-in-viewport", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/-private/observer-admin", "ember-in-viewport/-private/raf-admin"], function (_exports, _service, _object, _polyfills, _application, _runloop, _isInViewport, _canUseRaf, _canUseIntersectionObserver, _observerAdmin, _rafAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noop = () => {};

  /**
   * ensure use on requestAnimationFrame, no matter how many components
   * on the page are using this mixin
   *
   * @class InViewport
   * @module Ember.Service
   */
  class InViewport extends _service.default {
    init() {
      super.init(...arguments);
      (0, _object.set)(this, 'registry', new WeakMap());
      let options = (0, _polyfills.assign)({
        viewportUseRAF: (0, _canUseRaf.default)()
      }, this._buildOptions());

      // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)
      options = (0, _polyfills.assign)(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      (0, _object.setProperties)(this, options);
    }
    startIntersectionObserver() {
      this.observerAdmin = new _observerAdmin.default();
    }
    startRAF() {
      this.rafAdmin = new _rafAdmin.default();
    }

    /** Any strategy **/

    /**
     * @method watchElement
     * @param HTMLElement element
     * @param Object configOptions
     * @param Function enterCallback - support mixin approach
     * @param Function exitCallback - support mixin approach
     * @void
     */
    watchElement(element, configOptions = {}, enterCallback, exitCallback) {
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        if (!(0, _object.get)(this, 'observerAdmin')) {
          this.startIntersectionObserver();
        }
        const observerOptions = this.buildObserverOptions(configOptions);
        (0, _runloop.scheduleOnce)('afterRender', this, () => {
          // create IntersectionObserver instance or add to existing
          this.setupIntersectionObserver(element, observerOptions, enterCallback, exitCallback);
        });
      } else {
        if (!(0, _object.get)(this, 'rafAdmin')) {
          this.startRAF();
        }
        (0, _runloop.scheduleOnce)('afterRender', this, () => {
          // grab the user added callbacks when we enter/leave the element
          const {
            enterCallback = noop,
            exitCallback = noop
          } = this.getCallbacks(element) || {};
          // this isn't using the same functions as the mixin case, but that is b/c it is a bit harder to unwind.
          // So just rewrote it with pure functions for now
          (0, _rafAdmin.startRAF)(element, configOptions, enterCallback, exitCallback, this.addRAF.bind(this, element.id), this.removeRAF.bind(this, element.id));
        });
      }
      return {
        onEnter: this.addEnterCallback.bind(this, element),
        onExit: this.addExitCallback.bind(this, element)
      };
    }

    /**
     * @method addEnterCallback
     * @void
     */
    addEnterCallback(element, enterCallback) {
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        this.observerAdmin.addEnterCallback(element, enterCallback);
      } else {
        this.rafAdmin.addEnterCallback(element, enterCallback);
      }
    }

    /**
     * @method addExitCallback
     * @void
     */
    addExitCallback(element, exitCallback) {
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        this.observerAdmin.addExitCallback(element, exitCallback);
      } else {
        this.rafAdmin.addExitCallback(element, exitCallback);
      }
    }
    getCallbacks(target) {
      return (0, _object.get)(this, 'rafAdmin').getCallbacks(target);
    }

    /** IntersectionObserver **/

    /**
     * In order to track elements and the state that comes with them, we need to keep track
     * of them in order to get at them at a later time
     *
     * @method addToRegistry
     * @void
     */
    addToRegistry(element, observerOptions) {
      (0, _object.get)(this, 'registry').set(element, {
        observerOptions
      });
    }

    /**
     * @method setupIntersectionObserver
     * @param HTMLElement element
     * @param Object observerOptions
     * @param Function enterCallback
     * @param Function exitCallback
     * @void
     */
    setupIntersectionObserver(element, observerOptions, enterCallback, exitCallback) {
      this.addToRegistry(element, observerOptions);
      (0, _object.get)(this, 'observerAdmin').add(element, observerOptions, enterCallback, exitCallback);
    }
    buildObserverOptions({
      intersectionThreshold = 0,
      scrollableArea = null,
      viewportTolerance = {}
    }) {
      const domScrollableArea = scrollableArea ? document.querySelector(scrollableArea) : undefined;

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // IntersectionObserver takes either a Document Element or null for `root`
      const {
        top = 0,
        left = 0,
        bottom = 0,
        right = 0
      } = viewportTolerance;
      return {
        root: domScrollableArea,
        rootMargin: `${top}px ${right}px ${bottom}px ${left}px`,
        threshold: intersectionThreshold
      };
    }
    unobserveIntersectionObserver(target) {
      if (!target) {
        return;
      }
      const registeredTarget = (0, _object.get)(this, 'registry').get(target);
      if (typeof registeredTarget === 'object') {
        (0, _object.get)(this, 'observerAdmin').unobserve(target, registeredTarget.observerOptions, registeredTarget.scrollableArea);
      }
    }

    /** RAF **/

    addRAF(elementId, fn) {
      (0, _object.get)(this, 'rafAdmin').add(elementId, fn);
    }
    removeRAF(elementId) {
      if ((0, _object.get)(this, 'rafAdmin')) {
        (0, _object.get)(this, 'rafAdmin').remove(elementId);
      }
    }
    isInViewport(...args) {
      return (0, _isInViewport.default)(...args);
    }

    /** other **/
    stopWatching(target) {
      if ((0, _object.get)(this, 'observerAdmin')) {
        this.unobserveIntersectionObserver(target);
      }
      if ((0, _object.get)(this, 'rafAdmin')) {
        this.removeRAF(target);
      }
    }
    destroy() {
      (0, _object.set)(this, 'registry', null);
      if ((0, _object.get)(this, 'observerAdmin')) {
        (0, _object.get)(this, 'observerAdmin').destroy();
      }
      if ((0, _object.get)(this, 'rafAdmin')) {
        (0, _object.get)(this, 'rafAdmin').reset();
      }
    }
    _buildOptions(defaultOptions = {}) {
      const owner = (0, _application.getOwner)(this);
      if (owner) {
        return (0, _polyfills.assign)(defaultOptions, owner.lookup('config:in-viewport'));
      }
    }
  }
  _exports.default = InViewport;
});