define("@trovedata/sunstone-ui-commons/components/sunstone-draggable-dropzone/component", ["exports", "@ember/component", "@ember/object", "@trovedata/sunstone-ui-commons/components/sunstone-draggable-dropzone/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _component, _object, _template, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _exports.default = _component.default.extend(_troveColorMixin.default, {
    layout: _template.default,
    classNames: ['draggableDropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    dragLeave(event) {
      event.preventDefault();
      (0, _object.set)(this, 'dragClass', 'deactivated');
    },
    dragOver(event) {
      event.preventDefault();
      (0, _object.set)(this, 'dragClass', 'activated');
    },
    drop(event) {
      let data = event.dataTransfer.getData('text/data');
      (0, _object.set)(this, 'dragClass', 'deactivated');
      return this?.dropped(data);
    }
  });
});