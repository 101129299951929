define("@trovedata/trove-scenario-planner/utils/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.POPPER_OPTIONS = void 0;
  const POPPER_OPTIONS = _exports.POPPER_OPTIONS = {
    modifiers: {
      preventOverflow: {
        escapeWithReference: false
      }
    }
  };
});