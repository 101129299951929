define("ember-paper/components/paper-select/ebd-content/component", ["exports", "@ember/component", "ember-paper/components/paper-select/ebd-content/template", "@ember-decorators/component", "@ember/object", "@ember/runloop", "ember-css-transitions/utils/transition-utils", "ember-paper/utils/key-constants", "ember-power-select/utils/group-utils", "@ember/application", "ember-paper/utils/ebd-get-parent"], function (_exports, _component, _template, _component2, _object, _runloop, _transitionUtils, _keyConstants, _groupUtils, _application, _ebdGetParent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function waitForAnimations(element, callback) {
    let computedStyle = window.getComputedStyle(element);
    if (computedStyle.transitionDuration && computedStyle.transitionDuration !== '0s') {
      let eventCallback = function () {
        element.removeEventListener('transitionend', eventCallback);
        callback();
      };
      element.addEventListener('transitionend', eventCallback);
    } else if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
      let eventCallback = function () {
        element.removeEventListener('animationend', eventCallback);
        callback();
      };
      element.addEventListener('animationend', eventCallback);
    } else {
      callback();
    }
  }
  let PaperSelectEbdContent = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('otherStyles', 'isActive'), _dec4 = (0, _object.computed)('destination'), _dec(_class = _dec2(_class = (_class2 = class PaperSelectEbdContent extends _component.default {
    get customStyles() {
      if (this.isActive) {
        return {};
      } else {
        return this.otherStyles;
      }
    }
    get destinationElement() {
      return document.getElementById(this.destination);
    }
    animateIn(dropdownElement) {
      (0, _runloop.next)(() => {
        (0, _runloop.scheduleOnce)('afterRender', this, this.afterAnimateIn, dropdownElement);
      });
    }
    afterAnimateIn(dropdownElement) {
      this.dropdown.actions.reposition();
      this.set('isActive', true);
      this.focusItem(dropdownElement);
    }
    async animateOut(dropdownElement) {
      let parentElement = this.renderInPlace ? dropdownElement.parentElement.parentElement : dropdownElement.parentElement;

      // workaround for https://github.com/miguelcobain/ember-paper/issues/1166
      if (!parentElement) {
        parentElement = (0, _ebdGetParent.default)((0, _application.getOwner)(this));
      }
      let clone = dropdownElement.cloneNode(true);
      clone.id = `${clone.id}--clone`;
      parentElement.appendChild(clone);
      clone.children[0].children[0].scrollTop = dropdownElement.children[0].children[0].scrollTop;
      await (0, _transitionUtils.nextTick)();
      if (!this.isDestroyed) {
        this.set('isActive', false);
        clone.classList.add('md-leave');
        waitForAnimations(clone, function () {
          clone.classList.remove('md-active');
          parentElement.removeChild(clone);
        });
      } else {
        parentElement.removeChild(clone);
      }
    }
    focusItem(element) {
      let focusTarget = element.querySelector('md-option[aria-selected="true"]');

      // default to first not disabled option
      if (!focusTarget) {
        focusTarget = element.querySelector('md-option:not([aria-disabled="true"])');
      }
      if (focusTarget) {
        focusTarget.focus();
      }
    }
    handleKeyDown(ev) {
      switch (ev.which) {
        case _keyConstants.ESCAPE:
          {
            this.dropdown.actions.close();
            break;
          }
        case _keyConstants.LEFT_ARROW:
        case _keyConstants.UP_ARROW:
          {
            ev.preventDefault();
            let newHighlighted = (0, _groupUtils.advanceSelectableOption)(this.select.results, this.select.highlighted, -1);
            this.select.actions.highlight(newHighlighted, ev);
            this.select.actions.scrollTo(newHighlighted);
            this.focusOption(ev, -1);
            break;
          }
        case _keyConstants.RIGHT_ARROW:
        case _keyConstants.DOWN_ARROW:
          {
            ev.preventDefault();
            let newHighlighted = (0, _groupUtils.advanceSelectableOption)(this.select.results, this.select.highlighted, 1);
            this.select.actions.highlight(newHighlighted, ev);
            this.select.actions.scrollTo(newHighlighted);
            this.focusOption(ev, 1);
            break;
          }
        case _keyConstants.ENTER:
          {
            ev.preventDefault();
            this.select.actions.choose(this.select.highlighted);
            break;
          }
      }
    }
    focusOption(e, direction) {
      let focusTarget = e.target.closest('md-option');
      do {
        if (direction > 0) {
          focusTarget = focusTarget.nextElementSibling;
        } else {
          focusTarget = focusTarget.previousElementSibling;
        }
      } while (focusTarget && !isFocusable(focusTarget));
      if (focusTarget) {
        focusTarget.focus();
      }
    }
    shouldReposition(mutations) {
      let shouldReposition = false;
      shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      return shouldReposition;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "customStyles", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "customStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateIn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "animateIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateOut", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "animateOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusItem", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "focusItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype)), _class2)) || _class) || _class);
  function isFocusable(el) {
    // is a menu-item, doesn't have tabindex -1 and is not disabled
    return el && el.tagName === 'MD-OPTION' && el.getAttribute('tabindex') !== -1 && el.getAttribute('disabled') === null && el.getAttribute('aria-disabled') !== true;
  }
  var _default = _exports.default = PaperSelectEbdContent;
});