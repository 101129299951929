define("ember-svg-jar/inlined/subtract-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Filled_Icons\"><path fill=\"#757575\" d=\"M21 14a1 1 0 01-1 1H4a1 1 0 01-1-1v-4a1 1 0 011-1h16a1 1 0 011 1v4z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});