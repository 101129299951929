define("@trovedata/sunstone-ui-commons/serializers/enum", ["exports", "@ember/object", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    normalizeResponse(store, typeClass, payload, id, requestType) {
      if (requestType === 'queryRecord') {
        payload = payload._embedded.enum[0];
      }
      return this._super(store, typeClass, payload, id, requestType);
    },
    serialize(snapshot) {
      let json = {
        id: snapshot.id
      };
      snapshot.eachAttribute(key => {
        (0, _object.set)(json, key, snapshot.attr(key));
      });
      snapshot.eachRelationship((key, relationship) => {
        if (relationship.kind === 'hasMany') {
          let enumValues = snapshot.hasMany(key);
          if (enumValues) {
            enumValues = enumValues.map(item => {
              let adapter = this.store.adapterFor(item.modelName);
              return adapter.buildURL(item.modelName, item.id);
            });
            (0, _object.set)(json, 'enumValues', enumValues);
          }
        }
      });
      return json;
    }
  });
});