define("ember-svg-jar/inlined/sign-new-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M10.666 14.668h2v-1.333h-2a.668.668 0 01-.666-.667h2v-1.333h-2c0-.368.299-.667.666-.667h2V9.335h-2c-1.103 0-2 .896-2 2v1.333c0 1.103.897 2 2 2zM5.333 12.203l1.445 2.168A.668.668 0 008 14V9.335H6.666v2.466L5.221 9.633a.664.664 0 00-.748-.27.665.665 0 00-.474.637v4.668h1.334v-2.465zM14.652 14.668h1.362L16 14.615l.656-2.603.653 2.656h1.371l-.014-.053L20 9.335h-1.372l-.638 2.601-.657-2.601H15.961l-.638 2.601-.657-2.601h-1.333l1.309 5.333z\"/><path d=\"M22 6H2C.896 6 0 6.898 0 8v8c0 1.103.896 2 2 2h20c1.104 0 2-.897 2-2V8c0-1.102-.896-2-2-2zm-.003 10H2V8h20l-.003 8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});