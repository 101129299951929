define("@trovedata/sunstone-ui-commons/helpers/number-abbr", ["exports", "@ember/component/helper", "@ember/application/deprecations", "@ember/service"], function (_exports, _helper, _deprecations, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    intl: (0, _service.inject)(),
    compute([value]) {
      (false && !(false) && (0, _deprecations.deprecate)('[helper:number-abbr] The number-abbr helper will no longer be supported. Please update your app to use `helper:number`', false, {
        id: 'sunstone-ui-commons.number-abbr',
        until: 'sunstone-ui-commons-3.0'
      }));
      let abbrs = ['k', 'm', 'b'];
      let thresholds = [100000, 1000000, 1000000000];
      let divisors = [1000, 1000000, 1000000000];
      let abbrValue = value;
      let selectedAbbr = '';
      thresholds.forEach((threshold, index) => {
        if (value >= threshold) {
          abbrValue = (value / divisors[index]).toFixed(2);
          selectedAbbr = abbrs[index];
        }
      });
      return this.intl.formatNumber(abbrValue) + selectedAbbr;
    }
  });
});