define("@trovedata/sunstone-ui-commons/helpers/display-time", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    compute([seconds], {
      day = false,
      hour = false,
      minute = false
    }) {
      if (!seconds) {
        return undefined;
      }
      let response = '~';
      let allFalse = !day && !hour && !minute;
      // calculate (and subtract) whole days
      if (allFalse || day) {
        const days = Math.floor(seconds / 86400);
        seconds -= days * 86400;
        if (days === 1) {
          response = `${response} ${days} day`;
        } else if (days <= 1 && day && !hour && !minute) {
          response = `< 1 day`;
        } else {
          response = days ? `${response} ${days} days` : response;
        }
      }

      // calculate (and subtract) whole hours
      if (allFalse || hour) {
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        if (hours === 1) {
          response = `${response} ${hours} hour`;
        } else if (hours <= 1 && !day && hour && !minute) {
          response = `< 1 hour`;
        } else {
          response = hours ? `${response} ${hours} hours` : response;
        }
      }

      // calculate (and subtract) whole minutes
      if (allFalse || minute) {
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        if (seconds !== 0 || minutes !== 0) {
          response = minutes > 1 ? `${response} ${minutes} minutes` : `${response} 1 minute`;
        }
      }
      return response === '~' ? '~ 1 minute' : response;
    }
  });
});