define("ember-svg-jar/inlined/lightbulb-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24.001v24.001H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M12 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm1 13.91v-2.375l2.555-1.704-1.109-1.664L12 11.798l-2.445-1.63-1.109 1.664L11 13.535v2.375c-2.833-.479-5-2.943-5-5.91 0-3.309 2.691-6 6-6s6 2.691 6 6c0 2.967-2.167 5.431-5 5.91zM9 21h2v1h2v-1h2v-2H9z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});