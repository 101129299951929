define("@trovedata/sunstone-ui-commons/utils/date-utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateToJavaDate = dateToJavaDate;
  function dateToJavaDate(date) {
    return ('format' in date ? date : (0, _moment.default)(date)).format('YYYY-MM-DD');
  }
});