define("@trovedata/sunstone-ui-commons/models/application-instance", ["exports", "@ember/object/computed", "rsvp", "@ember/object", "@ember-data/model", "ember-data", "ember-data-model-fragments/attributes", "@ember/service"], function (_exports, _computed, _rsvp, _object, _model, _emberData, _attributes, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  // eslint-disable-next-line
  var _default = _exports.default = _model.default.extend({
    ajaxRequest: (0, _service.inject)('sunstone-ajax'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    orgId: (0, _model.attr)('number'),
    application: (0, _model.belongsTo)('app'),
    instProps: (0, _model.hasMany)('instance-property-value', {
      inverse: null
    }),
    appProps: (0, _model.hasMany)('application-property-value', {
      inverse: null
    }),
    propsetProps: (0, _model.hasMany)('property-set-value', {
      inverse: null
    }),
    status: (0, _model.belongsTo)('status'),
    activeIndicatorDetail: (0, _attributes.fragment)('active-indicator-detail', {
      defaultValue: {
        activeIndicator: true
      }
    }),
    sinceVersionDetail: (0, _attributes.fragment)('since-version-detail'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    configServer: (0, _model.belongsTo)('config-server', {
      inverse: null
    }),
    fetchAllProperties() {
      let promise = new _rsvp.default.Promise(resolve => {
        this.ajaxRequest.request(`applicationInstance/${this.id}/propertyValues`).then(allProps => {
          let {
            applicationInstanceConfigPropertyValue,
            applicationPropertyValue,
            propertySetValue
          } = allProps._embedded;
          if (applicationInstanceConfigPropertyValue) {
            applicationInstanceConfigPropertyValue.forEach(propVal => {
              let {
                id
              } = propVal;
              let model = this.store.modelFor('instance-property-value');
              let serializer = this.store.serializerFor('instance-property-value');
              let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
              this.store.push(normalizedPayload);
              let instProp = this.store.peekRecord('instance-property-value', id);
              instProp.set('source', 'Instance Property');
              this.instProps.pushObject(instProp);
            });
          }
          if (applicationPropertyValue) {
            applicationPropertyValue.forEach(propVal => {
              let {
                id
              } = propVal;
              let model = this.store.modelFor('application-property-value');
              let serializer = this.store.serializerFor('application-property-value');
              let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
              this.store.push(normalizedPayload);
              let appProp = this.store.peekRecord('application-property-value', id);
              appProp.set('source', 'Application Property');
              this.appProps.pushObject(appProp);
            });
          }
          if (propertySetValue) {
            propertySetValue.forEach(propVal => {
              let {
                id
              } = propVal;
              let model = this.store.modelFor('property-set-value');
              let serializer = this.store.serializerFor('property-set-value');
              let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
              this.store.push(normalizedPayload);
              let propsetProp = this.store.peekRecord('property-set-value', id);
              propsetProp.set('source', 'Propset Property');
              this.propsetProps.pushObject(propsetProp);
            });
          }
          resolve();
        });
      });
      return promise;

      // let { applicationInstanceConfigPropertyValue, applicationPropertyValue, propertySetValue } = allProps._embedded;
      // if (applicationInstanceConfigPropertyValue) {
      //   applicationInstanceConfigPropertyValue.forEach((propVal) => {
      //     let id = propVal.id;
      //     let model = this.store.modelFor('instance-property-value');
      //     let serializer = this.store.serializerFor('instance-property-value');
      //     let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
      //     this.store.push(normalizedPayload);
      //     let instProp = this.store.peekRecord('instance-property-value',id);
      //     get(this, 'instProps').pushObject(instProp);
      //   });
      // }
      //
      // if(applicationPropertyValue) {
      //   applicationPropertyValue.forEach((propVal) => {
      //     let id = propVal.id;
      //     let model = this.store.modelFor('application-property-value');
      //     let serializer = this.store.serializerFor('application-property-value');
      //     let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
      //     this.store.push(normalizedPayload);
      //     let appProp = this.store.peekRecord('application-property-value',id);
      //     get(this, 'appProps').pushObject(appProp);
      //   });
      // }
      //
      // if(propertySetValue) {
      //   propertySetValue.forEach((propVal) => {
      //     let id = propVal.id;
      //     let model = this.store.modelFor('property-set-value');
      //     let serializer = this.store.serializerFor('property-set-value');
      //     let normalizedPayload = serializer.normalizeResponse(this.store, model, propVal, id);
      //     this.store.push(normalizedPayload);
      //     let propsetProp = this.store.peekRecord('property-set-value',id);
      //     get(this, 'propsetProps').pushObject(propsetProp);
      //   });
      // }

      // return this;
    },
    isDeploying: false,
    isRunning: (0, _object.computed)('state.content', function () {
      return (0, _object.get)(this, 'state.content') === 'STARTED' || (0, _object.get)(this, 'state.content') === 'STARTING';
    }),
    isOn: (0, _computed.equal)('state.content', 'STARTED'),
    isOff: (0, _computed.equal)('state.content', 'STOPPED'),
    isPending: (0, _object.computed)('state.content', function () {
      return (0, _object.get)(this, 'state.content') === 'STOPPING' || (0, _object.get)(this, 'state.content') === 'STARTING';
    }),
    statusColor: (0, _object.computed)('state.content', function () {
      if ((0, _object.get)(this, 'state.content') === 'STARTED' || (0, _object.get)(this, 'state.content') === 'STARTING') {
        return 'accent';
      }
      if ((0, _object.get)(this, 'state.content') === 'STOPPED' || (0, _object.get)(this, 'state.content') === 'STOPPING') {
        return 'warn';
      }
      return null;
    }),
    isDeployable: (0, _object.computed)('configServer', function () {
      let promise = this.configServer.then(server => {
        return !!server;
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    state: (0, _object.computed)('status.eventType', function () {
      let promise = this.status.then(status => {
        return (0, _object.get)(status || {}, 'eventType');
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    stateClass: (0, _object.computed)('state', function () {
      const defaultStateClass = 'stopped';
      const stateClassMap = {
        'STARTED': 'started',
        'STOPPED': 'stopped',
        'STARTING': 'starting',
        'STOPPING': 'stopping'
      };
      let promise = this.state.then(state => {
        return stateClassMap[state] || defaultStateClass;
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    updateState(updateInst) {
      this.set('status.eventType', updateInst.lastState);
      this.set('status.startTime', updateInst.lastUpdate);
    },
    updateDeployment(state) {
      this.set('isDeploying', true);
      return this.ajaxRequest.post(`/applicationInstance/${this.id}/state?action=${state}`, {
        headers: {
          'Content-Type': 'application/hal+json'
        }
      }).finally(() => {
        this.set('isDeploying', false);
      });
    }
  });
});