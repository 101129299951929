define("@trovedata/trove-scenario-planner/components/paper-dialog/component", ["exports", "ember-keyboard", "ember-paper/components/paper-dialog"], function (_exports, _emberKeyboard, _paperDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberKeyboard.keyResponder)(_paperDialog.default.extend({
    onEscape: (0, _emberKeyboard.onKey)('Escape', function () {
      if (this.escapeToClose && this.onClose) this.onClose();
    })
  }));
});