define("ember-svg-jar/inlined/rewards-badge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M18 2.001H6c-1.104 0-2 .897-2 2v12c0 .323.156.626.419.813l7 5a1.002 1.002 0 001.162 0l7-5a.997.997 0 00.419-.813v-12c0-1.103-.896-2-2-2zm0 2v2H6v-2h12zm0 11.485l-6 4.286-6-4.286V8.001h12v7.485z\"/><path d=\"M8 11.001l2 2-1 3 3-2 3 2-1-3 2-2h-3l-1-3-1 3z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});