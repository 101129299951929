define("ember-svg-jar/inlined/content-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .002h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M18 2H6c-1.104 0-2 .898-2 2v4c0 .256.098.512.293.707L9 13.415V21a1.002 1.002 0 001.707.707l4-4A.996.996 0 0015 17v-3.586l4.707-4.707A.996.996 0 0020 8V4c0-1.102-.896-2-2-2zm-4.707 10.293A1 1 0 0013 13v3.586l-2 2V13a.997.997 0 00-.293-.707L6 7.586V4h12v3.586l-4.707 4.707z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});