define("@trovedata/sunstone-ui-commons/utils/string-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanTemplateString = cleanTemplateString;
  _exports.copyToClipboard = copyToClipboard;
  _exports.ellipse = ellipse;
  _exports.hasSubString = hasSubString;
  _exports.titleize = titleize;
  function hasSubString(mainString, subString, toLowercase) {
    subString = toLowercase ? `${subString}`.toLowerCase() : `${subString}`;
    mainString = toLowercase ? `${mainString}`.toLowerCase() : `${mainString}`;
    return mainString.indexOf(subString) !== -1;
  }
  function copyToClipboard(value) {
    let $temp = document.createElement('textarea');
    let $body = document.querySelector('body');
    if ($body) {
      $body.appendChild($temp);
      $temp.value = value;
      $temp.select();
      document.execCommand('copy');
      $temp.remove();
    }
  }
  function titleize(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  function ellipse(value, limit) {
    if (value.length > limit) {
      return `${value.slice(0, limit)}...`;
    } else {
      return value;
    }
  }
  function cleanTemplateString(value) {
    return value.replace(/[\t\n\r]/gm, '').replace(/\s+/g, ' ').trim();
  }
});