define("@trovedata/sunstone-ui-commons/components/card-list/component", ["exports", "@ember/array", "@ember/component", "@ember/object", "@trovedata/sunstone-ui-commons/components/card-list/template"], function (_exports, _array, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: 'ul',
    classNames: ['card-list'],
    _items: (0, _array.A)(),
    _registerItem(item) {
      this._items.push(item);
    },
    _unregisterItem(item) {
      this._items.removeObject(item);
    },
    _closeInactiveItems(activeItem) {
      this._items.forEach(item => {
        if (activeItem !== item) {
          (0, _object.set)(item, 'showActions', false);
        }
      });
    }
  });
});