define("@trovedata/sunstone-ui-commons/models/property-set-value", ["exports", "@ember/object/computed", "@ember/object", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _computed, _object, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    value: (0, _model.attr)('string'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateBy: (0, _model.attr)('string'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    orgId: (0, _model.attr)('number'),
    propertySet: (0, _model.belongsTo)('property-set'),
    configProperty: (0, _model.belongsTo)('property', {
      inverse: null
    }),
    activeIndicatorDetail: (0, _attributes.fragment)('activeIndicatorDetail', {
      defaultValue: {
        activeIndicator: true
      }
    }),
    activeIndicator: (0, _computed.alias)('activeIndicatorDetail.activeIndicator'),
    parentEntityName: (0, _computed.alias)('propertySet.name'),
    numericId: (0, _object.computed)('id', function () {
      return parseInt(this.id, 10);
    })
  });
});