define("ember-svg-jar/inlined/night-clear-sky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M4.873 7.091L5.964 10l1.091-2.909L9.964 6 7.055 4.909 5.964 2 4.873 4.909 1.964 6zM11.214 9.25l-2.25.75 2.25.75.75 2.25.75-2.25 2.25-.75-2.25-.75-.75-2.25z\"/><path d=\"M16.486 3.071a1 1 0 00-1.343 1.343A7.92 7.92 0 0116 8c0 4.411-3.589 8-8 8a7.913 7.913 0 01-3.586-.856.998.998 0 00-1.343 1.343A9.95 9.95 0 0012 22c5.514 0 10-4.485 10-10 0-3.796-2.113-7.217-5.514-8.929zM12 20a7.946 7.946 0 01-5.399-2.098A9.71 9.71 0 008 18c5.514 0 10-4.485 10-10a9.98 9.98 0 00-.098-1.398A7.947 7.947 0 0120 12c0 4.411-3.589 8-8 8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});