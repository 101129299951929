define("@trovedata/sunstone-ui-commons/index/route", ["exports", "@ember/array", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _array, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-mixins */
  var _default = _exports.default = _route.default.extend({
    config: (0, _service.inject)(),
    model() {
      return (0, _array.A)(Object.keys((0, _object.get)(this, 'config.sunstone.apps') === undefined ? {} : (0, _object.get)(this, 'config.sunstone.apps')));
    },
    redirect(model) {
      if ((0, _object.get)(model, 'length') === 1) {
        let app = (0, _object.get)(this, `config.sunstone.apps.${(0, _object.get)(model, 'firstObject')}`);
        this.transitionTo((0, _object.get)(app, 'id'));
      }
    }
  });
});