define("ember-svg-jar/inlined/st-major-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_264_2854)\"><rect width=\"32\" height=\"32\" rx=\"16\" transform=\"matrix(1 0 0 -1 4 32)\" fill=\"#8AC24A\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.586 25.414a2 2 0 002.828 0l6-6a2 2 0 10-2.828-2.828L22 19.172v-1.765l3.317-2.902a2 2 0 00-2.634-3.01l-.683.598V9a2 2 0 10-4 0v3.092l-.683-.597a2 2 0 00-2.634 3.01L18 17.407v1.765l-2.586-2.586a2 2 0 10-2.828 2.828l6 6z\" fill=\"#2C3134\"/></g><defs><filter id=\"filter0_d_264_2854\" x=\"0\" y=\"0\" width=\"40\" height=\"40\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"4\"/><feGaussianBlur stdDeviation=\"2\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_264_2854\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_264_2854\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});