define("@trovedata/trove-scenario-planner/components/shared-asset-info-window/component", ["exports", "@ember-decorators/component", "@ember/component", "@trovedata/trove-scenario-planner/components/shared-asset-info-window/template"], function (_exports, _component, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let SharedAssetInfoWindow = _exports.default = (_dec = (0, _component.layout)(_template.default), _dec2 = (0, _component.tagName)('shared-asset-info-window'), _dec(_class = _dec2(_class = class SharedAssetInfoWindow extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sharedAsset", void 0);
    }
    get adjustedRiskScore() {
      // Adjust 0-0.9 risk score to 1-10
      return Number((this.sharedAsset.riskScore * 10 + 1).toFixed(0));
    }
  }) || _class) || _class);
});