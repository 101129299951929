define("ember-svg-jar/inlined/power-button-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M15 5.589v2.223A5.999 5.999 0 0118 13c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.998 5.998 0 013-5.188V5.589C6.072 6.779 4 9.65 4 13c0 4.411 3.589 8 8 8s8-3.589 8-8c0-3.35-2.072-6.221-5-7.411z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M11 3h2v8h-2z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});