define("@trovedata/sunstone-ui-commons/models/config-server", ["exports", "@ember/object/computed", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _computed, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    hostNameUri: (0, _model.attr)('string'),
    hostBinAddress: (0, _model.attr)('string'),
    worker: (0, _model.attr)('boolean'),
    orgId: (0, _model.attr)('number'),
    hostname: (0, _computed.alias)('hostNameUri'),
    internalHostname: (0, _computed.alias)('hostBinAddress'),
    instances: (0, _model.hasMany)('application-instance'),
    insertTime: (0, _model.attr)('basic-date'),
    activeIndicatorDetail: (0, _attributes.fragment)('active-indicator-detail', {
      defaultValue: {
        activeIndicator: true
      }
    })
  });
});