define("@trovedata/trove-scenario-planner/utils/map-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE = _exports.STROKE_WEIGHT = _exports.RISK_COLOR_PALETTE_MAP = _exports.LINE_PURPLE = _exports.DEFAULT_ICON_PROPS = _exports.DEFAULT_BLUE = _exports.ACTIVE_STROKE_WEIGHT = void 0;
  _exports.generatePointSVG = generatePointSVG;
  _exports.getRiskKey = getRiskKey;
  _exports.getStrokeWeight = getStrokeWeight;
  const TYPE = _exports.TYPE = 'FeatureCollection';
  const DEFAULT_BLUE = _exports.DEFAULT_BLUE = '#3B678F';
  const DEFAULT_ICON_PROPS = _exports.DEFAULT_ICON_PROPS = {
    fillOpacity: 0.8,
    scale: 5,
    strokeColor: '#FFFFFF',
    // white
    strokeWeight: 1
  };
  const LINE_PURPLE = _exports.LINE_PURPLE = '#9370DB';
  const RISK_COLOR_PALETTE_MAP = _exports.RISK_COLOR_PALETTE_MAP = {
    1: '#3B678F',
    2: '#6285A5',
    3: '#89A3BC',
    4: '#B1C2D2',
    5: '#F5F7D7',
    6: '#E2E686',
    7: '#FFBD33',
    8: '#D16E3D',
    9: '#E85138',
    10: '#F44336'
  };
  const ACTIVE_STROKE_WEIGHT = _exports.ACTIVE_STROKE_WEIGHT = 5;
  const STROKE_WEIGHT = _exports.STROKE_WEIGHT = 3;
  function getRiskKey(riskScore) {
    const risk = riskScore * 10;
    if (risk === 0) return 1;
    if (risk >= 10) return 10;
    if (risk === Math.ceil(risk)) return risk + 1;
    return Math.ceil(risk);
  }
  function getStrokeWeight(agg, spanName) {
    return spanName && agg.name === spanName ? ACTIVE_STROKE_WEIGHT : STROKE_WEIGHT;
  }
  function generatePointSVG(options) {
    const {
      radius = 10,
      fillOpacity = 1,
      fillColor = '#000000',
      strokeColor = '#000000',
      strokeWeight = 1
    } = options;
    const height = (radius + strokeWeight) * 2;
    const center = height / 2;
    const svg = btoa(`
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="${height}px" height="${height}px" viewBox="0 0 ${height} ${height}" enable-background="new 0 0 ${height} ${height}" xml:space="preserve">
      <circle cx="${center}" cy="${center}" r="${radius}" fill="${fillColor}" fill-opacity="${fillOpacity}" stroke="${strokeColor}" stroke-width="${strokeWeight}" />
    </svg>
    `);
    return `data:image/svg+xml;base64,${svg}`;
  }
});