define("@trovedata/sunstone-ui-commons/utils/configure-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = configureMaps;
  function configureMaps(googleMapConfig, key) {
    let googleMapSrc = 'maps.googleapis.com/maps/api/js';
    googleMapConfig = googleMapConfig || {};
    googleMapConfig.protocol = googleMapConfig.protocol || '//';
    googleMapConfig.version = googleMapConfig.version || '3.55';
    googleMapConfig.apiKey = key || googleMapConfig.apiKey;
    let params = [];
    let isClient;
    params.push(`v=${encodeURIComponent(googleMapConfig.version)}`);
    if (googleMapConfig.apiKey) {
      isClient = googleMapConfig.apiKey.substr(0, 4) === 'gme-';
      params.push((isClient ? 'client' : 'key') + '=' + encodeURIComponent(googleMapConfig.apiKey));
    }
    if (googleMapConfig.libraries && googleMapConfig.libraries.length) {
      params.push(`libraries=${encodeURIComponent(googleMapConfig.libraries.join(','))}`);
    }
    if (googleMapConfig.language) {
      params.push('language=' + encodeURIComponent(googleMapConfig.language));
    }
    if (googleMapConfig.region) {
      params.push('region=' + encodeURIComponent(googleMapConfig.region));
    }
    googleMapSrc = `${googleMapConfig.protocol}${googleMapSrc}?${params.join('&')}`;
    let $maps = document.createElement('script');
    let $cluster = document.createElement('script');
    $maps.async = true;
    $maps.defer = true;
    $maps.id = 'maps-main';
    $cluster.async = true;
    $cluster.defer = true;
    $cluster.id = 'maps-cluster';
    $maps.src = googleMapSrc;
    $cluster.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js';
    document.body.appendChild($maps);
    document.body.appendChild($cluster);
  }
});