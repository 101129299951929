define("ember-svg-jar/inlined/file-information-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M2 2h10v4h4v2h2V4.586L13.414 0H2C.897 0 0 .898 0 2v18c0 1.103.897 2 2 2h6v-2H2V2z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M4 13h3v2H4z\"/></g><g><path fill=\"#757575\" d=\"M4 9h5v2H4z\"/></g><g><path d=\"M17 10c-3.859 0-7 3.14-7 7s3.141 7 7 7 7-3.14 7-7-3.141-7-7-7zm0 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M16 17h2v4h-2z\"/></g><g><circle cx=\"17\" cy=\"15\" r=\"1\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});