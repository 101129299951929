define("ember-svg-jar/inlined/grid-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M19 20.277V16h-1.999v4.277a1.984 1.984 0 00-.724.723H11v-5.277A1.994 1.994 0 108.277 13H3V7.723c.3-.175.548-.423.723-.723H8V5H3.723A1.994 1.994 0 101 7.723v12.554c-.595.347-1 .986-1 1.723a1.994 1.994 0 003.723 1h12.555c.347.595.984 1 1.723 1a2 2 0 002-2A1.998 1.998 0 0019 20.277zM9 21H3.723A2.012 2.012 0 003 20.277V15h5.277c.175.3.423.548.723.723V21z\"/><path d=\"M17 0c-3.86 0-7 3.141-7 7 0 3.86 3.14 7 7 7s7-3.14 7-7c0-3.859-3.14-7-7-7zm0 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z\"/><path d=\"M16 7h2v4h-2z\"/><circle cx=\"17\" cy=\"5\" r=\"1\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});