define("ember-paper/components/paper-autocomplete/component", ["exports", "@ember/component", "ember-paper/components/paper-autocomplete/template", "@ember-decorators/component", "@ember/object", "ember-paper/utils/calculate-ac-position", "ember-paper/mixins/validation-mixin", "@ember/debug", "ember-power-select/utils/group-utils"], function (_exports, _component, _template, _component2, _object, _calculateAcPosition, _validationMixin, _debug, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperAutocomplete = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('onSearchTextChange', 'onSelectionChange'), _dec(_class = _dec2(_class = (_class2 = class PaperAutocomplete extends _component.default.extend(_validationMixin.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isTouched", false);
      _defineProperty(this, "calculatePosition", _calculateAcPosition.default);
    }
    init() {
      this._initComponent();
      super.init(...arguments);
    }

    // Init autocomplete component
    _initComponent() {
      let {
        onSearchTextChange,
        onSelectionChange
      } = this;
      let hasTextChange = onSearchTextChange && typeof onSearchTextChange === 'function';
      let hasSelectionChange = onSelectionChange && typeof onSelectionChange === 'function';
      (false && !(hasTextChange || hasSelectionChange) && (0, _debug.assert)('<PaperAutocomplete> requires at least one of the `@onSelectionChange` or `@onSearchTextChange` functions to be provided.', hasTextChange || hasSelectionChange));
    }
    _onChange() {
      if (this.onSelectionChange) {
        this.onSelectionChange(...arguments);
      }
    }
    get validationProperty() {
      if (this.onSearchTextChange) {
        return 'searchText';
      } else {
        return 'selected';
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.notifyValidityChange();
    }
    close() {
      this.didAnimateScale = false;
      this.set('isTouched', true);
      this.notifyValidityChange();
      if (this.onClose) {
        return this.onClose(...arguments);
      }
    }
    open(select, e) {
      if (e && e.type === 'mousedown') {
        return false;
      }
      this.didAnimateScale = false;
      this.notifyValidityChange();
      if (this.onOpen) {
        return this.onOpen(...arguments);
      }
    }
    focus(select, e) {
      if ((e.target.classList.contains('ember-paper-autocomplete-search-input') || e.target.classList.contains('md-input')) && !select.selected) {
        select.actions.open(e);
      }
      if (this.onFocus) {
        return this.onFocus(...arguments);
      }
    }
    blur() {
      this.notifyValidityChange();
      if (this.onBlur) {
        this.onBlur(...arguments);
      }
    }
    _onInput(term, select, e) {
      if (select.selected) {
        select.actions.select(null);
      }
      if (this.onSearchTextChange) {
        this.onSearchTextChange(term, select, e);
      } else {
        this.set('searchText', term);
      }
      if (!select.isOpen && e.type !== 'change') {
        select.actions.open(e);
      }
      this.notifyValidityChange();
      if (this.onInput) {
        this.onInput(...arguments);
      }
      return term;
    }
    _onCreate() {
      let text = this.publicAPI.searchText;
      if (this.onCreate) {
        this.onCreate(text, this.publicAPI);
      }
      this.publicAPI.actions.close();
    }
    scrollTo(option, select) {
      let optionsList = document.getElementById(`ember-power-select-options-${this.publicAPI.uniqueId}`);
      if (!optionsList) {
        return;
      }
      optionsList = optionsList.parentNode;
      let index = (0, _groupUtils.indexOfOption)(select.results, option);
      if (index === -1) {
        return;
      }
      let optionElement = optionsList.querySelector(`[data-option-index="${index}"]`);
      if (!optionElement) {
        return;
      }
      let optionTopScroll = optionElement.offsetTop;
      let optionBottomScroll = optionTopScroll + optionElement.offsetHeight;
      if (optionBottomScroll > optionsList.offsetHeight + optionsList.scrollTop) {
        optionsList.scrollTop = optionBottomScroll - optionsList.offsetHeight;
      } else if (optionTopScroll < optionsList.scrollTop) {
        optionsList.scrollTop = optionTopScroll;
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_onChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "validationProperty", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "validationProperty"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "focus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "blur", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "blur"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onCreate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCreate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrollTo", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollTo"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperAutocomplete;
});