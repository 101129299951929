define("@trovedata/trove-scenario-planner/scenario-planner/plan/edit/route", ["exports", "@ember/routing/route", "@ember/service", "@trovedata/sunstone-ui-commons/utils/string-utils"], function (_exports, _route, _service, _stringUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EditRoute = _exports.default = (_class = class EditRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "aggGeom", _descriptor, this);
      _initializerDefineProperty(this, "customization", _descriptor2, this);
    }
    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      this.setUpBreadCrumbs(controller, transition);
    }
    resetController(controller, isExiting) {
      if (!isExiting) return;
      controller.applicationController.breadCrumbs = [];
    }
    deactivate() {
      super.deactivate();
      this.aggGeom.stopFetching();
    }
    setUpBreadCrumbs(controller, transition) {
      const {
        priorParams
      } = transition.to.queryParams;
      const {
        isMapExpanded,
        showSummaryCard
      } = this.customization;
      const grouping = this.modelFor('scenario-planner.plan');
      const breadCrumbs = [{
        label: 'Explore',
        query: {
          aggType: 'line-agg',
          editPlan: undefined,
          filters: undefined,
          groupingId: grouping.id,
          isDeepDiveMode: false,
          isMapExpanded,
          priorParams: undefined,
          showMapFilter: false,
          showSummaryCard
        },
        route: 'scenario-planner.plan.index'
      }];

      /**
       * If 'priorParams' query params are there, it means FE filters on plan
       * index were enabled/used prior to transitioning to plan edit route. Need
       * to create a breadcrumb to transition back to that FE filtered state
       * under plan index.
       */
      if (priorParams) {
        breadCrumbs.push({
          label: 'Filtered Circuits',
          query: {
            ...JSON.parse(priorParams)
          },
          route: 'scenario-planner.plan.index'
        });
      }
      if (controller.selectedPlan) {
        const label = `Plan (${(0, _stringUtils.ellipse)(controller.selectedPlan.name, 20)})`;
        breadCrumbs.push({
          isPlanLabel: true,
          label
        });
      }
      controller.applicationController.breadCrumbs = breadCrumbs;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "aggGeom", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});