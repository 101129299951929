define("@trovedata/sunstone-ui-commons/infinity/global-infinity-model", ["exports", "ember-infinity/lib/infinity-model"], function (_exports, _infinityModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //@ts-ignore
  var _default = _exports.default = _infinityModel.default.extend({
    perPageParam: 'size',
    totalPagesParam: 'meta.totalPages',
    buildParams() {
      let params = this._super(...arguments);
      if (params.size && this.store.adapterFor(this._infinityModelName).namespace === 'sunstone/api') {
        params.size++;
      }
      return params;
    }
  });
});