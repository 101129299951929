define("ember-svg-jar/inlined/phone-speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><g><path d=\"M17 13h-2c0-2.205-1.794-4-4-4V7c3.309 0 6 2.691 6 6z\" fill=\"#757575\"/></g><g><path d=\"M21 13h-2c0-4.411-3.589-8-8-8V3c5.515 0 10 4.486 10 10z\" fill=\"#757575\"/></g><g><path d=\"M11 11v2h2a2 2 0 00-2-2z\" fill=\"#757575\"/></g></g><g><path d=\"M18 23h-5C6.384 23 1 17.617 1 11V6c0-1.104.897-2 2-2h4c1.103 0 2 .896 2 2v4a2.003 2.003 0 01-1.908 1.998c.437 2.584 2.367 4.52 4.909 4.924A2.004 2.004 0 0114 15h4c1.103 0 2 .896 2 2v4a2 2 0 01-2 2zM3 6v5c0 5.514 4.486 10 10 10h5.001L18 17h-4v1a1 1 0 01-1 1c-4.488 0-7.928-3.433-8-7.984A1.005 1.005 0 016 10h1V6H3z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});