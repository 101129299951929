define("ember-svg-jar/inlined/network-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M2 4c.178 0 .348-.031.512-.074l3.281 3.281 1.414-1.414-3.281-3.281A2.01 2.01 0 004 2a2 2 0 10-2 2zM2 14c.738 0 1.377-.405 1.723-1H6v-2H3.723A1.994 1.994 0 000 12a2 2 0 002 2zM5.793 16.793l-3.281 3.282A1.967 1.967 0 002 20a2 2 0 102 2c0-.177-.031-.348-.074-.512l3.281-3.282-1.414-1.413zM22 10c-.738 0-1.376.405-1.723 1H18v2h2.277c.346.595.984 1 1.723 1a2 2 0 000-4zM18.207 7.207l3.281-3.281c.164.043.334.074.512.074a2 2 0 10-2-2c0 .178.031.348.074.512l-3.281 3.281 1.414 1.414zM22 20c-.178 0-.348.031-.512.075l-3.281-3.282-1.414 1.414 3.281 3.282A2.002 2.002 0 0020 22a2 2 0 102-2zM15 5c0-1.654-1.346-3-3-3S9 3.346 9 5s1.346 3 3 3 3-1.346 3-3zm-3 1a1.001 1.001 0 010-2 1.001 1.001 0 010 2zM17 13c0-2.757-2.243-5-5-5s-5 2.243-5 5v3a1 1 0 001 1h1v4a1 1 0 001 1h4a1 1 0 001-1v-4h1a1 1 0 001-1v-3zm-2 2h-1a1 1 0 00-1 1v4h-2v-4a1 1 0 00-1-1H9v-2c0-1.654 1.346-3 3-3s3 1.346 3 3v2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});