define("@trovedata/trove-scenario-planner/adapters/span", ["exports", "@trovedata/sunstone-ui-commons/adapters/service-point"], function (_exports, _servicePoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Span extends _servicePoint.default {
    pathForType(type) {
      type = 'servicePoint';
      return super.pathForType(type);
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = Span;
});