define("ember-svg-jar/inlined/percent-box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M9 12c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4a1.001 1.001 0 010 2 1.001 1.001 0 010-2zM12 15c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm4 0a1.001 1.001 0 11-1-1c.551 0 1 .448 1 1z\"/><path transform=\"rotate(134.999 12 12)\" d=\"M6.343 11h11.314v1.999H6.343z\"/><path d=\"M19 3H5c-1.104 0-2 .898-2 2v14c0 1.103.896 2 2 2h14c1.104 0 2-.897 2-2V5c0-1.102-.896-2-2-2zm-.003 16H5V5h14l-.003 14z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});