define("@trovedata/sunstone-ui-commons/serializers/dynamic-transform", ["exports", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serializeAttribute(snapshot, json, key, attribute) {
      const {
        dynamicTransform
      } = attribute.options;
      if (dynamicTransform) {
        attribute.type = snapshot.record.get(dynamicTransform);
      }
      if (attribute.type === 'ENUM') {
        attribute.type = 'enumeration';
      }
      if (attribute.type === 'ARRAY') {
        attribute.type = 'sunstone-array';
      }
      this._super.call(this, snapshot, json, key, attribute);
    }
  });
});