define("@trovedata/sunstone-ui-commons/utils/restheart-utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseFilter = parseFilter;
  _exports.parseSort = parseSort;
  function parseSort(sort) {
    const sortQuery = sort.reduce((query, def) => {
      query[def.key] = def.dir === 'asc' ? 1 : -1;
      return query;
    }, {});
    return JSON.stringify(sortQuery);
  }
  function parseFilter(filter) {
    const filterQuery = {
      $and: filter.reduce((query, def) => {
        const filter = {};
        filter[def.key] = {};
        if (_moment.default.isMoment(def.value)) {
          filter[def.key][`$${def.type}`] = {
            $date: def.value.valueOf()
          };
        } else if (def.type === 'regex') {
          filter[def.key][`$${def.type}`] = `^.*${def.value}.*$`;
        } else {
          filter[def.key][`$${def.type}`] = def.value;
        }
        query.push(filter);
        return query;
      }, [])
    };
    return JSON.stringify(filterQuery);
  }
});