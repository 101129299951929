define("ember-svg-jar/inlined/st-checkmark-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M33 64.35c-8.375 0-16.246-3.26-22.17-9.18-5.923-5.92-9.18-13.795-9.18-22.167 0-8.375 3.26-16.246 9.18-22.17C16.75 4.91 24.626 1.65 33 1.65c8.375 0 16.246 3.26 22.17 9.184 5.923 5.923 9.18 13.794 9.18 22.17 0 8.375-3.26 16.245-9.18 22.165C49.25 61.09 41.374 64.35 33 64.35zm0-59.4C17.533 4.95 4.95 17.533 4.95 33S17.533 61.05 33 61.05 61.05 48.467 61.05 33 48.467 4.95 33 4.95z\"/><path d=\"M26.4 46.197c-.421 0-.844-.161-1.167-.481l-9.9-9.9a1.651 1.651 0 010-2.333 1.651 1.651 0 012.333 0l8.732 8.731 21.931-21.931a1.651 1.651 0 012.334 0c.643.643.643 1.69 0 2.333l-23.1 23.1a1.643 1.643 0 01-1.169.481h.007z\"/>",
    "attrs": {
      "width": "66",
      "height": "66",
      "viewBox": "0 0 66 66",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});