define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      content=(component 'card-list/card-list-item/card-list-item-content' toggleActions=(action 'toggleActions'))
      trigger=(component 'card-list/card-list-item/card-list-item-actions-trigger' showActions=showActions toggleActions=(action 'toggleActions'))
    )
  }}
  
  */
  {
    "id": "CBr/is5m",
    "block": "[[[18,1,[[28,[37,1],null,[[\"content\",\"trigger\"],[[50,\"card-list/card-list-item/card-list-item-content\",0,null,[[\"toggleActions\"],[[28,[37,3],[[30,0],\"toggleActions\"],null]]]],[50,\"card-list/card-list-item/card-list-item-actions-trigger\",0,null,[[\"showActions\",\"toggleActions\"],[[33,4],[28,[37,3],[[30,0],\"toggleActions\"],null]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\",\"showActions\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/template.hbs",
    "isStrictMode": false
  });
});