define("@trovedata/sunstone-ui-commons/serializers/restheart-serializer", ["exports", "ember-data", "@ember/utils"], function (_exports, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONSerializer
  } = _emberData.default;
  class RestheartSerializer extends JSONSerializer {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      ((0, _utils.typeOf)(payload) === 'array' ? payload : [payload]).forEach(record => {
        record.id = record._id.$oid;
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      return super.normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType);
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
  _exports.default = RestheartSerializer;
});