define("@trovedata/sunstone-ui-commons/helpers/sunstone-moment-format", ["exports", "@ember/object", "@ember/service", "@ember/component/helper", "ember", "moment"], function (_exports, _object, _service, _helper, _ember, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = _helper.default.extend({
    sunstoneEnv: (0, _service.inject)(),
    newTimezoneObserver: (0, _object.observer)('sunstoneEnv.timezone', function () {
      this.recompute();
    }),
    compute([date, format]) {
      const timezone = this.get('sunstoneEnv.timezone');
      if (!_moment.default.tz.zone(timezone)) {
        _ember.default.Logger.warn(`Invalid timezone ${timezone}`);
        return undefined;
      }
      return date ? (0, _moment.default)(date).tz(timezone).format(format) : undefined;
    }
  });
});