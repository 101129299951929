define("ember-svg-jar/inlined/DataFrames-White", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g><path d=\"M140.6 51.6h-12.5c-6-16.7-19-29.6-35.7-35.6V3.4C92.4 1.4 91 0 89 0H55.3c-2 0-3.7 1.4-3.7 3.4v12.5c-16.4 6-29.3 19-35.3 35.7H3.7C1.7 51.6 0 53 0 55v33.8c0 2 1.8 3.7 3.7 3.7h12.5c6 16.4 19 29.4 35.3 35.4v12.5c0 2 1.8 3.7 3.7 3.7H89c2 0 3.4-1.8 3.4-3.7v-12.6c16.7-6 29.7-18.9 35.7-35.3h12.5c2 0 3.4-1.8 3.4-3.7V54.9c0-1.9-1.4-3.3-3.4-3.3zm-20.1 0h-13.6c-2 0-3.7 1.4-3.7 3.4v13.7H92.4V54.9c0-2-1.4-3.4-3.4-3.4H75.7V40.9H89c2 0 3.4-1.8 3.4-3.7V23.6c12.8 5.3 22.8 15.2 28.1 28zM58.7 85.3V58.7h26.7v26.7H58.7zm0-78.2h26.7v26.7H58.7V7.1zm-7.1 16.4v13.7c0 2 1.8 3.7 3.7 3.7h13.3v10.7H55.3c-2 0-3.7 1.4-3.7 3.4v13.7H40.9V54.9c0-2-1.4-3.4-3.4-3.4H23.9c5.3-12.7 15.2-22.7 27.7-28zM7.1 58.7h26.7v26.7H7.1V58.7zm44.5 47.8v13.7c-12.4-5.3-22.3-15.3-27.7-27.7h13.6c2 0 3.4-1.8 3.4-3.7v-13h10.7v13c0 2 1.8 3.7 3.7 3.7h13.3v10.7H55.3c-2-.1-3.7 1.3-3.7 3.3zm33.7 30.4H58.7v-26.7h26.7v26.7zm7.1-16.8v-13.6c0-2-1.4-3.4-3.4-3.4H75.7V92.4H89c2 0 3.4-1.8 3.4-3.7v-13h10.7v13c0 2 1.8 3.7 3.7 3.7h13.6c-5.2 12.5-15.2 22.3-28 27.7zm44.5-34.8h-26.7V58.7h26.7v26.6z\" fill=\"#fff\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
});