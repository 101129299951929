define("@trovedata/trove-scenario-planner/adapters/line", ["exports", "@trovedata/sunstone-ui-commons/adapters/service-point-group"], function (_exports, _servicePointGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Line extends _servicePointGroup.default {
    pathForType(type) {
      type = 'servicePointGroup';
      return super.pathForType(type);
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
  _exports.default = Line;
});