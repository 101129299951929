define("ember-svg-jar/inlined/move-to-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M3 7H1v16h16v-2H3zM5 2h2v2H5zM8 2h2v2H8zM11 2h2v2h-2zM14 2h2v2h-2zM20 2h2v2h-2zM17 2h2v2h-2zM20 11h2v2h-2z\"/><path d=\"M5 11h2v2H5zM5 8h2v2H5zM5 5h2v2H5zM20 8h2v2h-2zM20 5h2v2h-2zM5 14h2v2H5zM5 17h2v2H5zM8 17h2v2H8zM11 17h2v2h-2zM14 17h2v2h-2zM17 17h2v2h-2zM20 17h2v2h-2zM20 14h2v2h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});