define("@trovedata/trove-scenario-planner/helpers/distribution-bar-color", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.distributionBarColor = distributionBarColor;
  function distributionBarColor([amount, totalAmount, showYellow = false]) {
    if (0 <= amount && 0 < totalAmount) {
      if (amount <= totalAmount) {
        return showYellow ? 'rgba(255, 255, 0, .42)' : 'rgba(29, 139, 241, .42)'; // Yellow or Blue
      } else {
        return 'rgba(255, 0, 0, .42)'; // Red
      }
    } else {
      return 'RGB(var(--primary-300))'; // Default gray
    }
  }
  var _default = _exports.default = (0, _helper.helper)(distributionBarColor);
});