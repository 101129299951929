define("ember-svg-jar/inlined/TROVEdata-White", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#fff}</style><g><g><g><path class=\"st0\" d=\"M117.6 78.7c1.2 3.4 4.4 5.8 8.1 5.8 4.8 0 8.7-3.9 8.7-8.7 0-4.8-3.9-8.7-8.7-8.7-3.8 0-7 2.4-8.1 5.8h-15.9V22.8H117c.2 0 .3 0 .5-.1 1.2 3.4 4.4 5.8 8.2 5.8 4.8 0 8.7-3.9 8.7-8.7 0-4.8-3.9-8.7-8.7-8.7-3.8 0-7 2.4-8.2 5.8-.2 0-.3-.1-.5-.1H98.7c-1.6 0-2.9 1.3-2.9 2.9v53H80.4c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9h15.4v53c0 1.6 1.3 2.9 2.9 2.9h18.8c1.2 3.4 4.4 5.8 8.2 5.8 4.8 0 8.7-3.9 8.7-8.7 0-4.8-3.9-8.7-8.7-8.7-3.8 0-7 2.4-8.2 5.8h-15.9V78.7h16zm0 0\"/></g></g><g><path class=\"st0\" d=\"M39.6 42.8c11.8 0 21.4-9.6 21.4-21.4C61 9.6 51.4 0 39.6 0S18.2 9.6 18.2 21.4c0 11.8 9.6 21.4 21.4 21.4zm0-35.6c7.8 0 14.2 6.4 14.2 14.2 0 7.8-6.4 14.2-14.2 14.2s-14.2-6.4-14.2-14.2c.1-7.8 6.4-14.2 14.2-14.2zM56.4 43.9h-32C14.8 43.9 7 51.7 7 61.3v29.3c0 5.3 4.9 10 10.5 10 1.1 0 2.2-.2 3.3-.5v32c0 6.1 4.6 11.2 10.4 11.8.4 0 .7.1 1.1.1 2.9 0 5.7-1.1 7.8-3l.3-.3.3.3c2.4 2.2 5.6 3.3 8.9 3 5.8-.6 10.4-5.7 10.4-11.8v-32c1.1.3 2.2.5 3.3.5 5.6 0 10.5-4.7 10.5-10V61.3c0-9.6-7.8-17.4-17.4-17.4zm6.9 49.6c-1.7 0-3.3-1.4-3.3-2.5 0-2-1.6-3.6-3.6-3.6S52.8 89 52.8 91v41.2c0 2.4-1.7 4.4-3.8 4.6-1.3.1-2.4-.3-3.3-1.1-.9-.8-1.4-2-1.4-3.2 0-2-1.6-3.6-3.6-3.6h-.4c-2 0-3.6 1.6-3.6 3.6 0 1.2-.5 2.4-1.4 3.2-.9.8-2.1 1.2-3.3 1.1-2.2-.2-3.9-2.2-3.9-4.6V91c0-2-1.6-3.6-3.6-3.6S20.8 89 20.8 91c0 1.1-1.6 2.5-3.3 2.5-1.9 0-3.3-1.7-3.3-2.8V61.3c0-5.6 4.6-10.2 10.2-10.2h32c5.6 0 10.2 4.6 10.2 10.2v29.3c0 1.2-1.4 2.9-3.3 2.9z\"/></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
});