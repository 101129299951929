define("@trovedata/sunstone-ui-commons/helpers/root-url", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    rootUrl: (0, _service.inject)(),
    compute([relativeURL]) {
      return this.get('rootUrl').build(relativeURL);
    }
  });
});