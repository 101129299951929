define("@trovedata/sunstone-ui-commons/components/sunstone-draggable-item/component", ["exports", "@ember/component", "@ember/object", "@trovedata/sunstone-ui-commons/components/sunstone-draggable-item/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNameBindings: ['draggable:draggableItem'],
    attributeBindings: ['draggable'],
    draggable: true,
    dragStart(event) {
      return event.dataTransfer.setData('text/data', this.content);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      let draggable = this.draggable;
      if (!draggable) {
        (0, _object.set)(this, 'attributeBindings', []);
        (0, _object.set)(this, 'classNames', []);
      }
    }
  });
});