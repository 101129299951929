define("ember-svg-jar/inlined/calendar-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0-.001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><path d=\"M8 18H2V8h15.999v2H20V5c0-1.102-.897-2-2-2h-1V0h-2v5h-1V3H7V0H5v5H4V3H2C.897 3 0 3.898 0 5v13c0 1.103.897 2 2 2h6v-2z\" fill=\"#757575\"/><g><g><path d=\"M19 21.984h-4v-4l-2 2v3a1 1 0 001 1h6a1 1 0 001-1v-3l-2-2v4z\" fill=\"#757575\"/></g><g><path d=\"M22.293 19.691L17 14.398l-5.293 5.293-1.414-1.414 6-6a.999.999 0 011.414 0l6 6-1.414 1.414z\" fill=\"#757575\"/></g></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});