define("@trovedata/sunstone-ui-commons/components/trove-chart/component", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@trovedata/sunstone-ui-commons/highcharts-themes/dark-theme", "@ember/template-factory"], function (_exports, _component, _object, _component2, _darkTheme, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <HighCharts
    class="trove-chart responsive"
    @callback={{this.onLoad}}
    @chartOptions={{@chartOptions}}
    @content={{@content}}
    @mode={{false}}
    @theme={{this.theme}}
  />
  
  */
  {
    "id": "OWTGQsC1",
    "block": "[[[8,[39,0],[[24,0,\"trove-chart responsive\"]],[[\"@callback\",\"@chartOptions\",\"@content\",\"@mode\",\"@theme\"],[[30,0,[\"onLoad\"]],[30,1],[30,2],false,[30,0,[\"theme\"]]]],null],[1,\"\\n\"]],[\"@chartOptions\",\"@content\"],false,[\"high-charts\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-chart/component.hbs",
    "isStrictMode": false
  });
  let TroveChart = _exports.default = (_class = class TroveChart extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", _darkTheme.default);
    }
    onLoad(chart) {
      /**
       * Fit Parent Container
       *
       * ember-highcharts v1.1.1 to v3.2.1
       * highcharts v7.2.2 to 10.1.0
       *
       * After upgrades the normal css (app/styles/Partials/_highcharts.scss) that
       * used to ensure that the chart fits to the parent container does not work.
       * `reflow` is a solution provided by below.
       *
       * https://github.com/highcharts/highcharts-angular/issues/149
       */
      chart.reflow();
      if (this.args.onLoad) this.args.onLoad(chart);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onLoad", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onLoad"), _class.prototype)), _class);
  /**
   * Tooltip Styles
   *
   * Ensure that the custom tooltip has class 'trove-chart-tooltip'
   * Do not include custom web components like `<md-card>` in the tooltip HTML.
   */
  /**
   * Responsive
   *
   * Class `responsive` is needed the get the chart content to fit parent
   * container. In the future, if you encounter style conflicts, try removing
   * this class.
   */
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TroveChart);
});