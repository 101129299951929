define("@trovedata/sunstone-ui-commons/highcharts-themes/sunstone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    colors: ['rgba(247,163,92, 1)', 'rgba(128,133,233, 1)', 'rgba(124,181,236, 1)', 'rgba(144,237,125, 1)', 'rgba(241,92,128, 1)'],
    legend: {
      verticalAlign: 'top',
      backgroundColor: '#FCFFC5'
    },
    chart: {
      zoomType: 'x'
    },
    tooltip: {
      shared: true
    }
  };
});