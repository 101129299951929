define("@trovedata/sunstone-ui-commons/serializers/config-property", ["exports", "@trovedata/sunstone-ui-commons/serializers/restheart-serializer"], function (_exports, _restheartSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConfigProperty extends _restheartSerializer.default {}

  // DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
  _exports.default = ConfigProperty;
});