define("ember-svg-jar/inlined/st-no-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"32\" height=\"32\" rx=\"16\" fill=\"#fff\" fill-opacity=\".12\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.9 16a2 2 0 012-2h12a2 2 0 110 4h-12a2 2 0 01-2-2z\" fill=\"#2C3134\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});