define("@trovedata/sunstone-ui-commons/components/paper-select-multiple-before-options/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if searchEnabled}}
    <md-select-header class="ember-power-select-search md-searchbox">
      <input type="search" autocomplete="off"
        autocorrect="off" autocapitalize="off"
        spellcheck="false" role="combobox"
        class="ember-power-select-search-input md-searchinput"
        value={{select.searchText}}
        aria-controls={{listboxId}}
        placeholder={{searchPlaceholder}}
        oninput={{onInput}}
        onfocus={{onFocus}}
        onblur={{onBlur}}
        onkeydown={{action "onKeydown"}}>
    </md-select-header>
  {{/if}}
  {{#if (and flatOptions.length (not extra.disableSelectAll))}}
    <div class="select-all-muti-select">
      {{#paper-checkbox data-test-filter-select-all=true class="layout-margin-none" value=(contains flatOptions select.selected) onChange=(action 'selectAll')}}
        Select All
      {{/paper-checkbox}}
    </div>
    {{paper-divider}}
  {{/if}}
  
  */
  {
    "id": "1TLUNrAh",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"md-select-header\"],[14,0,\"ember-power-select-search md-searchbox\"],[12],[1,\"\\n    \"],[10,\"input\"],[14,\"autocomplete\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocapitalize\",\"off\"],[14,\"spellcheck\",\"false\"],[14,\"role\",\"combobox\"],[14,0,\"ember-power-select-search-input md-searchinput\"],[15,2,[33,2,[\"searchText\"]]],[15,\"aria-controls\",[36,3]],[15,\"placeholder\",[36,4]],[15,\"oninput\",[36,5]],[15,\"onfocus\",[36,6]],[15,\"onblur\",[36,7]],[15,\"onkeydown\",[28,[37,8],[[30,0],\"onKeydown\"],null]],[14,4,\"search\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,9],[[33,10,[\"length\"]],[28,[37,11],[[33,12,[\"disableSelectAll\"]]],null]],null],[[[1,\"  \"],[10,0],[14,0,\"select-all-muti-select\"],[12],[1,\"\\n\"],[6,[39,13],null,[[\"data-test-filter-select-all\",\"class\",\"value\",\"onChange\"],[true,\"layout-margin-none\",[28,[37,14],[[33,10],[33,2,[\"selected\"]]],null],[28,[37,8],[[30,0],\"selectAll\"],null]]],[[\"default\"],[[[[1,\"      Select All\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n  \"],[1,[34,15]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"searchEnabled\",\"select\",\"listboxId\",\"searchPlaceholder\",\"onInput\",\"onFocus\",\"onBlur\",\"action\",\"and\",\"flatOptions\",\"not\",\"extra\",\"paper-checkbox\",\"contains\",\"paper-divider\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-select-multiple-before-options/template.hbs",
    "isStrictMode": false
  });
});