define("@trovedata/sunstone-ui-commons/components/toggle-group/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if label}}
    {{trove-label label size=13 condensed=true}}
  {{/if}}
  {{#if paginated}}
    {{#alias-prop (lte selectedPageItem (sub itemsPerPage 1)) as |disabled|}}
      <div class="toggle md-nav-button {{if disabled "disabled"}}">
        {{#trove-button accent=true iconButton=true onClick=(action 'prev' selectedPageItem) disabled=disabled}}
          {{trove-icon "navigation-left" size=20}}
        {{/trove-button}}
      </div>
    {{/alias-prop}}
  {{/if}}
  <div class="layout-row toggle-container {{if paginated "paginated"}} {{if label "labeled"}}" style={{if paginationStyles paginationStyles}}>
    <div class="inner-toggle-container layout-row" style={{if innerContainerStyles innerContainerStyles}}>
      {{yield (hash
        toggle-item=
          (component 'toggle-group-item'
            selected=selected
            onSelect=(action "change")
          )
        )
      }}
    </div>
  </div>
  {{#if paginated}}
    {{#alias-prop (gte selectedPageItem (sub childComponents.length 1)) as |disabled|}}
      <div class="toggle md-nav-button {{if disabled "disabled"}}">
        {{#trove-button accent=true iconButton=true disabled=disabled onClick=(action 'next' selectedPageItem)}}
          {{trove-icon "navigation-right" size=20}}
        {{/trove-button}}
      </div>
    {{/alias-prop}}
  {{/if}}
  
  */
  {
    "id": "FvZyGaTU",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],[[33,1]],[[\"size\",\"condensed\"],[13,true]]]],[1,\"\\n\"]],[]],null],[41,[33,3],[[[6,[39,4],[[28,[37,5],[[33,6],[28,[37,7],[[33,8],1],null]],null]],null,[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[\"toggle md-nav-button \",[52,[30,1],\"disabled\"]]]],[12],[1,\"\\n\"],[6,[39,9],null,[[\"accent\",\"iconButton\",\"onClick\",\"disabled\"],[true,true,[28,[37,10],[[30,0],\"prev\",[33,6]],null],[30,1]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,11],[\"navigation-left\"],[[\"size\"],[20]]]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]]]],[]],null],[10,0],[15,0,[29,[\"layout-row toggle-container \",[52,[33,3],\"paginated\"],\" \",[52,[33,1],\"labeled\"]]]],[15,5,[52,[33,12],[33,12]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"inner-toggle-container layout-row\"],[15,5,[52,[33,13],[33,13]]],[12],[1,\"\\n    \"],[18,3,[[28,[37,15],null,[[\"toggle-item\"],[[50,\"toggle-group-item\",0,null,[[\"selected\",\"onSelect\"],[[33,17],[28,[37,10],[[30,0],\"change\"],null]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,3],[[[6,[39,4],[[28,[37,18],[[33,6],[28,[37,7],[[33,19,[\"length\"]],1],null]],null]],null,[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[\"toggle md-nav-button \",[52,[30,2],\"disabled\"]]]],[12],[1,\"\\n\"],[6,[39,9],null,[[\"accent\",\"iconButton\",\"disabled\",\"onClick\"],[true,true,[30,2],[28,[37,10],[[30,0],\"next\",[33,6]],null]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,11],[\"navigation-right\"],[[\"size\"],[20]]]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[2]]]]]],[]],null]],[\"disabled\",\"disabled\",\"&default\"],false,[\"if\",\"label\",\"trove-label\",\"paginated\",\"alias-prop\",\"lte\",\"selectedPageItem\",\"sub\",\"itemsPerPage\",\"trove-button\",\"action\",\"trove-icon\",\"paginationStyles\",\"innerContainerStyles\",\"yield\",\"hash\",\"component\",\"selected\",\"gte\",\"childComponents\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/toggle-group/template.hbs",
    "isStrictMode": false
  });
});