define("@trovedata/sunstone-ui-commons/models/data-field-statistic", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    dataType: (0, _model.attr)('string', {
      defaultValue: 'INTEGER'
    }),
    isEnum: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    enumTypeId: (0, _model.attr)('number'),
    dataField: (0, _model.belongsTo)('data-field', {
      async: true
    }),
    statisticType: (0, _model.belongsTo)('data-field-statistic-type', {
      async: true
    }),
    values: (0, _model.hasMany)('data-field-statistic-value', {
      async: false
    }),
    type: (0, _computed.alias)('statisticType'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    validations: {
      dataType: {
        presence: true
      },
      isEnum: {
        presence: true
      },
      values: true,
      type: true
    }
  });
});