define("@trovedata/sunstone-ui-commons/components/sunstone-highchart/component", ["exports", "ember-highcharts/components/high-charts", "sunstone-ui-commons/highcharts-themes/sunstone"], function (_exports, _highCharts, _sunstone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _highCharts.default.extend({
    theme: _sunstone.default
  });
});