define("ember-svg-jar/inlined/user-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><path fill=\"#757575\" d=\"M13.001 6h2v4h-2zM17.001 4h2v6h-2zM21.001 2h2v8h-2z\"/><g><g><path d=\"M7.001 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z\" fill=\"#757575\"/></g><g><path d=\"M9.001 22h-4a1 1 0 01-1-1v-4h-1a1 1 0 01-1-1v-3c0-2.757 2.243-5 5-5s5 2.243 5 5v3a1 1 0 01-1 1h-1v4a1 1 0 01-1 1zm-3-2h2v-4a1 1 0 011-1h1v-2c0-1.654-1.346-3-3-3s-3 1.346-3 3v2h1a1 1 0 011 1v4z\" fill=\"#757575\"/></g></g><g><path fill=\"#757575\" d=\"M13.001 11h10v2h-10z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});