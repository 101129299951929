define("ember-svg-jar/inlined/high-voltage-electric-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><path d=\"M17 8.531v-.532h5a1 1 0 00.64-1.768l-6-5-.008.011A.984.984 0 0016 .999H8a.984.984 0 00-.632.243l-.009-.012-6 5c-.323.27-.443.714-.3 1.11.144.395.52.659.941.659h5v.532l-5.64 4.7A1.001 1.001 0 002 14.999h4.208l-2.909 8h2.128l.043-.117L12 19.617l6.529 3.265.043.117H20.7l-2.909-8H22a1 1 0 00.64-1.768L17 8.531zm-3.764-1.032L15 5.075v4.85l-1.764-2.426zM12 5.799l-2.036-2.8h4.072L12 5.799zm-1.237 1.7L9 9.924v-4.85l1.763 2.425zM12 9.199l2.764 3.799H9.236L12 9.199zm5-3.2V4.134l2.238 1.864H17zm-12.238 0L7 4.134v1.864H4.762zm0 7L7 11.134v1.864H4.762zm1.702 7.149l1.015-2.791 2.284 1.141-3.299 1.65zm7.772-1.649l2.284-1.141 1.015 2.791-3.299-1.65zm1.596-3.034L12 17.381l-3.833-1.916.169-.466h7.326l.17.466zM17 12.999v-1.864l2.238 1.864H17z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});