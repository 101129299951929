define("@trovedata/sunstone-ui-commons/components/trove-map-markers/component", ["exports", "@ember/object", "@ember/component", "@ember/array", "jquery", "@trovedata/sunstone-ui-commons/components/trove-map-markers/template"], function (_exports, _object, _component, _array, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-jquery */
  /* global google MarkerClusterer */

  let getGoogleClusterInlineSvg = color => {
    let encoded = window.btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
      <g fill="${color}">
        <circle r="42"/>
      </g>
    </svg>`);
    return `data:image/svg+xml;base64,${encoded}`;
  };
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    markers: (0, _array.A)(),
    _markers: (0, _array.A)(),
    _circles: (0, _array.A)(),
    _polyline: null,
    color: null,
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map) {
        let map = this.map;
        this._markers.forEach(marker => {
          marker.setMap(null);
        });
        let _markerCluster = this._markerCluster;
        let _polyline = this._polyline;
        if (_markerCluster) {
          _markerCluster.clearMarkers();
        }
        if (_polyline) {
          _polyline.setMap(null);
        }
        let clusterMarkers = this.clusterMarkers;
        let clusterOptions = this.clusterOptions;
        (0, _object.set)(this, '_markers', []);
        let bounds = new google.maps.LatLngBounds();
        let infoWindow = new google.maps.InfoWindow({
          content: ''
        });
        infoWindow.addListener('domready', function () {
          var iwOuter = (0, _jquery.default)('.gm-style-iw');
          var iwBackground = iwOuter.prev();
          iwBackground.children(':nth-child(3)').find('div').children().css({
            'box-shadow': '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
            'z-index': '1'
          });
          var iwCloseBtn = iwOuter.next();
          iwCloseBtn.css({
            opacity: '1',
            right: '6px',
            top: '10px',
            width: '24px',
            height: '24px'
          });
          iwCloseBtn.html(`<i class="material-icons info-window-close">close</i>`);
        });
        let markers = this.markers;
        let connectMarkers = this.connectMarkers;
        markers.forEach(marker => {
          if ((0, _object.get)(marker, 'lat') && (0, _object.get)(marker, 'lng')) {
            let markerSvg = {
              path: (0, _object.get)(marker, 'path') ? (0, _object.get)(marker, 'path') : 'M12,2C8.134,2,5,5.134,5,9c0,5,7,13,7,13s7-8,7-13C19,5.134,15.866,2,12,2z M12,11.5c-1.38,0-2.5-1.119-2.5-2.5s1.12-2.5,2.5-2.5c1.381,0,2.5,1.119,2.5,2.5S13.381,11.5,12,11.5z',
              fillColor: (0, _object.get)(marker, 'color') ? (0, _object.get)(marker, 'color') : '#EA473C',
              fillOpacity: 1,
              scale: (0, _object.get)(marker, 'scale') ? (0, _object.get)(marker, 'scale') : 1.6,
              strokeWeight: 0,
              anchor: (0, _object.get)(marker, 'offset') ? new google.maps.Point((0, _object.get)(marker, 'offset.x'), (0, _object.get)(marker, 'offset.y')) : new google.maps.Point(11, 11),
              labelOrigin: (0, _object.get)(marker, 'label.offset') ? new google.maps.Point((0, _object.get)(marker, 'label.offset.x'), (0, _object.get)(marker, 'label.offset.y')) : new google.maps.Point(11, 11)
            };
            let latlng = new google.maps.LatLng((0, _object.get)(marker, 'lat'), (0, _object.get)(marker, 'lng'));
            let newMarker = new google.maps.Marker({
              position: latlng,
              icon: markerSvg,
              zIndex: (0, _object.get)(marker, 'zIndex') ? (0, _object.get)(marker, 'zIndex') : 1,
              label: (0, _object.get)(marker, 'label') ? {
                text: (0, _object.get)(marker, 'label.text'),
                color: (0, _object.get)(marker, 'label.color') ? (0, _object.get)(marker, 'label.color') : '#EA473C',
                fontSize: '14px',
                fontWeight: 'bold'
              } : null
            });
            newMarker.markerInput = marker;
            newMarker.setMap(map);
            bounds.extend(latlng);
            let content;
            if ((0, _object.get)(marker, 'infoWindow')) {
              let noHeader = (0, _object.get)(marker, 'infoWindow.title') ? '' : 'no-header';
              content = '<div id="iw-container">';
              if ((0, _object.get)(marker, 'infoWindow.title')) {
                content = `${content}<div class="iw-title">${(0, _object.get)(marker, 'infoWindow.title')}</div>`;
              }
              content = `${content}<div class="iw-content ${noHeader} layout-column">`;
              (0, _array.A)((0, _object.get)(marker, 'infoWindow.content')).forEach(item => {
                content += item.displayName ? `<span>${item.displayName}: ${item.value}</span>` : `<span>${item.value}</span>`;
              });
              content = `${content}</div></div>`;
              if ((0, _object.get)(marker, 'infoWindow.isActive')) {
                infoWindow.setContent(content);
                infoWindow.open(map, newMarker);
              }
            }
            newMarker.addListener('click', function () {
              if ((0, _object.get)(marker, 'onClick')) {
                (0, _object.get)(marker, 'onClick')(marker, newMarker);
              }
              if ((0, _object.get)(marker, 'infoWindow')) {
                infoWindow.setContent(content);
                infoWindow.open(map, newMarker);
              }
            });
            this._markers.push(newMarker);
          }
        });
        let disabledBoundFitting = this.disabledBoundFitting;
        if (!disabledBoundFitting) {
          map.fitBounds(bounds);
        }
        if (clusterMarkers) {
          let markerCluster = new MarkerClusterer(map, this._markers);
          if ((0, _object.get)(clusterOptions, 'gridSize')) {
            markerCluster.setGridSize((0, _object.get)(clusterOptions, 'gridSize'));
          }
          let styles = markerCluster.getStyles().map(style => {
            if ((0, _object.get)(clusterOptions, 'textColor')) {
              style.textColor = (0, _object.get)(clusterOptions, 'textColor');
            }
            if ((0, _object.get)(clusterOptions, 'color')) {
              style.url = getGoogleClusterInlineSvg((0, _object.get)(clusterOptions, 'color'));
            }
            if ((0, _object.get)(clusterOptions, 'scale')) {
              style.width = Math.floor(style.width * (0, _object.get)(clusterOptions, 'scale'));
              style.height = Math.floor(style.height * (0, _object.get)(clusterOptions, 'scale'));
            }
            return style;
          });
          markerCluster.setStyles(styles);
          (0, _object.set)(this, '_markerCluster', markerCluster);
        }
        if (connectMarkers) {
          let lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 2
          };
          (0, _object.set)(this, '_polyline', new google.maps.Polyline({
            path: markers,
            geodesic: true,
            strokeColor: this.markerLineColor ? this.markerLineColor : '#fff',
            strokeOpacity: 0,
            strokeWeight: 2,
            icons: [{
              icon: lineSymbol,
              offset: '100%',
              repeat: '10px'
            }],
            map
          }));
        }
        let center = map.getCenter();
        if (center) {
          map.panTo(center);
        }
        let onRenderMarkers = this.onRenderMarkers;
        if (onRenderMarkers) {
          onRenderMarkers(this._markers);
        }
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._markers.forEach(marker => {
        marker.setMap(null);
      });
      let _markerCluster = this._markerCluster;
      let _polyline = this._polyline;
      if (_markerCluster) {
        _markerCluster.clearMarkers();
      }
      if (_polyline) {
        _polyline.setMap(null);
      }
    }
  });
});