define("ember-svg-jar/inlined/asterisk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Filled_Icons\"><path fill=\"#757575\" d=\"M21 10h-5.535l2.767-4.794-3.463-2L12 8 9.232 3.206l-3.463 2L8.538 10H3v4h5.537l-2.768 4.795 3.463 2 2.769-4.797 2.768 4.795 3.463-2L15.465 14H21z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});