define("@trovedata/sunstone-ui-commons/components/async-icon-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPending}}
    {{trove-icon "interface-loading" spin=true}}
  {{else}}
    {{yield}}
  {{/if}}
  
  */
  {
    "id": "u8AQaF37",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],[\"interface-loading\"],[[\"spin\"],[true]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"isPending\",\"trove-icon\",\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/async-icon-button/template.hbs",
    "isStrictMode": false
  });
});