define("@trovedata/sunstone-ui-commons/models/definition", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    dataFieldPills: (0, _attributes.fragmentArray)('data-pill')
  });
});