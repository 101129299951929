define("@trovedata/sunstone-ui-commons/services/root-url", ["exports", "@ember/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    build(relativeURL) {
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return `${ENV.rootURL}${relativeURL.replace(/^\//, '')}`;
    }
  });
});