define("@trovedata/sunstone-ui-commons/components/sunstone-map-marker/component", ["exports", "@ember/object", "@ember/component", "@ember/array", "@trovedata/sunstone-ui-commons/components/sunstone-map-marker/template"], function (_exports, _object, _component, _array, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-side-effects */
  /* global google */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    markers: null,
    circles: (0, _array.A)(),
    color: null,
    zoom: null,
    addedListener: false,
    bounds: null,
    radius: 1000,
    smallRadius: 500,
    bigRadius: 2000,
    _markers: (0, _object.computed)('bounds', 'markers.[]', function () {
      (0, _object.set)(this, 'bounds', new google.maps.LatLngBounds());
      return this.markers.map(marker => {
        if (marker.get('latitude') && marker.get('longitude')) {
          let latlng = new google.maps.LatLng(marker.get('latitude'), marker.get('longitude'));
          this.bounds.extend(latlng);
          return latlng;
        } else {
          return null;
        }
      });
    }),
    init() {
      this._super(...arguments);
      this.set('bounds', new google.maps.LatLngBounds());
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map && !(0, _object.get)(this, 'circles.length') && !this.addedListener) {
        (0, _object.set)(this, 'addedListener', true);
        let zoom_changed = this.map.addListener('zoom_changed', () => {
          if (this.map.zoom > 14) {
            this.map.setZoom(14);
          }
          if (this.map.zoom >= 10 && this.radius !== this.smallRadius) {
            this.circles.forEach(circle => {
              circle.setRadius(this.smallRadius);
              (0, _object.set)(this, 'radius', this.smallRadius);
            });
          } else if (this.map.zoom < 10 && this.radius !== this.bigRadius) {
            this.circles.forEach(circle => {
              circle.setRadius(this.bigRadius);
              (0, _object.set)(this, 'radius', this.bigRadius);
            });
          }
          (0, _object.set)(this, 'zoom', this.map.zoom);
        });
        (0, _object.set)(this, 'zoom_changed', zoom_changed);
      }
    },
    didRender() {
      this._super(...arguments);
      if (this.map && (0, _object.get)(this, '_markers.length')) {
        this.map.fitBounds(this.bounds);
        this.circles.forEach(circle => {
          circle.setMap(null);
        });
        (0, _object.set)(this, 'circles', (0, _array.A)());
        let radius = this.radius;
        this._markers.forEach(marker => {
          this.circles.pushObject(new google.maps.Circle({
            strokeColor: this.color,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: this.color,
            fillOpacity: 0.6,
            map: this.map,
            center: marker,
            radius
          }));
        });
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      let zoom_changed = this.zoom_changed;
      (0, _object.set)(this, 'markers', null);
      (0, _object.set)(this, 'bounds', new google.maps.LatLngBounds());
      if (this.circles) {
        this.circles.forEach(circle => {
          circle.setMap(null);
        });
      }
      (0, _object.set)(this, 'circles', (0, _array.A)());
      if (zoom_changed) {
        zoom_changed.remove();
        (0, _object.set)(this, 'addedListener', false);
      }
    }
  });
});